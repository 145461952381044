import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { productActions, selectProduct } from "../../redux/slices/productSlice";
import { useDispatch, useSelector } from "react-redux";
import uniqueArrayList from "../../utils/UniqueArrayList";
import { getProductApi } from "../../redux/apis/dashboardApi";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import { selectLoggedUser } from "../../redux/slices/authSlice";

const { Option } = Select;

const AddYourProductForm = ({
  open,
  setOpen,
  formDefine,
  handleSubmit,
  submitLoading,
  rowData,
}) => {
  const rules = {
    name: [
      {
        required: true,
        message: "Please Enter Name",
      },
    ],
    productSpecs: [
      {
        required: true,
        message: "Please Enter Product Specifications",
      },
    ],
    price: [
      {
        required: true,
        message: "Please Enter Price",
      },
    ],
  };

  const dispatch = useDispatch();

  const productData = useSelector(selectProduct);
  const loggedUser = useSelector(selectLoggedUser);

  const [products, setProducts] = useState([]);
  const [defaultSelectedProduct, setDefaultSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      productActions.productList({
        limit: 10,
        page: currentPage,
        funnelId: loggedUser?.activeFunnel?._id,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (productData) {
      let newArr = [...products, ...productData?.list];
      let finalArr = uniqueArrayList(newArr, "_id");

      if (finalArr?.length) {
        setProducts(finalArr);
      }
    }
    // eslint-disable-next-line
  }, [productData]);

  const productInData = (val) => {
    if (val) {
      dispatch(productActions.productSearch({ searchText: val }));
    }
  };

  // eslint-disable-next-line
  const debounceProduct = useCallback(
    helperMethods.debounce(productInData, 500),
    []
  );

  const handleChangeProduct = async (value) => {
    if (value) {
      try {
        debounceProduct(value);
        const getProductData = await getProductApi(value);

        if (getProductData?.data?.success) {
          setDefaultSelectedProduct(value);
          formDefine?.setFieldsValue({
            name: getProductData?.data?.data?.name || "",
            productSpecs: getProductData?.data?.data?.productDetails || "",
            price: getProductData?.data?.data?.price || "",
          });
        } else {
          setDefaultSelectedProduct(null);
          message.error(getProductData?.data?.message);
        }
      } catch (error) {
        setDefaultSelectedProduct(null);
        message.error(error?.response?.data?.message);
      }
    } else {
      setDefaultSelectedProduct(null);
      formDefine?.resetFields();
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setDefaultSelectedProduct(null);
    formDefine?.resetFields();
  };

  return (
    <>
      <Modal
        title="Add/Edit Your Product"
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <div>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <p>Select Product</p>
                <Select
                  onChange={(e) => handleChangeProduct(e)}
                  style={{ width: "100%" }}
                  placeholder="Select Product"
                  value={defaultSelectedProduct}
                  allowClear
                  onPopupScroll={(e) => {
                    const target = e.target;
                    if (
                      target.scrollTop + target.clientHeight ===
                      target.scrollHeight
                    ) {
                      if (productData?.isNext) {
                        dispatch(
                          productActions.productList({
                            limit: 10,
                            page: currentPage + 1,
                            funnelId: loggedUser?.activeFunnel?._id,
                          })
                        );
                        setCurrentPage(currentPage + 1);
                      }
                    }
                  }}
                  onClear={() => debounceProduct("")}
                  showSearch
                >
                  {products?.map((val, i) => (
                    <Option key={i} value={val?._id}>
                      {val?.name} - {val?.category} (
                      {val?.productDetails?.length > 25
                        ? `${val?.productDetails?.slice(0, 25)} ...`
                        : val?.productDetails}
                      )
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>
          <p style={{ textAlign: "center" }}>Or Add Manually Product</p>
          <div>
            <Form
              name={formDefine}
              layout="vertical"
              form={formDefine}
              onFinish={handleSubmit}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="name" label="Name" rules={rules.name}>
                    <Input
                      disabled={defaultSelectedProduct ? true : false}
                      placeholder="Competition Name"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="productSpecs"
                    label="Product Specifications"
                    rules={rules.productSpecs}
                  >
                    <Input.TextArea
                      disabled={defaultSelectedProduct ? true : false}
                      placeholder="Product Specifications"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="price" label="Price" rules={rules.price}>
                    <Input
                      disabled={defaultSelectedProduct ? true : false}
                      style={{ width: "100%" }}
                      placeholder="Price"
                      className="hide-arrows"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handleCancel} htmlType="reset" type="default">
                  Cancel
                </Button>
                <Button
                  loading={submitLoading}
                  htmlType="submit"
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddYourProductForm;
