import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { PATH_AUTH } from "../../routes/path";
import { contactUsPostApi } from "../../redux/apis/auth";

const rules = {
  firstName: [{ required: true, message: "Please enter your first name" }],
  lastName: [{ required: true, message: "Please enter your last name" }],
  email: [
    { required: true, message: "Please enter your email" },
    {
      type: "email",
      message: "Please enter a valid email",
    },
  ],
  subject: [{ required: true, message: "Please enter a subject" }],
  message: [{ required: true, message: "Please enter your message" }],
};

const ContactUsPage = () => {
  const [contactus] = Form.useForm();
  const navigate = useNavigate();
  const [isSubmit, setSubmit] = useState(false);

  const onFinish = async (values) => {
    try {
      setSubmit(true);
      const res = await contactUsPostApi({ ...values });
      if (res?.data?.success) {
        setSubmit(false);
        contactus.resetFields();
        message.success(res?.data?.message);

        setTimeout(() => {
          navigate(PATH_AUTH.root);
        });
      } else {
        setSubmit(false);
        message.error(res?.data?.message);
      }
    } catch (error) {
      setSubmit(false);
      console.log(error);
      message.error(error?.response.data?.message);
    }
  };

  return (
    <>
      <Link to={PATH_AUTH.root} style={{ textDecoration: "none" }}>
        <div style={{ cursor: "pointer", marginTop: "10px" }}>
          <ArrowLeftOutlined style={{ marginRight: 10 }} />
          Back to login
        </div>
      </Link>

      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <p
            style={{ fontSize: "22px", fontWeight: 500, margin: 0, padding: 0 }}
          >
            Contact Us!
          </p>
          <p>Let us know how we can help you.</p>
        </div>

        <div
          style={{
            padding: 10,
            margin: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form
            form={contactus}
            name="contactus"
            layout="vertical"
            onFinish={onFinish}
            style={{ width: "400px" }}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={rules.firstName}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName" rules={rules.lastName}>
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={rules.email}>
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item label="Subject" name="subject" rules={rules.subject}>
              <Input placeholder="Subject" />
            </Form.Item>
            <Form.Item
              label="Type your message here"
              name="message"
              rules={rules.message}
            >
              <Input.TextArea rows={4} placeholder="Message" />
            </Form.Item>

            <div>
              <Button
                size="large"
                type="primary"
                loading={isSubmit}
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
