import { Card, Tabs } from "antd";
import React from "react";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";

const Profile = () => {
  const items = [
    {
      label: "Edit Profile",
      key: 1,
      children: <EditProfile />,
    },
    {
      label: "Change Password",
      key: 2,
      children: <ChangePassword />,
    }
  ];
  return (
    <>
      <Card>
        <Tabs defaultActiveKey="1" type="card" size="large" items={items} />
      </Card>
    </>
  );
};

export default Profile;
