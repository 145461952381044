/* eslint-disable */
import React, { useState, useEffect } from "react";
import PricingStructurePng from "../../assets/images/pricing-structure.png";
import { Col, Divider, Input, Modal, Row, Table, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router";
import {
  selectSubcription,
  subcriptionActions,
} from "../../redux/slices/subcriptionSlice";
import {
  editTransactionApi,
  getPaymentKeyApi,
} from "../../redux/apis/dashboardApi";
import { selectLoggedUser } from "../../redux/slices/authSlice";

const initialState = {
  price: 0,
  GST: 18,
  noOfLicense: 1,
  isCoupon: false,
  couponCode: null,
  couponAttempt: 1,
  discountPrice: null,
  setupCost: 10,
};

const initialModelState = {
  isModel: false,
  data: null,
  isSubmiting: false,
};

const RenewalSubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const isMainLicense = params.get("isMainLicense");
  const paymentStatus = params.get("status");

  const demoClearAllDataOrNot = window.localStorage.getItem("clearDemoData");

  const subcriptionData = useSelector(selectSubcription);
  const loggedUser = useSelector(selectLoggedUser);

  const [state, setState] = useState(initialState);
  const [modelState, setModelState] = useState(initialModelState);

  useEffect(() => {
    dispatch(subcriptionActions.subcription());
    dispatch(
      subcriptionActions.activeSubcriptionList({ superUserId: loggedUser?._id })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (subcriptionData) {
      if (location?.pathname.includes("renewal-subscription")) {
        const license = params.get("license");

        setState({
          ...state,
          price: subcriptionData?.viewData?.perUserPrice || 0,
          noOfLicense: Number(license),
        });
      }
    }
    // eslint-disable-next-line
  }, [subcriptionData]);

  const handlePlusValue = () => {
    setState({
      ...state,
      noOfLicense: state?.noOfLicense + 1,
      couponCode: null,
      discountPrice: null,
      isCoupon: false,
      couponAttempt: 1,
    });
  };

  const handleMinusValue = () => {
    if (state?.noOfLicense > 1) {
      setState({
        ...state,
        noOfLicense: state?.noOfLicense - 1,
        couponCode: null,
        discountPrice: null,
        isCoupon: false,
        couponAttempt: 1,
      });
    }
  };

  const handleApplyCouponCode = () => {
    if (state?.couponCode) {
      let couponCodeList =
        subcriptionData?.viewData?.couponCode?.length > 0
          ? [...subcriptionData?.viewData?.couponCode]
          : [];

      if (couponCodeList?.length > 0) {
        let match = couponCodeList.some(
          (item) => item.name === state?.couponCode
        );

        if (match) {
          if (state?.couponAttempt === 1) {
            let getCouponCode = couponCodeList?.find(
              (val) => val?.name === state?.couponCode
            );

            // let finalPrice =
            //   state?.price * state?.noOfLicense -
            //   (state?.noOfLicense * state?.price * getCouponCode?.price) / 100;

            let discountPrice =
              (state?.noOfLicense * state?.price * getCouponCode?.price) / 100;

            setState({
              ...state,
              isCoupon: false,
              couponAttempt: 0,
              discountPrice: Math.round(discountPrice),
            });
          } else {
            message.error("You already added coupon");
          }
        } else {
          message.error("Invalid coupon code");
        }
      } else {
        message.error("No coupon code available");
      }
    } else {
      message.error("Please enter coupon code");
    }
  };

  const getFinalAmount = (state) => {
    let totalWithoutRenewalCost = state?.price * state?.noOfLicense;

    let renewalCost =
      loggedUser?.accountType !== "demo" &&
      paymentStatus === "approved" &&
      0.2 * totalWithoutRenewalCost;

    renewalCost = state?.discountPrice
      ? Number(renewalCost) + Number(state?.discountPrice)
      : Number(renewalCost);

    let amt = 0;

    if (loggedUser?.accountType == "demo") {
      amt =
        totalWithoutRenewalCost -
        renewalCost +
        totalWithoutRenewalCost * (state?.setupCost / 100);
    } else {
      amt = totalWithoutRenewalCost - renewalCost;
    }

    amt = Math.round(amt);

    return amt || 0;
  };

  const handleProceedToCheckout = async () => {
    try {
      const subId = params.get("subId");

      setModelState({
        ...modelState,
        isSubmiting: true,
      });

      let finalAmount = getFinalAmount(state);

      const { data: paymentKeyData } = await getPaymentKeyApi();

      const paymentKey = paymentKeyData?.data?.key || null;

      if (paymentKey) {
        const startSubcriptionDate = new Date();

        const payload = {
          superUserId: loggedUser?._id,
          subLicense:
            isMainLicense === "true"
              ? state?.noOfLicense - 1
              : state?.noOfLicense,
          initialSubLicense:
            isMainLicense === "true"
              ? state?.noOfLicense - 1
              : state?.noOfLicense,
          mainLicense: isMainLicense === "true" ? 1 : 0,
          isMainLicense: isMainLicense === "true" ? true : false,
          amount: finalAmount,
          status: "pending",
          isRenewal: true,
          isNewSubcription: false,
          startSubcription: startSubcriptionDate?.getTime(),
          endSubcription: startSubcriptionDate.setFullYear(
            startSubcriptionDate.getFullYear() + 1
          ),
          isCoupon: state?.isCoupon,
          coupon: {
            name: state?.couponCode || "",
            amt: state?.discountPrice || 0,
          },
        };

        const paymentRes = await editTransactionApi(subId, payload);

        const { data, message: messageRes, success } = paymentRes.data;

        if (success) {
          setModelState({
            ...modelState,
            isSubmiting: false,
          });

          const options = {
            key: paymentKey,
            amount: data?.order?.amount || 0,
            currency: "INR",
            name: "FunneLine",
            description: "Subcription",
            image: "https://i.postimg.cc/cL5d9tWB/funneline.jpg",
            order_id: data?.order?.id || "",
            callback_url: `${process.env.REACT_APP_BASE_API_URL}/payment/payment-verification?paymentId=${data?._id}`,
            prefill: {
              name: loggedUser?.firstName
                ? loggedUser?.firstName + " " + loggedUser?.lastName
                : "-",
              email: loggedUser?.email || "",
              contact: loggedUser?.mobile || "",
            },
            notes: {
              name: loggedUser?.firstName
                ? loggedUser?.firstName + " " + loggedUser?.lastName
                : "-",
              email: loggedUser?.email || "",
              contact: loggedUser?.mobile || "",
              companyName: loggedUser?.companyName || "",
            },
            theme: {
              color: "#fbc413",
            },
          };
          const razor = new window.Razorpay(options);
          razor.open();
        } else {
          setModelState({
            ...modelState,
            isSubmiting: false,
          });

          message.error(messageRes);
        }
      }
    } catch (error) {
      setModelState({
        ...modelState,
        isSubmiting: false,
      });

      console.log(error);
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleProceedToDemoPay = async () => {
    try {
      const subId = params.get("subId");

      setModelState({
        ...modelState,
        isSubmiting: true,
      });

      let finalAmount = getFinalAmount(state);

      let totalWithoutRenewalCost = state?.price * state?.noOfLicense;

      let setUpcostAmt = totalWithoutRenewalCost * (state?.setupCost / 100);

      const { data: paymentKeyData } = await getPaymentKeyApi();

      const paymentKey = paymentKeyData?.data?.key || null;

      if (paymentKey) {
        const startSubcriptionDate = new Date();

        let mainAndSubLicense = {
          amount: finalAmount,
          status: "pending",
          isRenewal: true,
          isNewSubcription: false,
          startSubcription: startSubcriptionDate?.getTime(),
          endSubcription: startSubcriptionDate.setFullYear(
            startSubcriptionDate.getFullYear() + 1
          ),
          isCoupon: state?.isCoupon,
          coupon: {
            name: state?.couponCode || "",
            amt: state?.discountPrice || 0,
          },
          setupFees: Math.round(setUpcostAmt),
        };

        const mainLicensePayload = {
          superUserId: loggedUser?._id,
          subLicense: 0,
          initialSubLicense: 0,
          mainLicense: 1,
          isMainLicense: true,
          ...mainAndSubLicense,
        };

        const subLicensePayload = {
          superUserId: loggedUser?._id,
          subLicense: 1,
          initialSubLicense: 1,
          mainLicense: 0,
          isMainLicense: false,
          ...mainAndSubLicense,
        };

        let mainLic = isMainLicense === "true" ? true : false;

        let mainData = subcriptionData?.activeList?.find(
          (sub) => subId === sub?._id && sub?.isMainLicense === mainLic
        );

        let subData = subcriptionData?.activeList?.find(
          (sub) => subId !== sub?._id && sub?.isMainLicense !== mainLic
        );

        console.log("=====> mainData subData", mainData, subData);

        if (subData && mainData) {
          const [mainResult, subResult] = await Promise.all([
            editTransactionApi(mainData?._id, mainLicensePayload),
            editTransactionApi(subData?._id, subLicensePayload),
          ]);

          console.log("=====> mainResult subResult", mainResult, subResult);

          if (mainResult.data?.success && subResult.data?.success) {
            setModelState({
              ...modelState,
              isSubmiting: false,
            });

            const options = {
              key: paymentKey,
              amount: mainResult?.data?.data?.order?.amount || 0,
              currency: "INR",
              name: "FunneLine",
              description: "Subcription",
              image: "https://i.postimg.cc/cL5d9tWB/funneline.jpg",
              order_id: mainResult?.data?.data?.order?.id || "",
              callback_url: `${
                process.env.REACT_APP_BASE_API_URL
              }/payment/payment-verification?paymentId=${
                mainResult?.data?.data?._id
              }&clearAllData=${demoClearAllDataOrNot || ""}`,
              prefill: {
                name: loggedUser?.firstName
                  ? loggedUser?.firstName + " " + loggedUser?.lastName
                  : "-",
                email: loggedUser?.email || "",
                contact: loggedUser?.mobile || "",
              },
              notes: {
                name: loggedUser?.firstName
                  ? loggedUser?.firstName + " " + loggedUser?.lastName
                  : "-",
                email: loggedUser?.email || "",
                contact: loggedUser?.mobile || "",
                companyName: loggedUser?.companyName || "",
              },
              theme: {
                color: "#fbc413",
              },
            };
            const razor = new window.Razorpay(options);
            razor.open();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTotal = async (state) => {
    setModelState({
      ...modelState,
      data: state,
      isModel: true,
    });
  };

  return (
    <>
      <div>
        <Row>
          <Col span={8}>
            <div
              style={{
                color: "blue",
                cursor: "pointer",
                marginTop: "5px",
                marginLeft: "5px",
              }}
              onClick={() => {
                window.localStorage.removeItem("clearDemoData");
                navigate(-1);
              }}
            >
              <ArrowLeftOutlined /> <span>Back</span>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ textAlign: "center" }}>
              <h1 style={{ fontWeight: 500 }}>Renewal Subcription</h1>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ textAlign: "end" }}>
              <img
                src={PricingStructurePng}
                alt="pricingStructure"
                style={{ width: "250px", height: "200px" }}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                fontSize: "16px",
              }}
            >
              <p style={{ fontStyle: "italic" }}>
                "Price is what you pay. Value is what you get."
              </p>
              <p>- Warren Buffet.</p>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ textAlign: "center" }}>
              <p>
                One year per user license:{" "}
                <span style={{ fontWeight: 700, fontSize: "16px" }}>
                  {subcriptionData?.viewData?.perUserPrice || 0} INR
                </span>
                <span style={{ fontWeight: 500, fontSize: "16px" }}>
                  {" "}
                  (Including GST {state.GST}%)
                </span>
              </p>
              <div
                style={{
                  background: "#d9ecff",
                  color: "#272727",
                  borderRadius: "10px",
                  padding: "10px",
                  margin: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "1px",
                  }}
                >
                  <span>Add Nos. of users license you require</span>
                </div>

                {/* {isMainLicense !== "true" && (
                  <div
                    style={{
                      fontSize: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onClick={handlePlusValue}
                  >
                    <PlusCircleTwoTone />
                  </div>
                )} */}

                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <Input
                      value={state.noOfLicense}
                      style={{
                        width: "50px",
                        border: "1px solid #c3c3c3",
                        background: "#d9ecff",
                        fontSize: "18px",
                      }}
                      className="hide-arrows"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      readOnly={true}
                    />
                  </span>

                  {/* {isMainLicense !== "true" && (
                    <span
                      style={{
                        fontSize: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={handleMinusValue}
                    >
                      <MinusCircleTwoTone />
                    </span>
                  )} */}

                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      marginLeft: "5px",
                    }}
                  >
                    X {subcriptionData?.viewData?.perUserPrice || 0} INR
                  </span>
                </div>
              </div>

              {loggedUser?.accountType === "demo" && (
                <div
                  style={{
                    background: "#d9ecff",
                    color: "#272727",
                    borderRadius: "10px",
                    margin: "20px",
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <div>
                        <p>
                          One time setup cost: <span>{state.setupCost} %</span>{" "}
                          of Total Value (including GST)
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              <div>
                {state?.isCoupon ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Input
                        style={{ width: "250px" }}
                        placeholder="Coupon code"
                        value={state?.couponCode}
                        onChange={(event) =>
                          setState({
                            ...state,
                            couponCode: event?.target?.value?.toUpperCase(),
                          })
                        }
                      />
                      <div>
                        <span
                          onClick={handleApplyCouponCode}
                          style={{
                            color: "blue",
                            cursor: "pointer",
                            marginRight: "20px",
                          }}
                        >
                          Apply
                        </span>
                        <span
                          onClick={() =>
                            setState({
                              ...state,
                              isCoupon: false,
                              couponCode: null,
                              couponAttempt: 1,
                              discountPrice: null,
                            })
                          }
                          style={{ color: "red", cursor: "pointer" }}
                        >
                          Cancel
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {state?.couponCode && !state.isCoupon ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span
                          style={{
                            color: "#2e82d1",
                            fontSize: "16px",
                          }}
                        >
                          Coupon Applied: {state?.couponCode} (
                          {state?.discountPrice} INR)
                        </span>
                        <span
                          onClick={() =>
                            setState({
                              ...state,
                              isCoupon: false,
                              couponCode: null,
                              couponAttempt: 1,
                              discountPrice: null,
                              price: subcriptionData?.viewData?.perUserPrice,
                            })
                          }
                          style={{ color: "red", cursor: "pointer" }}
                        >
                          Remove
                        </span>
                      </div>
                    ) : (
                      <span
                        style={{
                          color: "#2e82d1",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() => setState({ ...state, isCoupon: true })}
                      >
                        Have you a coupon code?
                      </span>
                    )}
                  </>
                )}
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    color: `${state?.isCoupon ? "#EBEBE4" : "#F1C946"}`,
                    background: `${state?.isCoupon ? "#c3c3c3" : "#5E5E5E"}`,
                    borderRadius: "10px",
                    margin: "20px",
                    padding: "0px 20px",
                    width: "fit-content",
                    cursor: `${
                      !state?.isCoupon && state.price > 0
                        ? "pointer"
                        : "not-allowed"
                    }`,
                  }}
                  onClick={() =>
                    !state?.isCoupon && state.price > 0
                      ? handleTotal(state)
                      : {}
                  }
                >
                  <Row>
                    <Col span={24}>
                      <div>
                        <p style={{ fontSize: "16px", fontWeight: 600 }}>
                          Total ={" "}
                          <span style={{ fontWeight: 700 }}>
                            {getFinalAmount(state)}
                          </span>
                          <span> INR</span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div
          style={{
            fontSize: "18px",
            color: "#37b4de",
            fontWeight: 600,
            fontStyle: "italic",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "green", fontSize: "20px" }}>
              <CheckCircleFilled />
            </span>
            <span>Renewal charges are 80% of total users cost.</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "green", fontSize: "20px" }}>
              <CheckCircleFilled />
            </span>
            <span>Set up cost is for one time charge only.</span>
          </div>
        </div>

        <Divider />

        <div>
          <div
            style={{
              fontSize: "18px",
              color: "#37b4de",
              fontWeight: 500,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span>Understand the licensing roles</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontSize: "14px",
              marginBottom: "30px",
            }}
          >
            <p
              style={{
                fontWeight: 600,
                fontSize: "16px",
                color: "#1f1f1f",
              }}
            >
              There will be three roles for users:
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span>1. Super Admin (All rights)</span>
              <span>2. Admin (Viewing and other rights)</span>
              <span>3. Admin (Data making and adding rights)</span>
            </div>

            <p style={{ textAlign: "center" }}>
              Once you select nos of users, you will be given one unique id
              password of Super Admin, in which you will <br />
              select roles and rights of other users if multiple users are
              there.
            </p>

            <p style={{ textAlign: "center" }}>
              Any nos. of users you select, you can use those licenses as Super
              Admin, Admin and Users.
            </p>

            <p style={{ textAlign: "center" }}>
              Eg. if you have selected 5 users, you can use those 5 licenses as,
              1 Super Admin, 2 Admins, and 2 Users or <br />1 Super Admin, 1
              Admin, and 3 Users.
            </p>
          </div>
        </div>
      </div>

      <Modal
        open={modelState.isModel}
        title="Payment confirmation"
        okText="Proceed to checkout"
        onCancel={() => setModelState(initialModelState)}
        onOk={() => {
          loggedUser?.accountType === "demo"
            ? handleProceedToDemoPay()
            : handleProceedToCheckout();
        }}
        width={700}
        okButtonProps={{
          loading: modelState.isSubmiting,
        }}
      >
        <Divider style={{ marginBottom: "20px", marginTop: "20px" }} />

        <Table
          bordered
          columns={[
            {
              title: "Name",
              dataIndex: "firstName",
              render: (_, elm) => (
                <span>{elm?.firstName + " " + elm?.lastName}</span>
              ),
            },
            {
              title: "Email",
              dataIndex: "email",
              render: (_, elm) => <span>{elm?.email || ""}</span>,
            },
            {
              title: "No Of License",
              dataIndex: "nol",
              render: (_, elm) => (
                <span>
                  {elm?.modelState ? elm?.modelState?.noOfLicense : ""}
                </span>
              ),
            },
            {
              title: "Price",
              dataIndex: "firstName",
              render: (_, elm) => (
                <span>{elm?.modelState ? elm?.modelState?.price : ""}</span>
              ),
            },
          ]}
          dataSource={[{ ...loggedUser, modelState: modelState.data }]}
          pagination={false}
        />

        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col span={12}></Col>

            <Col span={12}>
              <Row>
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      padding: "5px",
                      fontWeight: 600,
                      flexDirection: "column",
                      alignItems: "end",
                    }}
                  >
                    <span>Subtotal</span>
                    {loggedUser?.accountType !== "demo" &&
                      paymentStatus === "approved" && (
                        <>
                          <span></span>
                          <span>Renewal Off</span>
                        </>
                      )}
                    <span></span>
                    {loggedUser?.accountType === "demo" && (
                      <>
                        <span>Setup Cost</span>
                        <span></span>
                      </>
                    )}
                    <span>Discount</span>
                  </div>
                </Col>
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      padding: "5px",
                      fontWeight: 600,
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <span>
                        &#8377;{" "}
                        {modelState?.data?.price *
                          modelState?.data?.noOfLicense || 0}
                      </span>
                      <span style={{ fontSize: "10px" }}>(including GST)</span>
                    </div>

                    {loggedUser?.accountType !== "demo" &&
                      paymentStatus === "approved" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <span style={{ color: "red" }}>
                            - &#8377;{" "}
                            {Math.round(
                              (modelState.data?.noOfLicense *
                                modelState.data?.price *
                                20) /
                                100
                            )}
                          </span>
                          <span style={{ fontSize: "10px", color: "red" }}>
                            (20%)
                          </span>
                        </div>
                      )}

                    {loggedUser?.accountType === "demo" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <span>
                          &#8377;{" "}
                          {Math.round(
                            (modelState.data?.noOfLicense *
                              modelState.data?.price *
                              10) /
                              100
                          )}
                        </span>
                        <span style={{ fontSize: "10px" }}>(10%)</span>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <span>
                        {modelState?.data?.discountPrice ? (
                          <span style={{ color: "red" }}>
                            - &#8377; {modelState?.data?.discountPrice}
                          </span>
                        ) : (
                          "-"
                        )}
                      </span>
                      <span style={{ color: "red", fontSize: "10px" }}>
                        {modelState.data?.couponCode
                          ? `(${modelState.data?.couponCode})`
                          : ""}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Divider style={{ margin: "5px" }} />
              <Row>
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      padding: "5px",
                      fontWeight: 600,
                      flexDirection: "column",
                      alignItems: "end",
                      fontSize: "16px",
                    }}
                  >
                    <span>Total</span>
                  </div>
                </Col>
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      padding: "5px",
                      fontWeight: 600,
                      flexDirection: "column",
                      alignItems: "end",
                      fontSize: "16px",
                    }}
                  >
                    <span>&#8377; {getFinalAmount(modelState.data)}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default RenewalSubscription;
