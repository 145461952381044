import { ArrowLeftOutlined } from "@ant-design/icons";
import { Collapse, Empty, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { PATH_AUTH } from "../../routes/path";
import { helpAndSupportListApi } from "../../redux/apis/auth";

const { root } = PATH_AUTH;

const initialState = {
  model: false,
  data: null,
  isLoading: false,
  list: [],
};

const HelpAndSupport = () => {
  const navigate = useNavigate();

  const [openModel, setOpenModel] = useState(initialState);

  const helpAndSupportFunc = async () => {
    try {
      setOpenModel({
        ...openModel,
        isLoading: true,
      });

      const res = await helpAndSupportListApi();

      if (res?.data?.success) {
        setOpenModel({
          model: true,
          data: res?.data?.data,
          isLoading: false,
        });
      } else {
        setOpenModel({
          ...openModel,
          isLoading: true,
        });
        message.error(res?.data?.message);
      }
    } catch (error) {
      setOpenModel({
        ...openModel,
        isLoading: false,
      });
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    helpAndSupportFunc();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (openModel.data?.length > 0) {
      const items = [];

      openModel.data?.map((faq, i) => {
        items.push({
          key: i + 1,
          label: faq?.title || "-",
          children:
            (
              <div dangerouslySetInnerHTML={{ __html: faq?.description }}></div>
            ) || "-",
        });

        return null;
      });

      setOpenModel((prevState) => ({
        ...prevState,
        list: items,
      }));
    }
  }, [openModel]);

  return (
    <>
      <div>
        <div>
          {" "}
          {openModel?.isLoading ? (
            <Spin />
          ) : (
            <>
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
                onClick={() => navigate(root)}
              >
                <ArrowLeftOutlined /> <span>Back to login</span>
              </div>

              <h3 style={{ marginLeft: "20px" }}>Help & Support</h3>

              {openModel?.list?.length > 0 ? (
                <Collapse
                  style={{ margin: "20px" }}
                  size="large"
                  items={openModel.list}
                />
              ) : (
                <Empty description={"No FAQs"} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HelpAndSupport;
