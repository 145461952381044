import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36,
  },
  billTo: {
    marginTop: 10,
    paddingBottom: 3,
  },
});

const BillTo = ({ state }) => {
  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Bill To:</Text>
        <Text>{state.invoiceData?.superUserId?.companyName || ""},</Text>
        <Text>{state.invoiceData?.superUserId?.mobile || ""},</Text>
        <Text>{state.invoiceData?.superUserId?.email || ""}</Text>
      </View>
    </>
  );
};

export default BillTo;
