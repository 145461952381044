import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Col, Form, Input, Row, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { PATH_AUTH } from "../../routes/path";
import { inquiryFormPostApi } from "../../redux/apis/auth";

const rules = {
  name: [{ required: true, message: "Please enter a name" }],
  email: [
    { required: true, message: "Please Enter Email" },
    {
      type: "email",
      message: "Please Enter A Valid Email",
    },
  ],
  phone: [
    {
      required: true,
      message: "Please Enter Phone Number",
    },
    {
      max: 10,
      message: "Please Enter Valid Phone Number",
    },
  ],
  subject: [{ required: true, message: "Please enter a subject" }],
};

const InquiryPage = () => {
  const [inquiryForm] = Form.useForm();
  const navigate = useNavigate();

  const [isSubmit, setSubmit] = useState(false);

  const onFinish = async (values) => {
    try {
      setSubmit(true);
      const res = await inquiryFormPostApi({ ...values });
      if (res?.data?.success) {
        setSubmit(false);
        inquiryForm.resetFields();
        message.success(res?.data?.message);

        setTimeout(() => {
          navigate(PATH_AUTH.root);
        });
      } else {
        setSubmit(false);
        message.error(res?.data?.message);
      }
    } catch (error) {
      setSubmit(false);
      console.log(error);
      message.error(error?.response.data?.message);
    }
  };

  return (
    <>
      <Link to={PATH_AUTH.root} style={{ textDecoration: "none" }}>
        <div style={{ cursor: "pointer", marginTop: "10px" }}>
          <ArrowLeftOutlined style={{ marginRight: 10 }} />
          Back to login
        </div>
      </Link>

      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <p
            style={{ fontSize: "22px", fontWeight: 500, margin: 0, padding: 0 }}
          >
            Inquiry
          </p>
        </div>

        <div
          style={{
            padding: 10,
            margin: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Form
            form={inquiryForm}
            name="inquiryForm"
            layout="vertical"
            onFinish={onFinish}
          >
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item label="Name" name="name" rules={rules.name}>
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email Id" name="email" rules={rules.email}>
                  <Input placeholder="Email Id" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  rules={rules.phone}
                  name="phone"
                >
                  <Input
                    placeholder="Phone Number"
                    className="hide-arrows"
                    style={{ width: "100%" }}
                    stringMode={true}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Subject" name="subject" rules={rules.subject}>
                  <Input placeholder="Subject" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={rules.description}
                >
                  <Input.TextArea rows={3} placeholder="Description" />
                </Form.Item>
              </Col>
            </Row>

            <div>
              <Button
                size="large"
                type="primary"
                loading={isSubmit}
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default InquiryPage;
