import React from "react";
import {
  Page,
  Document,
  Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import logo from "../../assets/images/funneline.jpg";
import InvoiceTitle from "../../sections/SubcriptionsManage/InvoiceTitle";
import InvoiceNo from "../../sections/SubcriptionsManage/InvoiceNo";
import BillTo from "../../sections/SubcriptionsManage/BillTo";
import InvoiceItemsTable from "../../sections/SubcriptionsManage/InvoiceItemsTable";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 150,
    height: 40,
    marginLeft: "auto",
    marginRight: "auto",
  },
  demoView: {
    marginTop: "20px",
  },
});

const Invoice = ({ invoice, state, subcriptionData }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={logo} />
        <InvoiceTitle title="Invoice" />
        <InvoiceNo state={state} />
        <BillTo state={state} />
        <InvoiceItemsTable
          subcriptionData={subcriptionData}
          state={state}
          invoice={invoice}
        />

        {state?.invoiceData?.superUserId?.accountType === "demo" && (
          <View style={styles.demoView}>
            <Text>Note: Demo Account</Text>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default Invoice;
