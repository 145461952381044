import React from "react";
import LoginForm from "../../sections/LoginForm/LoginForm";

const Login = () => {
  return (
    <>
      {/* <div>
        <div>
          <Header />
        </div> */}
      <LoginForm />
      {/* <div>
          <Footer />
        </div>
      </div> */}
    </>
  );
};

export default Login;
