import React, { useEffect, useState } from "react";
import { productActions, selectProduct } from "../../redux/slices/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Table, Tooltip, message } from "antd";
import CustomPagination from "../../pages/Product/Pagination";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { editProductApi } from "../../redux/apis/dashboardApi";

const ByProductWise = ({ loggedUser }) => {
  const dispatch = useDispatch();

  const productData = useSelector(selectProduct);

  const [products, setProducts] = useState([]);
  const [changedPrice, setChangedPrice] = useState({
    newPrice: null,
    submitLoading: false,
    isChange: false,
    rowId: null,
  });

  useEffect(() => {
    dispatch(
      productActions.productList({
        limit: 10,
        page: 1,
        funnelId: loggedUser?.activeFunnel?._id,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (productData) {
      setProducts(productData?.list);
    }
  }, [productData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "Category",
      dataIndex: "category",
      width: "15%",
    },
    {
      title: "Original Price",
      dataIndex: "originalPrice",
      width: "15%",
      render: (_, elm) => (
        <span>&#8377; {Number(elm?.originalPrice).toLocaleString()}</span>
      ),
    },
    {
      title: "Last Price",
      dataIndex: "lastPrice",
      width: "15%",
      render: (_, elm) => (
        <>
          {elm?.lastPrice ? (
            <span>&#8377; {Number(elm?.lastPrice).toLocaleString()}</span>
          ) : (
            <span>&#8377; {Number(elm?.originalPrice).toLocaleString()}</span>
          )}
        </>
      ),
    },
    {
      title: "New Price",
      dataIndex: "price",
      width: "20%",
      render: (_, elm) => {
        return (
          <>
            {changedPrice.isChange && changedPrice.rowId === elm._id ? (
              <>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Input
                    placeholder="Please enter"
                    style={{ width: "100%" }}
                    className="hide-arrows"
                    onChange={(val) =>
                      setChangedPrice({
                        ...changedPrice,
                        newPrice: val.target.value,
                      })
                    }
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <div>
                    <Tooltip title="Save">
                      <Button
                        icon={<CheckOutlined />}
                        loading={changedPrice.submitLoading}
                        onClick={() => handleSave(elm)}
                        type="primary"
                      />
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title="Cancel">
                      <Button
                        icon={<CloseOutlined />}
                        loading={changedPrice.submitLoading}
                        onClick={() =>
                          setChangedPrice({ ...changedPrice, isChange: false })
                        }
                        type="default"
                      />
                    </Tooltip>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>&#8377; {Number(elm?.price).toLocaleString()}</span>
                <Tooltip title="Edit">
                  <Button
                    icon={<EditOutlined />}
                    type="default"
                    onClick={() =>
                      setChangedPrice({
                        ...changedPrice,
                        isChange: true,
                        rowId: elm._id,
                      })
                    }
                  />
                </Tooltip>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const handleSave = async (row) => {
    try {
      if (changedPrice.newPrice !== null && changedPrice.newPrice > 0) {
        setChangedPrice({ ...changedPrice, submitLoading: true });

        const data = {
          lastPrice: Number(row?.price),
          price: Number(changedPrice.newPrice),
        };

        const editRes = await editProductApi(row?._id, data);

        if (editRes.data.success) {
          message.success(editRes.data.message);
          dispatch(
            productActions.productList({
              limit: 10,
              page: 1,
              funnelId: loggedUser?.activeFunnel?._id,
            })
          );
          setChangedPrice({
            ...changedPrice,
            newPrice: null,
            submitLoading: false,
            isChange: false,
            rowId: null,
          });
        } else {
          setChangedPrice({ ...changedPrice, submitLoading: false });
          message.error(editRes.data.message);
        }
      } else {
        message.error("Please enter valid new price");
      }
    } catch (error) {
      setChangedPrice({ ...changedPrice, submitLoading: false });
      message.error(error?.response.data?.message);
    }
  };

  return (
    <>
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={products}
          loading={productData?.isLoading}
          rowKey="id"
          pagination={false}
          scroll={{
            x: 1000,
          }}
        />
        <div style={{ marginTop: "20px" }}>
          <CustomPagination />
        </div>
      </div>
    </>
  );
};

export default ByProductWise;
