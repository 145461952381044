/* eslint-disable */
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { authActions, selectLoggedUser } from "../../redux/slices/authSlice";
import {
  apiGetPresetList,
  editPreset,
  editProgressStagesApi,
  getPresetApi,
} from "../../redux/apis/dashboardApi";
import { listOfProgressFront } from "../../utils/general-constant";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

const initialState = {
  potentialList: [],
  consumptionList: [],
  unitList: [],
};

const Presets = () => {
  const [presetForm] = Form.useForm();

  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoggedUser);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [listProgress, setListProgress] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editedListProgress, setEditedListProgress] = useState([]);
  const [state, setState] = useState(initialState);

  function compareSorting(list) {
    return list.slice().sort((a, b) => {
      const numA = parseInt(a);
      const numB = parseInt(b);

      if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB;
      } else {
        return a.localeCompare(b);
      }
    });
  }

  const keyValuePairsList = (list) => {
    if (list.length > 0) {
      let tempList = [];

      tempList = compareSorting(list);

      tempList = tempList?.map((val) => {
        return {
          label: val,
          value: val,
        };
      });

      return tempList;
    }

    return list;
  };

  const presetListFunc = async () => {
    try {
      const res = await apiGetPresetList();

      if (res?.data?.success) {
        let potentialList =
          res.data?.data?.potentialValue?.length > 0
            ? keyValuePairsList(res?.data?.data?.potentialValue)
            : [];

        let consumptionList =
          res.data?.data?.consumptionValue?.length > 0
            ? keyValuePairsList(res?.data?.data?.consumptionValue)
            : [];

        let unitList =
          res.data?.data?.unitValue?.length > 0
            ? keyValuePairsList(res?.data?.data?.unitValue)
            : [];

        setState({
          potentialList,
          consumptionList,
          unitList,
        });
      } else {
        message.error(res?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (loggedUser) {
      presetListFunc();
    }
  }, []);

  const selectOptionsForFinancialYear = [
    { monthNum: 1, label: "January", value: "January" },
    { monthNum: 2, label: "February", value: "February" },
    { monthNum: 3, label: "March", value: "March" },
    { monthNum: 4, label: "April", value: "April" },
    { monthNum: 5, label: "May", value: "May" },
    { monthNum: 6, label: "June", value: "June" },
    { monthNum: 7, label: "July", value: "July" },
    { monthNum: 8, label: "August", value: "August" },
    { monthNum: 9, label: "September", value: "September" },
    { monthNum: 10, label: "October", value: "October" },
    { monthNum: 11, label: "November", value: "November" },
    { monthNum: 12, label: "December", value: "December" },
  ];

  useEffect(() => {
    if (loggedUser) {
      if (Object.keys(loggedUser?.activeFunnel).length > 0) {
        (async () => {
          try {
            const presetDataRes = await getPresetApi({
              funnelId: loggedUser?.activeFunnel?._id,
            });

            if (presetDataRes?.data?.success) {
              setListProgress(loggedUser?.stages);
              presetListFunc();
              dispatch(
                authActions.presetDataAction({
                  data: presetDataRes?.data?.data,
                })
              );
            } else {
              setListProgress([]);
              presetForm.resetFields();
              message.error(presetDataRes?.data?.message);
            }
          } catch (error) {
            setListProgress([]);
            presetForm.resetFields();
            message.error(
              error?.response?.data?.message || "Something went wrong"
            );
          }
        })();
      } else {
        message.error("Please Activate Any Funnel");
      }
    }
  }, [loggedUser]);

  useEffect(() => {
    if (loggedUser) {
      presetForm.setFieldsValue({
        consumptionValue: loggedUser?.presets?.consumptionValue,
        financialYearStart: loggedUser?.presets?.financialYear?.start,
        financialYearEnd: loggedUser?.presets?.financialYear?.end,
        potentialValue: loggedUser?.presets?.potentialValue,
        unitValue: loggedUser?.presets?.unitValue,
        isSetDefaultUnit: loggedUser?.presets?.isSetDefaultUnit,
      });
    }
  }, [loggedUser]);

  const onFinish = async (values) => {
    try {
      setSubmitLoading(true);
      const res = await editPreset({
        ...values,
        funnelId: loggedUser?.activeFunnel?._id,
        financialYear: {
          start: values?.financialYearStart,
          end: values?.financialYearEnd,
        },
      });

      if (res.data?.success) {
        message.success(res.data?.message);
        setTimeout(() => {
          setSubmitLoading(false);
          dispatch(authActions.isLogin());
        }, 1000);
      } else {
        setSubmitLoading(false);
        message.error(res.data?.message);
      }
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
      message.error(error);
    }
  };

  // add and edit stages
  const handleEditStages = () => {
    setEditedListProgress([...listProgress]);
    setOpenModal(true);
  };

  const handleCancleModal = () => {
    setOpenModal(false);
  };

  const handleChangeName = (e, index) => {
    const updatedListProgress = [...editedListProgress];

    const updatedItem = { ...updatedListProgress[index] };

    updatedItem.name = e.target.value;

    updatedListProgress[index] = updatedItem;

    setEditedListProgress(updatedListProgress);
  };

  const handleSubmitData = async () => {
    try {
      const isNameInvalid = editedListProgress.some((stage) =>
        [undefined, null, ""].includes(stage.name)
      );

      if (isNameInvalid) {
        message.error(`Please Enter Stage Name`);
        return;
      }

      const data = {
        funnelId: loggedUser?.activeFunnel?._id,
        stages: editedListProgress,
      };
      const res = await editProgressStagesApi(data);

      if (res?.data?.success) {
        message.success(res?.data?.message);
        setTimeout(() => {
          setOpenModal(false);
          dispatch(authActions.isLogin());
        }, 1000);
      } else {
        setOpenModal(false);
        message.error(res?.data?.message);
      }
    } catch (error) {
      console.log(error);
      setOpenModal(false);
      message.error(error?.response?.data?.message ?? "something went wrong");
    }
  };

  const handleDeleteStage = (row, index) => {
    const filteredStages = editedListProgress?.filter((item, i) => i !== index);

    if (filteredStages.length > 0) {
      const newFirstStage = { ...filteredStages[0] };
      const newLastStage = { ...filteredStages[filteredStages.length - 1] };

      newFirstStage.bg = "#f55249";
      newLastStage.bg = "#2bb526";

      filteredStages[0] = newFirstStage;
      filteredStages[filteredStages.length - 1] = newLastStage;

      setEditedListProgress(filteredStages);
    }
  };

  const handleAddStage = () => {
    const addData = listOfProgressFront?.find(
      (val, index) => index === editedListProgress.length
    );

    const newStage = {
      name: "",
      value: editedListProgress.length + 1,
      bg: addData?.bg,
      color: addData?.color,
    };

    let updatedListProgress = [...editedListProgress, newStage];

    updatedListProgress = updatedListProgress.map((stage, index) => ({
      ...stage,
      bg:
        index === updatedListProgress.length - 1
          ? "#2bb526"
          : index === 0
          ? "#f55249"
          : listOfProgressFront[index]?.bg,
    }));

    setEditedListProgress(updatedListProgress);
  };

  let hideFields =
    loggedUser?.addEditDeleteRights?.length > 0 &&
    loggedUser?.addEditDeleteRights
      ?.filter((v) => v.value === "preset")
      .some((val) => val?.isAddEditDelete === false);

  const handleSelectFinancialYearStart = (value) => {
    let copiedMonths = [...selectOptionsForFinancialYear];

    const selectedMonth = copiedMonths.find((val) => val?.value === value);
    let endMonthNum = selectedMonth.monthNum + 11;

    if (endMonthNum > 12) {
      endMonthNum -= 12;
    }

    const endMonth = copiedMonths.find((val) => val?.monthNum === endMonthNum);

    presetForm.setFieldsValue({
      financialYearEnd: endMonth?.value,
    });
  };

  return (
    <>
      <h2>Presets</h2>
      <Card>
        <div style={{ marginTop: "20px" }}>
          <Form
            name="presetForm"
            form={presetForm}
            layout="vertical"
            onFinish={onFinish}
            disabled={hideFields}
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label="Potential Value"
                  name="potentialValue"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Potential Value",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    options={state.potentialList}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label="Consumption Volume in Potential Mapping"
                  name="consumptionValue"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please Select Consumption Volume in Potential Mapping",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    options={state.consumptionList}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label="Financial Year Start"
                  name="financialYearStart"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Financial Year",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    onChange={(val) => handleSelectFinancialYearStart(val)}
                    options={selectOptionsForFinancialYear}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label="Financial Year End"
                  name="financialYearEnd"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Financial Year",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    disabled={true}
                    options={selectOptionsForFinancialYear}
                  />
                </Form.Item>
              </Col>
              <Col xs={16} sm={16} md={12}>
                <Form.Item
                  label="Select Unit For QTY/Volume"
                  name="unitValue"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Unit For QTY/Volume",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    options={state.unitList}
                    placeholder="Select Unit"
                    allowClear
                    showSearch
                  />
                </Form.Item>
              </Col>
              <Col xs={8} sm={8} md={8} style={{ paddingTop: "30px" }}>
                <Form.Item name="isSetDefaultUnit" valuePropName="checked">
                  <Checkbox>Set as default for the all products</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ marginTop: "20px" }}
                type="primary"
                htmlType="submit"
                size="large"
                loading={submitLoading}
              >
                Save Preset
              </Button>
            </div>
          </Form>
        </div>
      </Card>
      <br />
      <Card
        title="Progress Stages"
        extra={
          <div>
            {!hideFields && (
              <Button
                className="mr-2"
                icon={<EditOutlined style={{ fontSize: "16px" }} />}
                type="primary"
                size="middle"
                onClick={() => handleEditStages()}
              >
                Edit Stage
              </Button>
            )}
          </div>
        }
      >
        <div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {listProgress?.length > 0 ? (
              listProgress?.map((v, index) => (
                <>
                  <div
                    style={{
                      width: "90px",
                      textAlign: "center",
                      height: "65px",
                    }}
                    key={index}
                  >
                    <Tooltip title={v?.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "5px",
                          fontSize: "12px",
                          borderRadius: "5px",
                          background: `${v?.bg}`,
                          color: `#000000`,
                          fontWeight: 600,
                          letterSpacing: "0.5px",
                          position: "relative",
                          height: "100%",
                        }}
                      >
                        <span>{index + 1}</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {(v?.name).length > 18
                            ? (v?.name).slice(0, 18) + "..."
                            : v?.name}
                        </span>
                        {index !== listProgress.length - 1 && (
                          <div
                            className="connection-line"
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "-24px",
                              width: "24px",
                              height: "2px",
                              background: "#ccc",
                            }}
                          />
                        )}
                      </div>
                    </Tooltip>
                  </div>
                </>
              ))
            ) : (
              <>No Stages</>
            )}
          </div>
        </div>

        <Modal
          open={openModal}
          onCancel={handleCancleModal}
          onOk={handleSubmitData}
          okText="Submit"
          title="Edit Stage"
        >
          <div>
            <Row gutter={[12, 12]}>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                <span style={{ fontWeight: 600 }}>No.</span>
              </Col>
              <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                <span style={{ fontWeight: 600 }}>Stage Name</span>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <span style={{ fontWeight: 600 }}>Color</span>
              </Col>
              {editedListProgress?.length > 0 ? (
                editedListProgress?.map((v, index) => (
                  <>
                    <Col
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 500,
                      }}
                    >
                      <span>{index + 1}.</span>
                    </Col>
                    <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                      <Input
                        value={v?.name}
                        onChange={(e) => handleChangeName(e, index)}
                        maxLength={50}
                      />
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <div
                        style={{
                          width: "100%",
                          height: "30px",
                          borderRadius: "7px",
                          backgroundColor: `${v?.bg}`,
                        }}
                      />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                      {editedListProgress?.length > 5 && (
                        <div>
                          <Button
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => handleDeleteStage(v, index)}
                          />
                        </div>
                      )}
                    </Col>
                  </>
                ))
              ) : (
                <>No Stages</>
              )}

              {editedListProgress?.length < 10 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    icon={<PlusCircleOutlined />}
                    type="primary"
                    onClick={() => handleAddStage()}
                  >
                    Add Stage
                  </Button>
                </div>
              )}
            </Row>
          </div>
        </Modal>
      </Card>
    </>
  );
};

export default Presets;
