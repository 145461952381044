import { Carousel } from "antd";
import React from "react";
import chartImg from '../../assets/images/chart.png'

const WelcomePage = () => {
  return (
    <>
      <Carousel dots={false}>
        <div>
          <div style={{ textAlign: "center", padding: '20px' }}>
            <h1 style={{ fontSize: "35px", fontWeight: 400, marginBottom: '50px' }}>Welcome</h1>
            <p style={{ fontSize: "22px", fontWeight: 500, color: "#c7c700", marginBottom: '30px'}}>
              "Our only focus is to help you in your customers conversions"
            </p>
            <p style={{fontSize: '22px', marginBottom: '30px'}}>
              You are at the place where you can create you "customers' funnel"
              in details in an <br /> easy way to track the conversion ratio and growth
              of your company.
            </p>
            <img src={chartImg} alt="report" style={{ height: '250px', width: '300px', float: 'right'}} />
          </div>
        </div>
        <div>Sales Reports</div>
        <div>Subscription</div>
      </Carousel>
    </>
  );
};

export default WelcomePage;
