import React, { useState } from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import { forgotPasswordApi, verifyEmailApi } from "../../redux/apis/auth";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "../../routes/path";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const rules = {
  password: [
    { required: true, message: "Please Enter Password" },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (
          !value ||
          /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{6,}$/.test(value)
        ) {
          return Promise.resolve();
        } else if (value.length < 6) {
          return Promise.reject(
            "Password should be at least 6 characters long"
          );
        } else if (!/(?=.*[A-Z])/.test(value)) {
          return Promise.reject(
            "Password should contain at least one uppercase letter"
          );
        } else if (!/(?=.*[!@#$%^&*])/.test(value)) {
          return Promise.reject(
            "Password should contain at least one special character"
          );
        } else if (!/(?=.*[0-9])/.test(value)) {
          return Promise.reject("Password should contain at least one digit");
        }
      },
    }),
  ],

  confirmPassword: [
    {
      required: true,
      message: "Please Enter Confirm Password",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject("Password and Confirm Password must be same");
      },
    }),
  ],
};

const ForgotPassword = () => {
  const [forgotForm] = Form.useForm();

  const navigate = useNavigate();

  const [forgotData, setForgotData] = useState({
    email: null,
    isVerifiedEmail: false,
    isLoading: false,
  });

  const handleChangeEmail = (e) => {
    setForgotData({ email: e.target.value });
  };

  const handleVerifyEmail = async () => {
    if (forgotData.email) {
      try {
        setForgotData({ ...forgotData, isLoading: true });
        const res = await verifyEmailApi({ email: forgotData?.email });
        if (res.data?.success) {
          setForgotData({
            ...forgotData,
            isVerifiedEmail: true,
            isLoading: false,
          });
        } else {
          setForgotData({
            ...forgotData,
            isVerifiedEmail: false,
            isLoading: false,
          });
          message.error(res?.data?.message);
        }
      } catch (error) {
        setForgotData({
          ...forgotData,
          isVerifiedEmail: false,
          isLoading: false,
        });
        message.error(error?.response?.data?.message);
      }
    } else {
      message.error("Please Enter Email");
    }
  };

  const onFinish = async (values) => {
    try {
      if (forgotData.email) {
        const data = {
          email: forgotData.email,
          password: values?.password,
        };
        setForgotData({ ...forgotData, isLoading: true });
        const res = await forgotPasswordApi(data);

        if (res.data?.success) {
          message.success(res?.data?.message);

          navigate(PATH_AUTH.root);

          setTimeout(() => {
            setForgotData({
              isLoading: false,
              email: null,
              isVerifiedEmail: false,
            });
          }, 600);
        } else {
          setForgotData({
            isLoading: false,
            email: null,
            isVerifiedEmail: false,
          });
          message.error(res?.data?.message);
        }
      } else {
        message.error("Something went wrong!, Please try again");
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <Link to={PATH_AUTH.root} style={{ textDecoration: "none" }}>
        <div style={{ cursor: "pointer", marginTop: "10px" }}>
          <ArrowLeftOutlined style={{ marginRight: 10 }} />
          Login
        </div>
      </Link>

      <div
        style={{
          height: "73vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!forgotData.isVerifiedEmail ? (
          <div style={{ width: "25%" }}>
            <p style={{ margin: 0, marginBottom: "5px" }}>Email</p>
            <Input
              value={forgotData.email}
              onChange={handleChangeEmail}
              placeholder="Email"
            />
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ marginTop: "10px" }}
                onClick={handleVerifyEmail}
                loading={forgotData.isLoading}
              >
                Verify Email
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ width: "60%" }}>
            <Form
              form={forgotForm}
              name="forgotForm"
              layout="vertical"
              onFinish={onFinish}
            >
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={rules.password}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Confim Password"
                    name="confirmPassword"
                    rules={rules.confirmPassword}
                  >
                    <Input.Password placeholder="Confirm Password" />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  loading={forgotData.isLoading}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
