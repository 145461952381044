import { Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { termsAndPrivacyApi } from "../../redux/apis/auth";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "../../routes/path";

const { root } = PATH_AUTH;

const Privacy = () => {
  const navigate = useNavigate();

  const initialState = {
    type: "",
    model: false,
    data: null,
    isLoading: false,
  };

  const [openModel, setOpenModel] = useState(initialState);

  const privacyPolicyFunc = async () => {
    try {
      setOpenModel({
        ...openModel,
        isLoading: true,
      });

      const res = await termsAndPrivacyApi({ type: "privacy" });

      if (res?.data?.success) {
        setOpenModel({
          type: "privacy",
          model: true,
          data: res?.data?.data,
          isLoading: false,
        });
      } else {
        setOpenModel({
          ...openModel,
          isLoading: true,
        });
        message.error(res?.data?.message);
      }
    } catch (error) {
      setOpenModel({
        ...openModel,
        isLoading: false,
      });
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    privacyPolicyFunc();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        {" "}
        {openModel?.isLoading ? (
          <Spin />
        ) : (
          <>
            <div
              style={{
                color: "blue",
                cursor: "pointer",
                marginTop: "5px",
                marginLeft: "5px",
              }}
              onClick={() => navigate(root)}
            >
              <ArrowLeftOutlined /> <span>Back to login</span>
            </div>

            <h3 style={{ marginLeft: "20px" }}>Privacy Policy</h3>
            <div
              style={{ padding: "20px", wordBreak: "break-word" }}
              dangerouslySetInnerHTML={{ __html: openModel?.data }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Privacy;
