import React from "react";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "../../routes/path";
import logo from "../../assets/images/funneline.jpg";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        height: "70px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        background: "#bdbdbb",
      }}
    >
      <div style={{ width: "50%" }}>
        <div
          onClick={() => navigate(PATH_AUTH.root)}
          style={{ cursor: "pointer" }}
        >
          <img
            alt="FunneLine"
            src={logo}
            style={{ height: 70, width: "300px", margin: 0, marginTop: "4px" }}
          />
        </div>
        {/* <h1 style={{ margin: 0, padding: 20 }}>FunneLine</h1> */}
      </div>
      <div style={{ width: "50%", float: "right" }}>
        <ul
          style={{
            listStyleType: "none",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <li
            onClick={() => navigate(PATH_AUTH.inquiry)}
            style={{ cursor: "pointer" }}
          >
            Inquiry
          </li>
          <li
            onClick={() => navigate(PATH_AUTH.pricingStructure)}
            style={{ cursor: "pointer" }}
          >
            Pricing
          </li>
          <li
            onClick={() => navigate(PATH_AUTH.helpAndSupport)}
            style={{ cursor: "pointer" }}
          >
            Help & Support
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
