import React, { useState } from "react";
import { Button, Col, DatePicker, Form, Modal, Popconfirm, Row } from "antd";
import dayjs from "dayjs";

const AddTimelineDate = ({
  open,
  setOpen,
  formDefine,
  handleSubmit,
  submitLoading,
  rowData,
}) => {
  const [formValue, setFormValue] = useState(null);
  const [isDisable, setIsDisable] = useState(true);

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const rules = {
    conversionTimeline: [
      {
        required: true,
        message: "Please Select Conversion Date",
      },
    ],
  };

  const handleCancel = () => {
    setOpen(false);
    formDefine?.resetFields();
  };

  const handlePopconfirmConfirm = () => {
    if (formValue !== null) {
      setIsDisable(false);
    }
  };

  return (
    <>
      <Modal
        title="Add Conversion Time Line Date"
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <div>
            <Form
              name={formDefine}
              layout="vertical"
              form={formDefine}
              onFinish={handleSubmit}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="conversionTimeline"
                    label="Conversion Time Line"
                    rules={rules.conversionTimeline}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Select Date"
                      format={"DD/MM/YYYY"}
                      disabledDate={disabledDate}
                      onChange={(date) => setFormValue(date)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handleCancel} htmlType="reset" type="default">
                  Cancel
                </Button>
                {isDisable ? (
                  <Popconfirm
                    title="Please check date currectly or not!"
                    description="This field non editable once you submit."
                    onConfirm={handlePopconfirmConfirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary">Confirm</Button>
                  </Popconfirm>
                ) : (
                  <Button
                    loading={submitLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddTimelineDate;
