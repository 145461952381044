import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { stateAndCitiesApi, superUserListApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  list: [],
  viewData: null,
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
  managementSuperUser: null,
  stateAndCities: [],
  userId: null,
};

const superUserSlice = createSlice({
  name: "superUsers",
  initialState,
  reducers: {
    superUserList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.searchText = "";
      state.isNext = false;
      state.managementSuperUser = action.payload.managementSuperUser;
    },
    superUserListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
      state.managementSuperUser = null;
    },
    superUserListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
      state.managementSuperUser = null;
    },
    superUserListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
    },
    superUserListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
    },
    superUserSearch(state, action) {
      state.searchText = action.payload.searchText;
      state.page = 1;
      state.limit = 10;
    },
    stateAndCitiesList(state, action) {
      state.userId = action.payload.userId;
      state.stateAndCities = [];
    },
    stateAndCitiesListSuccess(state, action) {
      state.stateAndCities = action.payload.data;
      state.userId = null;
    },
    stateAndCitiesListFailed(state) {
      state.stateAndCities = [];
      state.userId = null;
    },
  },
});

export const {
  actions: superUserActions,
  reducer: superUserReducer,
  name: superUserKey,
} = superUserSlice;

const selectDomain = (state) => state.superUsers || initialState;

export const selectSuperUser = createSelector(
  [selectDomain],
  (superUserState) => superUserState
);

export const getState = (state) => state.superUsers;

export function* superUserListReq() {
  try {
    const states = yield select(getState);
    const res = yield superUserListApi(states);

    if (res.data.success) {
      yield put(superUserActions.superUserListSuccess(res.data));
    } else {
      yield put(superUserActions.superUserListFailed(res.data));
    }
  } catch (error) {
    yield put(superUserActions.superUserListFailed(error));
  }
}

export function* stateAndCitiesListReq() {
  try {
    const states = yield select(getState);
    const res = yield stateAndCitiesApi(states);

    if (res.data.success) {
      yield put(superUserActions.stateAndCitiesListSuccess(res.data));
    } else {
      yield put(superUserActions.stateAndCitiesListFailed(res.data));
    }
  } catch (error) {
    yield put(superUserActions.stateAndCitiesListFailed(error));
  }
}

export function* superUserSaga() {
  yield takeLatest(superUserActions.superUserList.type, superUserListReq);
  yield takeLatest(
    superUserActions.superUserListPageLimit.type,
    superUserListReq
  );
  yield takeLatest(superUserActions.superUserListLimit.type, superUserListReq);
  yield takeLatest(superUserActions.superUserSearch.type, superUserListReq);
  yield takeLatest(
    superUserActions.stateAndCitiesList.type,
    stateAndCitiesListReq
  );
}
