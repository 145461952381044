import {
  // DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import RolesPagination from "./RolesPagination";
import AddEditRoleModel from "../../sections/RolesManage/AddEditRoleModel";
import { useDispatch, useSelector } from "react-redux";
import { roleActions, selectRole } from "../../redux/slices/roleSlice";
// import { deleteSubUserAdminApi } from "../../redux/apis/dashboardApi";
// import { authActions } from "../../redux/slices/authSlice";

const RolesManagement = ({ loggedUser }) => {
  const dispatch = useDispatch();

  const rolesData = useSelector(selectRole);

  const [roles, setRoles] = useState([]);
  const [addEditData, setAddEditData] = useState({
    data: null,
    model: false,
    type: null,
  });

  useEffect(() => {
    dispatch(
      roleActions.roleList({
        limit: 10,
        page: 1,
        createdBy: loggedUser?._id,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (rolesData) {
      let superAdminData = [
        {
          firstName: loggedUser?.firstName || "",
          lastName: loggedUser?.lastName || "",
          role: loggedUser?.role || "",
          designation: loggedUser?.designation || "",
          email: loggedUser?.email || "",
          mobile: loggedUser?.mobile || "",
          companyId: loggedUser?.companyId || "",
          reportingTo: loggedUser?.reportingTo || "",
          location: loggedUser?.location || "",
          state: loggedUser?.state || "",
          _id: loggedUser?._id || "",
        },
      ];

      let rolesList = rolesData?.list;

      let updatedList = [...superAdminData, ...rolesList];

      setRoles(updatedList);
    }
  }, [rolesData, loggedUser]);

  const handleViewEditUser = (row, type) => {
    if (type === "edit") {
      setAddEditData({ ...addEditData, data: row, model: true, type });
    }
  };

  // const handleDelete = async (id) => {
  //   try {
  //     if (id) {
  //       const res = await deleteSubUserAdminApi(id);
  //       if (res?.data?.success) {
  //         message.success(res?.data?.message);
  //         dispatch(authActions.isLogin());
  //         dispatch(
  //           roleActions.roleList({
  //             limit: 10,
  //             page: 1,
  //             createdBy: loggedUser?._id,
  //           })
  //         );
  //       } else {
  //         message.error(res?.data?.message);
  //       }
  //     }
  //   } catch (error) {
  //     message.error(error?.response?.data?.message ?? "Something went wrong");
  //   }
  // };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
      render: (_, elm) => (
        <span>
          {elm?.firstName} {elm?.lastName}
        </span>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      width: "20%",
      render: (_, elm) => (
        <Tag>
          {elm?.role?.roleName === "superUser"
            ? "Super Admin"
            : elm?.role?.roleName === "subUser"
            ? "User"
            : elm?.role?.roleName === "userAdmin"
            ? "Manager"
            : elm?.role?.roleName}
        </Tag>
      ),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "20%",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: "20%",
    },
    {
      title: "Company ID",
      dataIndex: "companyId",
      width: "20%",
    },
    {
      title: "Reporting To",
      dataIndex: "reportingTo",
      width: "20%",
      render: (val, elm) => {
        let fullNames = elm?.reportingTo?.map(
          (v) => `${v?.firstName} ${v?.lastName}`
        );

        return (
          <>
            <div>
              {fullNames?.length
                ? fullNames?.map((name, i) => (
                    <>
                      <Tag key={i}>{name}</Tag>
                    </>
                  ))
                : ""}
            </div>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "20%",
      align: "center",
      fixed: "right",
      render: (_, elm) => (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <Tooltip title="View/Edit">
            <Button
              className="mr-2"
              icon={<EditOutlined style={{ fontSize: "16px" }} />}
              type="primary"
              size="middle"
              onClick={() => handleViewEditUser(elm, "edit")}
            />
          </Tooltip>
          {/* <Tooltip title="Delete">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                handleDelete(elm._id);
              }}
              size="middle"
            />
          </Tooltip> */}
        </div>
      ),
    },
  ];

  const handleAddEditData = (type) => {
    if (type === "add") {
      setAddEditData({ ...addEditData, model: true, type });
    }
  };

  return (
    <>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          size="large"
          onClick={() => handleAddEditData("add")}
        >
          Create
        </Button>
      </div>
      <div className="table-responsive">
        <Card>
          <>
            <Table
              columns={columns}
              dataSource={roles}
              loading={rolesData?.loading}
              rowKey="id"
              pagination={false}
              scroll={{
                x: 1000,
              }}
            />
            <div style={{ marginTop: "20px" }}>
              <RolesPagination />
            </div>
          </>
        </Card>
      </div>

      <AddEditRoleModel
        data={addEditData}
        setData={setAddEditData}
        loggedUser={loggedUser}
      />
    </>
  );
};

export default RolesManagement;
