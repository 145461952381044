import React, { useEffect, useState } from "react";
import { Row, Col, Segmented, Divider, Empty, Select, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  salesVsActualActions,
  selectSalesVsActual,
} from "../../redux/slices/salesVsActualSlice";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import {
  customerActions,
  selectCustomer,
} from "../../redux/slices/customerSlice";
import { monthsArrayList } from "../../utils/general-constant";

const { Option } = Select;

const mainOptions = [
  {
    label: "Overview",
    value: "overview",
  },
  {
    label: "Customer Wise",
    value: "customer",
  },
];

const yearOptions = [
  {
    label: "Current Year",
    value: "current",
  },
  {
    label: "Previous Year",
    value: "previous",
  },
  {
    label: "Old Data",
    value: "old",
  },
];

const YearWithQuarter = {
  quarterOne: {
    name: "Quarter 1",
    plan: 0,
    actual: 0,
    achievement: 0,
    months: [
      {
        name: "January",
        plan: 0,
        actual: 0,
        achievement: 0,
      },
      {
        name: "February",
        plan: 30,
        actual: 25,
        achievement: 83,
      },
      {
        name: "March",
        plan: 30,
        actual: 15,
        achievement: 20,
      },
    ],
  },
  quarterTwo: {
    name: "Quarter 2",
    plan: 0,
    actual: 0,
    achievement: 0,
    months: [
      {
        name: "April",
        plan: 0,
        actual: 0,
        achievement: 0,
      },
      {
        name: "May",
        plan: 0,
        actual: 0,
        achievement: 0,
      },
      {
        name: "June",
        plan: 0,
        actual: 0,
        achievement: 0,
      },
    ],
  },
  quarterThree: {
    name: "Quarter 3",
    plan: 0,
    actual: 0,
    achievement: 0,
    months: [
      {
        name: "July",
        plan: 0,
        actual: 0,
        achievement: 0,
      },
      {
        name: "August",
        plan: 0,
        actual: 0,
        achievement: 0,
      },
      {
        name: "September",
        plan: 0,
        actual: 0,
        achievement: 0,
      },
    ],
  },
  quarterFour: {
    name: "Quarter 4",
    plan: 0,
    actual: 0,
    achievement: 0,
    months: [
      {
        name: "October",
        plan: 0,
        actual: 0,
        achievement: 0,
      },
      {
        name: "November",
        plan: 0,
        actual: 0,
        achievement: 0,
      },
      {
        name: "December",
        plan: 0,
        actual: 0,
        achievement: 0,
      },
    ],
  },
};

const SalesVsActual = () => {
  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoggedUser);
  const salesVsActualData = useSelector(selectSalesVsActual);

  const funnelData = loggedUser?.activeFunnel;
  const viewActiveFunnel = funnelData;
  const startMonth = loggedUser?.presets?.financialYear?.start || "January";

  const [filteredValues, setFilteredValues] = useState({
    main: "overview",
    year: "current",
  });
  const [filteredByCustomer, setFilteredByCustomer] = useState(null);

  const [saleVsActual, setSalesVsActual] = useState(null);

  const customerData = useSelector(selectCustomer);

  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    dispatch(customerActions.customerList({ limit: 9999, page: 1 }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (customerData) {
      setCustomers(customerData?.list);
    }
  }, [customerData]);

  useEffect(() => {
    dispatch(
      salesVsActualActions.salesVsActualList({
        funnelId: viewActiveFunnel?._id,
        userId: loggedUser?._id,
        filterBy: filteredValues.main,
        filterYear: filteredValues.year,
        selectedCustomer: null,
      })
    );
    // eslint-disable-next-line
  }, []);

  const rearrangeMonthsBasedOnStartMonth = (startMonth) => {
    const months = [...monthsArrayList];

    const startIndex = months.findIndex(
      (month) => month.toLowerCase() === startMonth.toLowerCase()
    );

    if (startIndex === -1) {
      console.error("Start month not found in the months array.");
      return months;
    }

    const reorderedMonths = [
      ...months.slice(startIndex),
      ...months.slice(0, startIndex),
    ];
    return reorderedMonths;
  };

  function rearrangeDataBasedOnStartMonth(data, startMonth) {
    const months = [...monthsArrayList];

    const startMonthIndex = months.indexOf(startMonth);

    const reorderedData = {};
    for (let i = 0; i < 12; i++) {
      const newIndex = (startMonthIndex + i) % 12;
      reorderedData[i] = data[newIndex];
    }

    return reorderedData;
  }

  useEffect(() => {
    if (salesVsActualData) {
      const quarterlyData = {};

      // const monthlyData = salesVsActualData.list;
      const monthlyData = rearrangeDataBasedOnStartMonth(
        salesVsActualData.list,
        startMonth
      );

      Object.keys(monthlyData).forEach((key) => {
        const monthIndex = parseInt(key);
        const quarterIndex = Math.floor(monthIndex / 3) + 1;

        if (!quarterlyData[`quarter${quarterIndex}`]) {
          quarterlyData[`quarter${quarterIndex}`] = {
            name: `Quarter ${quarterIndex}`,
            plan: 0,
            actual: 0,
            achievement: 0,
            months: [],
          };
        }

        quarterlyData[`quarter${quarterIndex}`]?.months.push({
          name: getMonthName(monthIndex),
          plan: monthlyData[key]?.totalSales,
          actual: monthlyData[key]?.totalActual,
          achievement: monthlyData[key]?.totalAchievement,
        });

        quarterlyData[`quarter${quarterIndex}`].plan +=
          monthlyData[key]?.totalActual;
        quarterlyData[`quarter${quarterIndex}`].actual +=
          monthlyData[key]?.totalSales;
        quarterlyData[`quarter${quarterIndex}`].achievement += parseFloat(
          monthlyData[key]?.totalAchievement
        );
      });

      function getMonthName(monthIndex) {
        const arrangedMonths = rearrangeMonthsBasedOnStartMonth(startMonth);

        const months = [...arrangedMonths];

        return months[monthIndex];
      }

      Object.keys(quarterlyData).forEach((quarter) => {
        const quarterObj = quarterlyData[quarter];
        if (quarterObj.months.length > 0) {
          let sumPlan = quarterObj.months?.reduce(
            (sum, elm) => sum + elm?.plan,
            0
          );
          let sumActual = quarterObj.months?.reduce(
            (sum, elm) => sum + elm?.actual,
            0
          );
          quarterObj.plan = sumPlan;
          quarterObj.actual = sumActual;
          quarterObj.achievement =
            sumActual && sumPlan
              ? `${(Number(sumActual / sumPlan) * 100).toFixed(2)} %`
              : 0;
        }
      });

      setSalesVsActual(quarterlyData ?? YearWithQuarter);
    }
    // eslint-disable-next-line
  }, [salesVsActualData]);

  const renderQuarterMonths = (month) => {
    return (
      <Col span={6}>
        <div style={{ border: "1px solid #c3c3c3" }}>
          <div
            style={{
              padding: "10px",
              textAlign: "center",
              backgroundColor: "#a5e7f2",
              wordBreak: "break-all",
            }}
          >
            {month.name}
          </div>
          <Row
            style={{
              borderBottom: "1px solid #c3c3c3",
            }}
          >
            <Col
              span={8}
              style={{
                padding: "10px",
                borderRight: "1px solid #c3c3c3",
                wordBreak: "break-all",
              }}
            >
              Plan
            </Col>
            <Col
              span={8}
              style={{
                padding: "10px",
                borderRight: "1px solid #c3c3c3",
                wordBreak: "break-all",
              }}
            >
              Actual
            </Col>
            <Col
              span={8}
              style={{ padding: "10px 5px", wordBreak: "break-all" }}
            >
              Achievement
            </Col>
          </Row>
          <Row>
            <Col
              span={8}
              style={{
                padding: "10px",
                borderRight: "1px solid #c3c3c3",
                wordBreak: "break-all",
              }}
            >
              {month.plan}
            </Col>
            <Col
              span={8}
              style={{
                padding: "10px",
                borderRight: "1px solid #c3c3c3",
                wordBreak: "break-all",
              }}
            >
              {month.actual}
            </Col>
            <Col span={8} style={{ padding: "10px", wordBreak: "break-all" }}>
              {month.achievement}
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  const renderTableForEachQuarter = (quarter) => {
    return (
      <Row gutter={12} style={{ marginBottom: "12px" }}>
        <Col span={6}>
          <div style={{ border: "1px solid #c3c3c3" }}>
            <div
              style={{
                padding: "10px",
                textAlign: "center",
                backgroundColor: "#bdbdbd",
                wordBreak: "break-all",
              }}
            >
              {saleVsActual[quarter]?.name}
            </div>
            <Row
              style={{
                borderBottom: "1px solid #c3c3c3",
              }}
            >
              <Col
                span={8}
                style={{
                  padding: "10px",
                  borderRight: "1px solid #c3c3c3",
                  wordBreak: "break-all",
                }}
              >
                Plan
              </Col>
              <Col
                span={8}
                style={{
                  padding: "10px",
                  borderRight: "1px solid #c3c3c3",
                  wordBreak: "break-all",
                }}
              >
                Actual
              </Col>
              <Col
                span={8}
                style={{ padding: "10px 5px", wordBreak: "break-all" }}
              >
                Achievement
              </Col>
            </Row>
            <Row>
              <Col
                span={8}
                style={{
                  padding: "10px",
                  borderRight: "1px solid #c3c3c3",
                  wordBreak: "break-all",
                }}
              >
                {saleVsActual[quarter]?.plan}
              </Col>
              <Col
                span={8}
                style={{
                  padding: "10px",
                  borderRight: "1px solid #c3c3c3",
                  wordBreak: "break-all",
                }}
              >
                {saleVsActual[quarter]?.actual}
              </Col>
              <Col span={8} style={{ padding: "10px", wordBreak: "break-all" }}>
                {saleVsActual[quarter]?.achievement}
              </Col>
            </Row>
          </div>
        </Col>
        {saleVsActual[quarter]?.months?.length > 0 &&
          saleVsActual[quarter].months?.map((item, index) => {
            return <>{renderQuarterMonths(item)}</>;
          })}
      </Row>
    );
  };

  const renderAllOverYear = (yearlyData) => {
    let updatedYear = Object.values(yearlyData);

    let yearlySumPlan = updatedYear?.reduce(
      (sum, elm) => sum + elm?.totalSales,
      0
    );
    let yearlySumActual = updatedYear?.reduce(
      (sum, elm) => sum + elm?.totalActual,
      0
    );
    let yearlyAchievement =
      yearlySumPlan && yearlySumActual
        ? `${(Number(yearlySumActual / yearlySumPlan) * 100).toFixed(2)} %`
        : 0;

    let year =
      filteredValues.year === "current"
        ? new Date().getFullYear()
        : filteredValues.year === "previous"
        ? Number(new Date().getFullYear()) - 1
        : Number(new Date().getFullYear()) - 2;

    if (filteredValues?.year === "old") {
      let newValues = Object.values(yearlyData);

      return (
        <>
          {newValues?.length > 0 ? (
            newValues?.map((val) =>
              renderYear(
                val?.saleYear,
                val?.totalSales,
                val?.totalActual,
                val?.achievement
              )
            )
          ) : (
            <>
              <Empty />
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {renderYear(year, yearlySumPlan, yearlySumActual, yearlyAchievement)}
        </>
      );
    }
  };

  const renderYear = (year, plan, actual, achievement) => {
    return (
      <div style={{ marginTop: "20px" }}>
        <Col span={24}>
          <div style={{ border: "1px solid #c3c3c3" }}>
            <div
              style={{
                padding: "10px",
                textAlign: "center",
                backgroundColor: "#bdbdbd",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              Year {year}
            </div>
            <Row
              style={{
                borderBottom: "1px solid #c3c3c3",
                background: "#f5e695",
              }}
            >
              <Col
                span={8}
                style={{ padding: "10px", borderRight: "1px solid #c3c3c3" }}
              >
                Plan
              </Col>
              <Col
                span={8}
                style={{ padding: "10px", borderRight: "1px solid #c3c3c3" }}
              >
                Actual
              </Col>
              <Col span={8} style={{ padding: "10px" }}>
                Achievement
              </Col>
            </Row>
            <Row>
              <Col
                span={8}
                style={{
                  padding: "10px",
                  borderRight: "1px solid #c3c3c3",
                  wordBreak: "break-all",
                }}
              >
                {plan}
              </Col>
              <Col
                span={8}
                style={{
                  padding: "10px",
                  borderRight: "1px solid #c3c3c3",
                  wordBreak: "break-all",
                }}
              >
                {actual}
              </Col>
              <Col span={8} style={{ padding: "10px", wordBreak: "break-all" }}>
                {achievement}
              </Col>
            </Row>
          </div>
        </Col>
      </div>
    );
  };

  const handleChangeFilteredMainValue = (val) => {
    setFilteredValues({ ...filteredValues, main: val });

    dispatch(
      salesVsActualActions.salesVsActualList({
        funnelId: viewActiveFunnel?._id,
        userId: loggedUser?._id,
        filterBy: val,
        filterYear: filteredValues.year,
        selectedCustomer: null,
      })
    );
  };

  const handleChangeFilteredYearValue = (val) => {
    setFilteredValues({ ...filteredValues, year: val });

    dispatch(
      salesVsActualActions.salesVsActualList({
        funnelId: viewActiveFunnel?._id,
        userId: loggedUser?._id,
        filterBy: filteredValues.main,
        filterYear: val,
        selectedCustomer: null,
      })
    );
  };

  const handleChangeCustomer = (customer) => {
    setFilteredByCustomer(customer);
  };

  const handleApplyFilter = () => {
    dispatch(
      salesVsActualActions.salesVsActualList({
        funnelId: viewActiveFunnel?._id,
        userId: loggedUser?._id,
        filterBy: filteredValues.main,
        filterYear: filteredValues.year,
        selectedCustomer: filteredByCustomer,
      })
    );
  };

  return (
    <>
      <h2>Sales Vs Actual</h2>

      <div>
        <div>
          <Segmented
            options={mainOptions}
            value={filteredValues.main}
            onChange={handleChangeFilteredMainValue}
          />
        </div>
        <Divider />
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Segmented
            options={yearOptions}
            value={filteredValues.year}
            onChange={handleChangeFilteredYearValue}
          />

          {filteredValues.main === "customer" && (
            <Row>
              <Col xs={24} sm={24} md={12} lg={8}>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <span>Customer: </span>
                  <Select
                    showSearch
                    value={filteredByCustomer}
                    onChange={handleChangeCustomer}
                    placeholder="Customer"
                    style={{ width: "100%" }}
                  >
                    {customers?.map((val) => (
                      <Option value={val?.name}>{val?.name}</Option>
                    ))}
                  </Select>
                  <Button onClick={handleApplyFilter} type="primary">
                    Apply filter
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>

      {filteredValues?.year !== "old" ? (
        <>
          <div style={{ marginTop: "20px" }}>
            {saleVsActual &&
              Object.keys(saleVsActual).map((quarter, index) => (
                <div key={index}>{renderTableForEachQuarter(quarter)}</div>
              ))}
          </div>

          <div style={{ marginTop: "20px" }}>
            {salesVsActualData?.list &&
              renderAllOverYear(salesVsActualData?.list)}
          </div>
        </>
      ) : (
        <>
          <div style={{ marginTop: "20px" }}>
            {salesVsActualData?.list && (
              <>{renderAllOverYear(salesVsActualData?.list)}</>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SalesVsActual;
