import { authReducer } from "./slices/authSlice";
import storage from "redux-persist/lib/storage";
import { customerReducer } from "./slices/customerSlice";
import { productReducer } from "./slices/productSlice";
import { funnelReducer } from "./slices/funnelSlice";
import { funnelRowReducer } from "./slices/funnelRowSlice";
import { carryForwardRowReducer } from "./slices/carryForwardRow";
import { employeeReducer } from "./slices/employeeSlice";
import { roleReducer } from "./slices/roleSlice";
import { superUserReducer } from "./slices/superUserSlice";
import { potentialMapReducer } from "./slices/potentialMapSlice";
import { salesVsActualReducer } from "./slices/salesVsActualSlice";
import { subcriptionReducer } from "./slices/subcriptionSlice";
const { combineReducers } = require("@reduxjs/toolkit");
const { persistReducer } = require("redux-persist");

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  blacklist: [],
};

const authPersistConfig = {
  key: "auth",
  storage,
  keyPrefix: "redux-",
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  customers: customerReducer,
  products: productReducer,
  funnels: funnelReducer,
  funnelRows: funnelRowReducer,
  carryforwardrow: carryForwardRowReducer,
  employee: employeeReducer,
  roles: roleReducer,
  superUsers: superUserReducer,
  potentialMaps: potentialMapReducer,
  salesVsActual: salesVsActualReducer,
  subcription: subcriptionReducer,
});

export { rootReducer, rootPersistConfig };
