/*eslint-disable*/
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Input,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import OrgCharPagination from "./OrgCharPagination";
import AddEmployeeFormModel from "../../sections/OrganizationChartManage/OrganizationChartManage";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import {
  employeeActions,
  selectEmployee,
} from "../../redux/slices/employeeSlice";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import OrgChart from "./OrgChart";
import {
  customerActions,
  selectCustomer,
} from "../../redux/slices/customerSlice";
import { deleteEmployeeApi } from "../../redux/apis/dashboardApi";

const { Option } = Select;

const EmployeeList = () => {
  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoggedUser);
  const employeeData = useSelector(selectEmployee);
  const customerData = useSelector(selectCustomer);

  const customerId = window.localStorage.getItem("customerId");

  const [addEmployeeModel, setAddEmployeeModel] = useState(false);
  const [editEmployeeData, setEditEmployeeData] = useState({
    row: null,
    type: null,
  });
  const [employees, setEmployees] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({
    value: null,
    isDisabled: true,
  });

  useEffect(() => {
    dispatch(customerActions.customerList({ page: 1, limit: 9999 }));

    dispatch(
      employeeActions.employeeList({
        limit: 10,
        page: 1,
        funnelId: loggedUser?.activeFunnel?._id,
        userId: loggedUser?._id,
        customerId: selectedCustomer.value,
      })
    );
  }, []);

  useEffect(() => {
    if (customerId) {
      setSelectedCustomer({
        ...selectedCustomer,
        value: customerId,
        isDisabled: false,
      });

      dispatch(
        employeeActions.employeeList({
          limit: 10,
          page: 1,
          funnelId: loggedUser?.activeFunnel?._id,
          userId: loggedUser?._id,
          customerId: customerId,
        })
      );
    }
  }, [customerId]);

  useEffect(() => {
    if (employeeData) {
      setEmployees(employeeData?.list);
    }
  }, [employeeData]);

  useEffect(() => {
    if (customerData) {
      setCustomers(customerData?.list);
    }
  }, [customerData]);

  const handleDelete = async (id) => {
    try {
      const deleteRes = await deleteEmployeeApi(id);
      if (deleteRes?.data?.success) {
        message.success(deleteRes?.data?.message);
        dispatch(
          employeeActions.employeeList({
            limit: 10,
            page: 1,
            funnelId: loggedUser?.activeFunnel?._id,
            userId: loggedUser?._id,
            customerId: selectedCustomer.value,
          })
        );
      } else {
        message.error(deleteRes?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
      message.error(error?.response.data?.message);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "employeeName",
      align: "center",
      width: 400,
      render: (val, elm) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 600,
              }}
            >
              <span>
                {elm?.employeeName} - {elm?.designation}
              </span>
              <span>{elm?.department}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Reportee",
      dataIndex: "list",
      align: "center",
      width: 300,
      render: (_, elm) => {
        return (
          <>
            <div>
              {elm?.reportee && elm?.reportee?.length > 0 ? (
                elm?.reportee?.map((v) => <Tag>{v}</Tag>)
              ) : (
                <>-</>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Reporting To",
      dataIndex: "reporting",
      align: "center",
      width: 300,
      render: (_, elm) => {
        return (
          <>
            {elm?.reporting ? (
              <div>
                <Tag>{elm?.reporting ?? ""}</Tag>
              </div>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: "Edit Data / Add Reportee/Reporting",
      dataIndex: "action",
      align: "center",
      width: 100,
      render: (_, elm) => {
        return (
          <>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <Tooltip title="Add/Edit Reportee/Reporting Data">
                <Button
                  type="primary"
                  onClick={() => handleAddEmployee(elm, "edit")}
                  icon={<EditOutlined />}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    handleDelete(elm._id);
                  }}
                  size="middle"
                />
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  const handleAddEmployee = (row, type) => {
    setAddEmployeeModel(true);
    setEditEmployeeData({ row, type });
  };

  const searchInData = (e) => {
    const searchInput = e.target.value;

    dispatch(
      employeeActions.employeeSearch({
        searchText: searchInput,
      })
    );
  };

  const debounceOnSearch = React.useCallback(
    helperMethods.debounce(searchInData, 400),
    []
  );

  const handleChangeCustomer = (customer) => {
    setSelectedCustomer({
      ...selectedCustomer,
      value: customer,
    });
  };

  const handleApplyFilter = () => {
    if (selectedCustomer.value) {
      setSelectedCustomer({
        ...selectedCustomer,
        isDisabled: false,
      });

      dispatch(
        employeeActions.employeeList({
          limit: 10,
          page: 1,
          funnelId: loggedUser?.activeFunnel?._id,
          userId: loggedUser?._id,
          customerId: selectedCustomer.value,
        })
      );
    } else {
      message.error("Please select customer");
      setSelectedCustomer({
        ...selectedCustomer,
        isDisabled: true,
      });
    }
  };

  return (
    <>
      <h2>Employee List</h2>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <div style={{ width: "30%" }}>
          <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
            <Select
              placeholder="Select customer"
              value={selectedCustomer.value}
              onChange={handleChangeCustomer}
              style={{ width: "100%" }}
            >
              {customers?.length > 0 &&
                customers?.map((cus) => (
                  <Option key={cus?._id} value={cus?._id}>
                    {cus?.name}
                  </Option>
                ))}
            </Select>
            <Button type="primary" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
          </div>
          <div>
            <Input
              size="large"
              placeholder="Search..."
              prefix={<SearchOutlined />}
              onChange={(e) => {
                debounceOnSearch(e);
              }}
              allowClear
              onClear={(e) => debounceOnSearch()}
              disabled={selectedCustomer.isDisabled}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <OrgChart
            selectedCustomer={selectedCustomer}
            employeeList={employees}
          />
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            size="large"
            onClick={() => handleAddEmployee(null, "new")}
            disabled={selectedCustomer.isDisabled}
          >
            Add Employee
          </Button>
        </div>
      </div>
      <Card>
        <Table
          columns={columns}
          dataSource={employees}
          pagination={false}
          bordered
          scroll={{
            x: 1000,
          }}
        />
        <div style={{ marginTop: "20px" }}>
          <OrgCharPagination />
        </div>
      </Card>

      <AddEmployeeFormModel
        open={addEmployeeModel}
        setOpen={setAddEmployeeModel}
        employeeList={employees}
        editEmployeeData={editEmployeeData}
        setEditEmployeeData={setEditEmployeeData}
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
      />
    </>
  );
};

export default EmployeeList;
