import React, { useState } from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import WelcomePage from "../../pages/Welcome/WelcomePage";
import { loginApi, sendOtpApi, verifyOtpApi } from "../../redux/apis/auth";
import { authActions } from "../../redux/slices/authSlice";
import { PATH_AUTH, PATH_DASHBOARD } from "../../routes/path";
import "./LoginCss.css";

const LoginForm = () => {
  const [loginForm] = Form.useForm();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [withOtp, setWithOtp] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [sendOtpData, setSendOtpData] = useState({
    mobile: null,
    otp: null,
    isSend: false,
  });

  const loginWithOtpStyle = {
    fontSize: 16,
    fontWeight: 600,
    color: "#1677ff",
    cursor: "pointer",
  };

  const Rules = {
    emailMobile: [
      {
        required: true,
        message: withOtp ? "Please Enter Mobile Number" : "Please Enter Email",
      },
      {
        max: withOtp ? 10 : 100,
        message: withOtp
          ? "Invalid Mobile Number"
          : "Email Maxium length 100 character",
      },
    ],
    password: [{ required: true, message: "Please Enter Password" }],
  };

  const handleLoginWithOtp = () => {
    setWithOtp(!withOtp);
  };

  const handleCreateAccount = () => {
    navigate(PATH_AUTH.register);
  };

  const handleChangeNumber = (val) => {
    setSendOtpData({ ...sendOtpData, mobile: val?.target?.value });
  };

  const handleChangeOTP = (val) => {
    setSendOtpData({ ...sendOtpData, otp: val });
  };

  const handleSendOtp = async () => {
    if (sendOtpData.mobile) {
      try {
        setSendOtp(true);
        const data = {
          emailMobile: sendOtpData?.mobile?.toString(),
        };
        const res = await sendOtpApi(data);
        if (res.data?.success) {
          message.success(res?.data?.message);
          setSendOtp(false);
          setSendOtpData({
            ...sendOtpData,
            isSend: true,
          });
        } else {
          setSendOtp(false);
          setSendOtpData({
            ...sendOtpData,
            isSend: false,
          });
          message.error(res.data?.message);
        }
      } catch (error) {
        setSendOtp(false);
        setSendOtpData({
          ...sendOtpData,
          isSend: false,
        });
        message.error(error.response.data.message);
      }
    } else {
      message.error("Please Enter Mobile Number");
    }
  };

  const handleVerifyOtp = async () => {
    if (sendOtpData.otp) {
      try {
        setSendOtp(true);
        const data = {
          emailMobile: sendOtpData?.mobile?.toString(),
          otp: sendOtpData?.otp?.toString(),
        };

        const res = await verifyOtpApi(data);
        if (res.data?.success) {
          dispatch(authActions.isLogin());
          window.sessionStorage.setItem("pathname", PATH_DASHBOARD.root);
          message.success(res?.data?.message);
          setSendOtp(false);
          setSendOtpData({ mobile: null, otp: null, isSend: false });
        } else {
          setSendOtp(false);
          setSendOtpData({
            ...sendOtpData,
            otp: null,
            isSend: false,
          });
          message.error(res.data?.message);
          window.sessionStorage.setItem("pathname", PATH_AUTH.root);
        }
      } catch (error) {
        setSendOtp(false);
        setSendOtpData({
          ...sendOtpData,
          otp: null,
          isSend: false,
        });
        message.error(error?.response?.data?.message);
        window.sessionStorage.setItem("pathname", PATH_AUTH.root);
      }
    } else {
      message.error("Please Enter OTP!");
    }
  };

  const onFinish = async (values) => {
    try {
      setIsSubmit(true);
      const data = {
        emailMobile: values.emailMobile,
        password: values.password,
        withOtp,
      };
      const res = await loginApi(data);
      if (res.data?.success) {
        dispatch(authActions.isLogin());
        message.success(res.data?.message);
        setIsSubmit(false);
        window.sessionStorage.setItem("pathname", PATH_DASHBOARD.root);
      } else {
        setIsSubmit(false);
        message.success(res.data?.message);
        window.sessionStorage.setItem("pathname", PATH_AUTH.root);
      }
    } catch (error) {
      setIsSubmit(false);
      message.error(
        error?.response ? error?.response.data?.message : error?.message
      );
      window.sessionStorage.setItem("pathname", PATH_AUTH.root);
    }
  };

  return (
    <div
      style={{
        paddingLeft: 20,
        paddingTop: 20,
        // background: "#d3d3d3",
        height: "100%",
      }}
    >
      <Row gutter={16}>
        <Col md={10} lg={6}>
          <div style={{ textAlign: "center", height: "100%", padding: "20px" }}>
            <h2>Login</h2>
            {withOtp ? (
              <>
                <Row>
                  <Col span={24}>
                    <Input
                      style={{ width: "100%" }}
                      maxLength={10}
                      value={sendOtpData?.mobile}
                      stringMode={true}
                      placeholder="Mobile"
                      onChange={handleChangeNumber}
                      className="hide-arrows"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Col>
                </Row>
                <br />
                {sendOtpData?.isSend && (
                  <Row>
                    <Col span={24}>
                      <Input
                        style={{ width: "100%" }}
                        maxLength={6}
                        value={sendOtpData?.otp}
                        stringMode={true}
                        placeholder="Otp"
                        onChange={handleChangeOTP}
                        className="hide-arrows"
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Col>
                  </Row>
                )}

                <br />
                <span style={loginWithOtpStyle} onClick={handleLoginWithOtp}>
                  {withOtp ? "Or Login With Password" : "Or Login With OTP"}
                </span>
                <br />
                <br />
                {sendOtpData?.isSend ? (
                  <Button
                    loading={sendOtp}
                    style={{ border: "1px solid #4551f6", color: "#4551f6" }}
                    onClick={handleVerifyOtp}
                  >
                    Verify OTP & Login
                  </Button>
                ) : (
                  <Button
                    loading={sendOtp}
                    style={{ border: "1px solid #4551f6", color: "#4551f6" }}
                    onClick={handleSendOtp}
                  >
                    Send OTP
                  </Button>
                )}

                <br />
              </>
            ) : (
              <Form name="auth" form={loginForm} onFinish={onFinish}>
                <Row>
                  <Col span={24}>
                    <Form.Item name="emailMobile" rules={Rules.emailMobile}>
                      <Input placeholder={withOtp ? "Mobile" : "Email"} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="password" rules={Rules.password}>
                      <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Link to="/auth/forgot-password">
                      <p style={{ textAlign: "left", width: "fit-content" }}>
                        Forgot Password?
                      </p>
                    </Link>
                  </Col>
                </Row>

                <Button
                  htmlType="submit"
                  loading={isSubmit}
                  type="primary"
                  style={{
                    width: "100%",
                  }}
                >
                  Login
                </Button>
                <div style={{ marginTop: "20px" }}>
                  <span style={loginWithOtpStyle} onClick={handleLoginWithOtp}>
                    {withOtp ? "Or Login With Password" : "Or Login With OTP"}
                  </span>
                </div>
              </Form>
            )}

            <div
              style={{
                marginTop: "40px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <span style={{ fontWeight: 500 }}>Don't have an account?</span>
              <span
                style={{
                  color: "#1677ff",
                  fontSize: "16px",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={handleCreateAccount}
              >
                Sign Up
              </span>
            </div>
          </div>
        </Col>
        <Col md={14} lg={18}>
          <div>
            <WelcomePage />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginForm;
