import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import Router from "./routes";
import { persistor, store } from "./redux/store";
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/en_US';

function App() {
  return (
    <>
      <ConfigProvider locale={locale}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ConfigProvider>
    </>
  );
}

export default App;
