import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { listEmployeeApi } from "../apis/dashboardApi";
import { message } from "antd";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  list: [],
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
  funnelId: null,
  userId: null,
  customerId: null,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    employeeList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.searchText = "";
      state.isNext = false;
      state.funnelId = action.payload.funnelId;
      state.userId = action.payload.userId;
      state.customerId = action.payload.customerId;
    },
    employeeListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
    },
    employeeListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    employeeListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
      state.funnelId = action.payload.funnelId;
    },
    employeeListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
      state.funnelId = action.payload.funnelId;
    },
    employeeSearch(state, action) {
      state.searchText = action.payload.searchText;
      state.page = 1;
      state.limit = 10;
      state.funnelId = action.payload.funnelId;
    },
  },
});

export const {
  actions: employeeActions,
  reducer: employeeReducer,
  name: employeeKey,
} = employeeSlice;

const selectDomain = (state) => state.employee || initialState;

export const selectEmployee = createSelector(
  [selectDomain],
  (employeeState) => employeeState
);

export const getState = (state) => state.employee;

export function* employeeListReq() {
  try {
    const states = yield select(getState);
    const res = yield listEmployeeApi(states);

    if (res.data.success) {
      yield put(employeeActions.employeeListSuccess(res.data));
    } else {
      message.error(res.message);
      yield put(employeeActions.employeeListFailed(res.data));
    }
  } catch (error) {
    message.error(error?.response?.data?.message || "Something went wrong");
    yield put(employeeActions.employeeListFailed(error));
  }
}

export function* employeeSaga() {
  yield takeLatest(employeeActions.employeeList.type, employeeListReq);
  yield takeLatest(employeeActions.employeeListPageLimit.type, employeeListReq);
  yield takeLatest(employeeActions.employeeListLimit.type, employeeListReq);
  yield takeLatest(employeeActions.employeeSearch.type, employeeListReq);
}
