import { Col, Modal, Row } from "antd";
import React from "react";

const ViewEmployeeDetailsModel = ({ data, setData }) => {
  const handleCancel = () => {
    setData({ data: null, model: false });
  };

  return (
    <>
      <Modal
        title="Employee Details"
        open={data?.model}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Employee Name:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.employeeName ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Email:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.email ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Mobile:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.mobile ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Designation:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.designation ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Department:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.department ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Responsibility:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.responsibility ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Reporting:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.reporting ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Reportee:-</span>
          </Col>
          <Col span={12}>
            <span>
              {data?.data?.reportee?.length
                ? data?.data?.reportee?.toString()
                : "-"}
            </span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ViewEmployeeDetailsModel;
