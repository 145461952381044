import React from "react";
import { Divider } from "antd";
import ListOfProgressReportStages from "../../sections/ProgressReportManage/ListOfProgressReportStages";
import FunnelRowProgressReport from "../../sections/ProgressReportManage/FunnelRowProgressReport";

const ProgressReport = () => {
  return (
    <>
      <div>
        <h2 style={{textAlign: 'center'}}>Progress Report</h2>
        <ListOfProgressReportStages />
        <Divider />
        <FunnelRowProgressReport />
      </div>
    </>
  );
};

export default ProgressReport;
