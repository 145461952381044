/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AddEditCustomer from "../../sections/CustomerManage/AddEditCustomer";
import { Card, Col, Form, Row, Spin, message } from "antd";
import { PATH_DASHBOARD } from "../../routes/path";
import { editCustomerApi, getCustomerApi } from "../../redux/apis/dashboardApi";

const EditCustomer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const params = useParams();
  const [viewData, setViewData] = useState(null);
  const [isViewData, setIsViewData] = useState(null);

  const [addEditCustomerForm] = Form.useForm();

  useEffect(() => {
    if (location) {
      if (location.pathname.includes("edit")) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
      }
    }
  }, [location]);

  const handleCancle = () => {
    navigate(PATH_DASHBOARD.customer);
  };

  useEffect(() => {
    if (isEdit && params) {
      (async () => {
        try {
          setIsViewData(true);
          const viewRes = await getCustomerApi(params?.id);
          if (viewRes?.data?.success) {
            setIsViewData(false);
            setViewData(viewRes?.data?.data);
          } else {
            setIsViewData(false);
            setViewData(null);
          }
        } catch (error) {
          console.log("error ---->", error);
          setIsViewData(false);
          setViewData(null);
        }
      })();
    }
  }, [params, isEdit]);

  useEffect(() => {
    if (viewData) {
      addEditCustomerForm.setFieldsValue({
        name: viewData?.name,
        industry: viewData?.industry,
        gstNo: viewData?.gstNo,
        subIndustry: viewData?.subIndustry,
        officeAddress: viewData?.officeAddress,
        totalNoOfPlant: viewData?.totalNoOfPlant,
        cusPlantAddress: viewData?.cusPlantAddress,
        plantOneAddress: viewData?.plantOneAddress,
        plantTwoAddress: viewData?.plantTwoAddress,
        custSiteAddress: viewData?.custSiteAddress,
        location: viewData?.location,
        paymentTerms: viewData?.paymentTerms,
        state: viewData?.state,
        website: viewData?.website,
        isNewProject: viewData?.isNewProject,
      });
    }
  }, [viewData, isEdit]);

  const onFinish = async (values) => {
    try {
      setIsSubmit(true);
      const isNewProject = values?.isNewProject ? true : false;

      const data = {
        ...values,
        isNewProject,
        isActive: true,
      };

      const editRes = await editCustomerApi(params?.id, data);

      if (editRes.data.success) {
        message.success(editRes.data.message);
        setIsSubmit(false);
        setTimeout(() => {
          navigate(PATH_DASHBOARD.customer);
        }, 1000);
      } else {
        setIsSubmit(false);
        message.error(editRes.data.message);
      }
    } catch (error) {
      setIsSubmit(false);
      message.error(error?.response.data?.message);
    }
  };

  if (isViewData && isEdit) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spin />
        </div>
      </>
    );
  }

  return (
    <>
      <Card title="Edit Customer">
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="mt-4">
            <Form
              name="addEditCustomerForm"
              layout="vertical"
              form={addEditCustomerForm}
              onFinish={onFinish}
            >
              <AddEditCustomer
                isEdit={isEdit}
                isSubmit={isSubmit}
                handleCancle={handleCancle}
                viewData={viewData}
              />
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};

export default EditCustomer;
