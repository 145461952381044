import React from "react";
import { Button, Row, Col, Dropdown, Menu } from "antd";
import { LeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  potentialMapActions,
  selectPotentialMap,
} from "../../redux/slices/potentialMapSlice";

export function PotentialMapPagination() {
  const pageSizeOptions = ["10", "20", "50"];
  const potentialData = useSelector(selectPotentialMap);
  const dispatch = useDispatch();

  const Mymenu = (
    <Menu>
      {pageSizeOptions.map((e, i) => (
        <Menu.Item
          onClick={() => {
            dispatch(
              potentialMapActions.potentialMapListLimit({ limit: Number(e) })
            );
          }}
          key={i}
        >
          {e}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Row gutter={10}>
          <Col>
            <Button
              size="middle"
              disabled={potentialData.page <= 1}
              onClick={() => {
                dispatch(
                  potentialMapActions.potentialMapListPageLimit({
                    page: potentialData.page - 1,
                  })
                );
              }}
              icon={<LeftOutlined />}
            />
          </Col>
          <Col>
            <Button size="middle">{potentialData.page}</Button>
          </Col>
          <Col>
            <Button
              size="middle"
              disabled={!potentialData.isNext}
              onClick={() => {
                dispatch(
                  potentialMapActions.potentialMapListPageLimit({
                    page: potentialData.page + 1,
                  })
                );
              }}
              icon={<RightOutlined />}
            />
          </Col>
          <Col>
            <Dropdown overlay={Mymenu}>
              <Button size="middle">
                {potentialData.limit} <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PotentialMapPagination;
