import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

const queryBuilder = (att) => {
  let queryString = `?`;

  if (att.sort && att.sortType) {
    queryString += `sort=${att.sort}&sortType=${att.sortType}`;
  }

  if (att.skip) {
    queryString += "&skip=" + (att.page - 1) * att.limit;
  }

  if (att.limit) {
    queryString += "&limit=" + att.limit;
  }

  if (att.searchText) {
    queryString += "&search=" + att.searchText;
  }

  if (att.funnelId) {
    queryString += "&funnelId=" + att.funnelId;
  }

  if (att.userId) {
    queryString += "&userId=" + att.userId;
  }

  if (att.superUserId) {
    queryString += "&superUserId=" + att.superUserId;
  }

  if (att.createdBy) {
    queryString += "&createdBy=" + att.createdBy;
  }

  if (att.isCarryForward) {
    queryString += "&isCarryForward=true";
  } else {
    queryString += "&isCarryForward=false";
  }

  if (att.isDelete) {
    queryString += "&isDelete=true";
  } else {
    queryString += "&isDelete=false";
  }

  if (att.stageFilter) {
    queryString += "&stageFilter=" + att.stageFilter;
  }

  if (att.customerName) {
    queryString += "&customerName=" + att.customerName;
  }

  if (att.filterBy) {
    queryString += "&filterBy=" + att.filterBy;
  }

  if (att.filterYear) {
    queryString += "&filterYear=" + att.filterYear;
  }

  if (att.selectedCustomer) {
    queryString += "&selectedCustomer=" + att.selectedCustomer;
  }

  if (att.mainFunnelRow) {
    queryString += "&mainFunnelRow=" + att.mainFunnelRow;
  }

  if (att.customerId) {
    queryString += "&customerId=" + att.customerId;
  }

  if (att.managementSuperUser) {
    queryString += "&managementSuperUser=" + att.managementSuperUser;
  }

  return queryString;
};

// customer
export const customerListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/customer/list` + str, { withCredentials: true });
};

export const addCustomerApi = (data) => {
  return axios.post(`${baseUrl}/customer/add`, data, { withCredentials: true });
};

export const getCustomerApi = (id) => {
  return axios.get(`${baseUrl}/customer/get/` + id, { withCredentials: true });
};

export const editCustomerApi = (id, data) => {
  return axios.put(`${baseUrl}/customer/edit/` + id, data, {
    withCredentials: true,
  });
};

export const deleteCustomerApi = (id) => {
  return axios.delete(`${baseUrl}/customer/delete/` + id, {
    withCredentials: true,
  });
};

// product
export const productListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/product/list` + str, { withCredentials: true });
};

export const addProductApi = (data) => {
  return axios.post(`${baseUrl}/product/add`, data, { withCredentials: true });
};

export const getProductApi = (id) => {
  return axios.get(`${baseUrl}/product/get/` + id, { withCredentials: true });
};

export const editProductApi = (id, data) => {
  return axios.put(`${baseUrl}/product/edit/` + id, data, {
    withCredentials: true,
  });
};

export const deleteProductApi = (id) => {
  return axios.delete(`${baseUrl}/product/delete/` + id, {
    withCredentials: true,
  });
};

export const downloadExcelForProductSampleApi = () => {
  return axios.get(`${baseUrl}/product/download-sample`, {
    withCredentials: true,
    responseType: "arraybuffer",
    headers: { "Content-Type": "blob" },
  });
};

export const importProductsApi = (data) => {
  return axios.post(`${baseUrl}/product/import-products`, data, {
    withCredentials: true,
  });
};

export const categoryListApi = (data) => {
  let queryString = "";
  if (data.funnelId) {
    queryString += "?funnelId=" + data.funnelId;
  }

  return axios.get(`${baseUrl}/product/category/list` + queryString, {
    withCredentials: true,
  });
};

export const editPricingApi = (data) => {
  return axios.put(`${baseUrl}/product/pricing-change`, data, {
    withCredentials: true,
  });
};

// user

export const updateProfile = (id, data) => {
  return axios.put(`${baseUrl}/user/edit/${id}`, data, {
    withCredentials: true,
  });
};

export const changePassword = (id, data) => {
  return axios.put(`${baseUrl}/user/change-password/${id}`, data, {
    withCredentials: true,
  });
};

// preset

export const editPreset = (data) => {
  return axios.put(`${baseUrl}/preset/update`, data, {
    withCredentials: true,
  });
};

export const getPresetApi = (data) => {
  return axios.get(`${baseUrl}/preset/get?funnelId=${data?.funnelId}`, {
    withCredentials: true,
  });
};

export const apiGetPresetList = () => {
  return axios.get(`${baseUrl}/preset/get-list`, {
    withCredentials: true,
  });
};

// funnel
export const funnelListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/funnel/list` + str, { withCredentials: true });
};

export const addFunnelApi = (data) => {
  return axios.post(`${baseUrl}/funnel/add`, data, { withCredentials: true });
};

export const editFunnelApi = (id, data) => {
  return axios.put(`${baseUrl}/funnel/edit/${id}`, data, {
    withCredentials: true,
  });
};

export const getFunnelApi = (data) => {
  return axios.get(`${baseUrl}/funnel/get/${data?.funnelId}`, {
    withCredentials: true,
  });
};

export const deleteFunnelApi = (id) => {
  return axios.delete(`${baseUrl}/funnel/delete/${id}`, {
    withCredentials: true,
  });
};

export const deleteFunnelRowApi = (id) => {
  return axios.delete(`${baseUrl}/funnel-row/delete/${id}`, {
    withCredentials: true,
  });
};

export const funnelRowListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/funnel-row/list` + str, {
    withCredentials: true,
  });
};

export const potentialMapListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/funnel-row/potential-map/list` + str, {
    withCredentials: true,
  });
};

export const potentialMapTotalApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/funnel-row/potential-map/total` + str, {
    withCredentials: true,
  });
};

export const addFunnelRowApi = (data) => {
  return axios.post(`${baseUrl}/funnel-row/add`, data, {
    withCredentials: true,
  });
};

export const editFunnelRowApi = (id, data) => {
  return axios.put(`${baseUrl}/funnel-row/edit/${id}`, data, {
    withCredentials: true,
  });
};

export const getFunnelRowApi = (id) => {
  return axios.get(`${baseUrl}/funnel-row/get/${id}`, {
    withCredentials: true,
  });
};

// progress stages
export const editProgressStagesApi = (data) => {
  return axios.put(`${baseUrl}/progress-stage/edit`, data, {
    withCredentials: true,
  });
};

// organization chart
export const listEmployeeApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/employee/list` + str, {
    withCredentials: true,
  });
};

export const addEmployeeApi = (data) => {
  return axios.post(`${baseUrl}/employee/add`, data, {
    withCredentials: true,
  });
};

export const editEmployeeApi = (id, data) => {
  return axios.put(`${baseUrl}/employee/edit/${id}`, data, {
    withCredentials: true,
  });
};

export const getOrgChartApi = (data) => {
  return axios.get(
    `${baseUrl}/employee/org-chart?userId=${data?.userId}&funnelId=${data?.funnelId}&customerId=${data?.customerId}`,
    {
      withCredentials: true,
    }
  );
};

export const deleteEmployeeApi = (id) => {
  return axios.delete(`${baseUrl}/employee/delete/` + id, {
    withCredentials: true,
  });
};

// rights management
export const editRightsApi = (data) => {
  return axios.put(`${baseUrl}/role/edit`, data, {
    withCredentials: true,
  });
};

export const getRoleApi = (filter) => {
  return axios.get(`${baseUrl}/role/get-role?role=${filter}`, {
    withCredentials: true,
  });
};

// role management
export const roleListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/super-user/list` + str, {
    withCredentials: true,
  });
};

export const mainRolesListApi = () => {
  return axios.get(`${baseUrl}/role/list`, {
    withCredentials: true,
  });
};

// super user list
export const superUserListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/user/list` + str, { withCredentials: true });
};

export const addSubUserAdminApi = (data) => {
  return axios.post(`${baseUrl}/super-user/add`, data, {
    withCredentials: true,
  });
};

export const editSubUserAdminApi = (id, data) => {
  return axios.put(`${baseUrl}/super-user/edit/${id}`, data, {
    withCredentials: true,
  });
};

export const deleteSubUserAdminApi = (id) => {
  return axios.delete(`${baseUrl}/super-user/delete/${id}`, {
    withCredentials: true,
  });
};

export const stateAndCitiesApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/user/get/user-wise-state-city-list` + str, {
    withCredentials: true,
  });
};

// sales vs actual
export const salesVsActualListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/sales-vs-actual/list` + str, {
    withCredentials: true,
  });
};

// subscriptions
export const getUserpriceApi = () => {
  return axios.get(`${baseUrl}/subcription/get-user-price`, {
    withCredentials: true,
  });
};

export const getPaymentKeyApi = () => {
  return axios.get(`${baseUrl}/payment/get-payment-key`, {
    withCredentials: true,
  });
};

export const postPaymentApi = (data) => {
  return axios.post(`${baseUrl}/payment/add`, data, {
    withCredentials: true,
  });
};

export const getUserLicenseListApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/payment/get-total-active-user-licenses` + str, {
    withCredentials: true,
  });
};

export const getPaymentLicenseIdsApi = (data) => {
  let str = data ? queryBuilder(data) : "";

  return axios.get(`${baseUrl}/payment/get-payment-license-ids` + str, {
    withCredentials: true,
  });
};

export const addPaymentLicenseIdsApi = (data) => {
  return axios.post(`${baseUrl}/payment/add-payment-license-ids`, data, {
    withCredentials: true,
  });
};

export const getTransactionApi = (id) => {
  return axios.get(`${baseUrl}/payment/get-transaction/${id}`, {
    withCredentials: true,
  });
};

export const editTransactionApi = (id, data) => {
  return axios.put(`${baseUrl}/payment/edit-transaction/${id}`, data, {
    withCredentials: true,
  });
};

// dashboard
export const getDashboardApi = (att) => {
  let queryString = {};

  if (att.userId) {
    queryString.userId = att.userId;
  }

  if (att.funnelId) {
    queryString.funnelId = att.funnelId;
  }

  if (att.chartFilter) {
    queryString.chartFilter = att.chartFilter;
  }

  if (att.selectedProduct) {
    queryString.selectedProduct = att.selectedProduct;
  }

  if (att.selectedCustomer) {
    queryString.selectedCustomer = att.selectedCustomer;
  }

  if (att.customerList?.length > 0) {
    queryString.customerList = att.customerList;
  }

  if (att.productList?.length > 0) {
    queryString.productList = att.productList;
  }

  if (att.filterYear) {
    queryString.filterYear = att.filterYear;
  }

  if (att.filterMonth !== null && att.filterMonth !== undefined) {
    queryString.filterMonth = att.filterMonth;
  }

  if (att.filterQuater) {
    queryString.filterQuater = att.filterQuater;
  }

  return axios.post(`${baseUrl}/dashboard/data`, queryString, {
    withCredentials: true,
  });
};
