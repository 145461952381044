import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { funnelRowListApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  list: [],
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
  funnelId: null,
  userId: null,
  isDelete: false,
  isCarryForward: false,
  mainFunnelRow: null,
};

const carryForwardSlice = createSlice({
  name: "carryforwardrow",
  initialState,
  reducers: {
    carryForwardRowList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.searchText = "";
      state.isNext = false;
      state.funnelId = action.payload.funnelId;
      state.userId = action.payload.userId;
      state.isDelete = action.payload.isDelete;
      state.isCarryForward = action.payload.isCarryForward;
      state.mainFunnelRow = action.payload.mainFunnelRow;
    },
    carryForwardRowListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
    },
    carryForwardRowListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    carryForwardRowListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
      state.funnelId = action.payload.funnelId;
    },
    carryForwardRowListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
      state.funnelId = action.payload.funnelId;
    },
    carryForwardRowSearch(state, action) {
      state.searchText = action.payload.searchText;
      state.page = 1;
      state.limit = 10;
      state.funnelId = action.payload.funnelId;
    },
  },
});

export const {
  actions: carryForwardRowActions,
  reducer: carryForwardRowReducer,
  name: carryForwardRowKey,
} = carryForwardSlice;

const selectDomain = (state) => state.carryforwardrow || initialState;

export const selectCarryForwardRow = createSelector(
  [selectDomain],
  (carryForwardRowState) => carryForwardRowState
);

export const getState = (state) => state.carryforwardrow;

export function* carryForwardRowListReq() {
  try {
    const states = yield select(getState);
    const res = yield funnelRowListApi(states);

    if (res.data.success) {
      yield put(carryForwardRowActions.carryForwardRowListSuccess(res.data));
    } else {
      yield put(carryForwardRowActions.carryForwardRowListFailed(res.data));
    }
  } catch (error) {
    yield put(carryForwardRowActions.carryForwardRowListFailed(error));
  }
}

export function* carryForwardRowSaga() {
  yield takeLatest(
    carryForwardRowActions.carryForwardRowList.type,
    carryForwardRowListReq
  );
  yield takeLatest(
    carryForwardRowActions.carryForwardRowListPageLimit.type,
    carryForwardRowListReq
  );
  yield takeLatest(
    carryForwardRowActions.carryForwardRowListLimit.type,
    carryForwardRowListReq
  );
  yield takeLatest(
    carryForwardRowActions.carryForwardRowSearch.type,
    carryForwardRowListReq
  );
}
