import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Login from "../pages/login/Login";
import PageNotFound from "../pages/404/PageNotFound";
import Register from "../pages/Register/Register";
import GuestGuard from "../auth/GuestGuard";
import Dashboard from "../pages/Dashboard/Dashboard";
import AuthGuard from "../auth/AuthGuard";
import { useDispatch } from "react-redux";
import { authActions } from "../redux/slices/authSlice";
import { useEffect } from "react";
import Layout from "../layouts/sidebar/Sidebar";
import { PATH_AUTH } from "./path";
import CustomerList from "../pages/Customer/CustomerList";
import AddCustomer from "../pages/Customer/AddCustomer";
import EditCustomer from "../pages/Customer/EditCustomer";
import ProductList from "../pages/Product/ProductList";
import AddProduct from "../pages/Product/AddProduct";
import EditProduct from "../pages/Product/EditProduct";
import Profile from "../pages/Profile";
import Presets from "../pages/Profile/Presets";
import ForgotPassword from "../sections/LoginForm/ForgotPassword";
import Header from "../pages/header/Header";
import Footer from "../pages/footer/Footer";
import FunnelList from "../pages/funnel/FunnelList";
import AddFunnel from "../pages/funnel/AddFunnel";
import EditFunnel from "../pages/funnel/EditFunnel";
import FunnelRowList from "../pages/funnelRow/FunnelRowList";
import AddFunnelRow from "../pages/funnelRow/AddFunnelRow";
import EditFunnelRow from "../pages/funnelRow/EditFunnelRow";
import ProgressReport from "../pages/ProgressReport";
import OrganizationChart from "../pages/OrganizationChart";
import ViewChart from "../sections/OrganizationChartManage/ViewChart";
import Management from "../pages/Management/Management";
import PotentialMapped from "../pages/PotentialMap/PotentialMapped";
import SalesVsActual from "../pages/SalesVsActual/SalesVsActual";
import Subscriptions from "../pages/Subscriptions/Subscriptions";
import PricingStructure from "../pages/Welcome/PricingStructure";
import Privacy from "../pages/cms/Privacy";
import Terms from "../pages/cms/Terms";
import SubscriptionPrice from "../pages/Subscriptions/SubscriptionPrice";
import SuccessPage from "../pages/paymentPages/success";
import FailurePage from "../pages/paymentPages/failure";
import RenewalSubscription from "../pages/Subscriptions/RenewalSubscription";
import MainInvoiceFile from "../pages/Subscriptions/MainInvoiceFile";
import HelpAndSupport from "../pages/cms/HelpAndSupport";
import AboutUs from "../pages/cms/AboutUs";
import InquiryPage from "../pages/Inquiries";
import ContactUsPage from "../pages/Welcome/ContactUs";

export default function Router() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAuth = pathname.includes("/app");
  const homeUrl = pathname.includes("/");

  useEffect(() => {
    if (isAuth || homeUrl) {
      dispatch(authActions.isLogin());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="app-container">
        {!isAuth && (
          <div className="header">
            <Header />
          </div>
        )}
        <div className="content">
          <Routes>
            <Route>
              <Route
                path="/auth/login"
                element={
                  <GuestGuard>
                    <Login />
                  </GuestGuard>
                }
              />
              <Route
                path="/auth/register"
                element={
                  <GuestGuard>
                    <Register />
                  </GuestGuard>
                }
              />
              <Route
                path="/auth/forgot-password"
                element={
                  <GuestGuard>
                    <ForgotPassword />
                  </GuestGuard>
                }
              />
              <Route path="/pricing-structure" element={<PricingStructure />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/help-and-support" element={<HelpAndSupport />} />
              <Route path="/inquiry" element={<InquiryPage />} />
              <Route path="/contact-us" element={<ContactUsPage />} />
            </Route>
            <Route element={<Layout />}>
              <Route
                path="/app/dashboard"
                element={
                  <AuthGuard>
                    <Dashboard />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/customer"
                element={
                  <AuthGuard>
                    <CustomerList />
                  </AuthGuard>
                }
              />
              <Route
                path="/app/customer/add"
                element={
                  <AuthGuard>
                    <AddCustomer />
                  </AuthGuard>
                }
              />
              <Route
                path="/app/customer/edit/:id"
                element={
                  <AuthGuard>
                    <EditCustomer />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/product"
                element={
                  <AuthGuard>
                    <ProductList />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/product/add"
                element={
                  <AuthGuard>
                    <AddProduct />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/product/edit/:id"
                element={
                  <AuthGuard>
                    <EditProduct />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/profile"
                element={
                  <AuthGuard>
                    <Profile />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/presets"
                element={
                  <AuthGuard>
                    <Presets />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/funnel"
                element={
                  <AuthGuard>
                    <FunnelList />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/funnel/add"
                element={
                  <AuthGuard>
                    <AddFunnel />
                  </AuthGuard>
                }
              />
              <Route
                path="/app/funnel/edit/:id"
                element={
                  <AuthGuard>
                    <EditFunnel />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/funnel-row"
                element={
                  <AuthGuard>
                    <FunnelRowList />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/funnel-row/add/:id"
                element={
                  <AuthGuard>
                    <AddFunnelRow />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/funnel-row/edit/:id/:rowId"
                element={
                  <AuthGuard>
                    <EditFunnelRow />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/progress-report"
                element={
                  <AuthGuard>
                    <ProgressReport />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/organization-chart"
                element={
                  <AuthGuard>
                    <OrganizationChart />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/organization-chart/view/:customerId"
                element={
                  <AuthGuard>
                    <ViewChart />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/management"
                element={
                  <AuthGuard>
                    <Management />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/potential-map"
                element={
                  <AuthGuard>
                    <PotentialMapped />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/sales-vs-actual"
                element={
                  <AuthGuard>
                    <SalesVsActual />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/subscriptions"
                element={
                  <AuthGuard>
                    <Subscriptions />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/subscription-price"
                element={
                  <AuthGuard>
                    <SubscriptionPrice />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/renewal-subscription"
                element={
                  <AuthGuard>
                    <RenewalSubscription />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/payment-success"
                element={
                  <AuthGuard>
                    <SuccessPage />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/payment-failed"
                element={
                  <AuthGuard>
                    <FailurePage />
                  </AuthGuard>
                }
              />

              <Route
                path="/app/subcription/invoice/:id"
                element={
                  <AuthGuard>
                    <MainInvoiceFile />
                  </AuthGuard>
                }
              />
            </Route>

            <Route
              path="/"
              element={<Navigate to={PATH_AUTH.root} replace />}
              index={true}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
        {!isAuth && (
          <div className="footer">
            <Footer />
          </div>
        )}
      </div>
    </>
  );
}
