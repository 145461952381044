import { Button, Col, Form, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddProgressStagesFrom = ({
  open,
  setOpen,
  formDefine,
  handleSubmit,
  submitLoading,
  rowData,
}) => {
  const rules = {
    progressStage: [
      {
        required: true,
        message: "Please Select Progress Stage",
      },
    ],
  };

  const loggedUser = useSelector(selectLoggedUser);

  const [listProgress, setListProgress] = useState([]);

  useEffect(() => {
    if (loggedUser?.stages?.length > 0) {
      setListProgress(loggedUser?.stages);
    } else {
      setListProgress([]);
    }
  }, [loggedUser]);

  const handleCancel = () => {
    setOpen(false);
    formDefine?.resetFields();
  };

  return (
    <>
      <Modal
        title="Add/Edit Progress Stage"
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="mt-4">
            <Form
              name={formDefine}
              layout="vertical"
              form={formDefine}
              onFinish={handleSubmit}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="progressStage"
                    label="Progress Stage"
                    rules={rules.progressStage}
                  >
                    <Select
                      allowClear
                      style={{
                        width: "100%",
                        maxHeight: "300px",
                      }}
                    >
                      {listProgress?.map((v, index) => (
                        <Option key={index} value={index + 1}>
                          {index + 1}. {v?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handleCancel} htmlType="reset" type="default">
                  Cancel
                </Button>
                <Button
                  loading={submitLoading}
                  htmlType="submit"
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddProgressStagesFrom;
