import React, { useEffect, useState } from "react";
import { Button, Input, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { funnelActions, selectFunnel } from "../../redux/slices/funnelSlice";
import DashboardCharts from "./DashboardCharts";
import { addFunnelApi } from "../../redux/apis/dashboardApi";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { PATH_DASHBOARD } from "../../routes/path";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const funnelData = useSelector(selectFunnel);
  const loggedUser = useSelector(selectLoggedUser);

  const [newFunnelModel, setNewFunnelModel] = useState(false);
  const [newFunnelData, setNewFunnelData] = useState({
    funnelName: null,
    isLoading: false,
  });

  useEffect(() => {
    dispatch(funnelActions.funnelList({ limit: 10, page: 1 }));
    // eslint-disable-next-line
  }, []);

  const handleOpenNewFunnel = () => {
    setNewFunnelModel(true);
  };

  const handleCloseNewFunnel = () => {
    setNewFunnelModel(false);
    setNewFunnelData({ funnelName: null, isLoading: false });
  };

  const handleCreateFunnel = async () => {
    try {
      if (newFunnelData?.funnelName) {
        setNewFunnelData({ ...newFunnelData, isLoading: true });
        const data = {
          funnelName: newFunnelData?.funnelName,
        };
        const res = await addFunnelApi(data);

        if (res?.data?.success) {
          setNewFunnelData({ ...newFunnelData, isLoading: false });
          message.success(res?.data?.message);
          setNewFunnelModel(false);
          setNewFunnelData({ funnelName: null, isLoading: false });

          setTimeout(() => {
            dispatch(funnelActions.funnelList({ limit: 10, page: 1 }));
            window.location.reload()
          }, 1000);
        } else {
          setNewFunnelData({ ...newFunnelData, isLoading: false });
          message.error(res?.data?.message);
        }
      } else {
        setNewFunnelData({ ...newFunnelData, isLoading: false });
        message.error("Please Enter Funnel Name");
      }
    } catch (error) {
      setNewFunnelData({ ...newFunnelData, isLoading: false });
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      {funnelData?.list?.length > 0 ? (
        <>
          {Object.keys(loggedUser?.activeFunnel).length > 0 ? (
            <DashboardCharts />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  height: "90vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  size="large"
                  onClick={() => navigate(PATH_DASHBOARD.funnel)}
                >
                  Please Active Funnel
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            size="large"
            icon={<PlusOutlined />}
            onClick={handleOpenNewFunnel}
          >
            Create New Funnel
          </Button>
        </div>
      )}

      <Modal
        title="Add New Funnel"
        open={newFunnelModel}
        onOk={handleCreateFunnel}
        onCancel={handleCloseNewFunnel}
        confirmLoading={newFunnelData?.isLoading}
        okText="Submit"
      >
        <div>
          <p>Funnel Name</p>
          <Input
            placeholder="Enter Funnel Name"
            value={newFunnelData?.funnelName}
            onChange={(e) => setNewFunnelData({ funnelName: e?.target?.value })}
          />
        </div>
      </Modal>
    </>
  );
};

export default Dashboard;
