import React, { useState, useEffect, useCallback, useRef } from "react";
import PricingStructurePng from "../../assets/images/pricing-structure.png";
import { Col, Divider, Input, Row, Tooltip, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  PauseOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { PATH_AUTH } from "../../routes/path";
import { useNavigate } from "react-router";
import {
  selectSubcription,
  subcriptionActions,
} from "../../redux/slices/subcriptionSlice";
import "./pricing.css";

const initialState = {
  price: 0,
  GST: 18,
  noOfLicense: 1,
  setupCost: 10,
  isCoupon: false,
  couponCode: null,
  couponAttempt: 1,
  discountPrice: null,
};

const PricingStructure = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subcriptionData = useSelector(selectSubcription);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    dispatch(subcriptionActions.subcription());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (subcriptionData?.viewData?.perUserPrice !== undefined) {
      setState((prevState) => ({
        ...prevState,
        price: subcriptionData.viewData.perUserPrice || 0,
      }));
    }
  }, [subcriptionData]);

  const handlePlusValue = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      noOfLicense: prevState.noOfLicense + 1,
      couponCode: null,
      discountPrice: null,
      isCoupon: false,
      couponAttempt: 1,
    }));
  }, []);

  const handleMinusValue = useCallback(() => {
    setState((prevState) => {
      if (prevState.noOfLicense > 1) {
        return {
          ...prevState,
          noOfLicense: prevState.noOfLicense - 1,
          couponCode: null,
          discountPrice: null,
          isCoupon: false,
          couponAttempt: 1,
        };
      }
      return prevState;
    });
  }, []);

  const handleApplyCouponCode = useCallback(() => {
    const { couponCode } = state;

    if (!couponCode) {
      message.error("Please enter coupon code");
      return;
    }

    const couponCodeList = subcriptionData?.viewData?.couponCode || [];

    if (couponCodeList.length === 0) {
      message.error("No coupon code available");
      return;
    }

    const matchedCoupon = couponCodeList.find(
      (item) => item.name === couponCode
    );

    if (!matchedCoupon) {
      message.error("Invalid coupon code");
      return;
    }

    if (state.couponAttempt === 1) {
      setState((prevState) => ({
        ...prevState,
        discountPrice:
          (prevState.noOfLicense * prevState.price * matchedCoupon.price) / 100,
        couponAttempt: 0,
        isCoupon: false,
      }));
    } else {
      message.error("You already added coupon");
    }
  }, [state, subcriptionData]);

  const handleTotal = useCallback(() => {
    message.warning("Please first do login or sign up");
  }, []);

  const getFinanlAmount = useCallback(() => {
    const { discountPrice, price, noOfLicense, setupCost } = state;

    const totalPrice = price * noOfLicense;
    const setupCostAmount = (totalPrice * setupCost) / 100;

    const finalAmount = discountPrice
      ? totalPrice - discountPrice + setupCostAmount
      : totalPrice + setupCostAmount;

    return finalAmount || 0;
  }, [state]);

  const LicensingRolesInfo = () => (
    <div
      style={{
        fontSize: "18px",
        color: "#37b4de",
        fontWeight: 500,
        textAlign: "center",
      }}
    >
      <div style={{ fontSize: "18px", fontWeight: 500, color: "#37b4de" }}>
        Understand the licensing roles
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "14px",
          marginBottom: "30px",
        }}
      >
        <p style={{ fontWeight: 600, fontSize: "16px", color: "#1f1f1f" }}>
          There will be three roles for users:
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <span>1. Super Admin (All rights)</span>
          <span>2. Admin (Viewing and other rights)</span>
          <span>3. User (Data making and adding rights)</span>
        </div>
        <p style={{ textAlign: "center" }}>
          Once you select the number of users, you will be given a unique ID and
          password for the Super Admin, through which you can assign roles and
          rights to other users if multiple users are present.
        </p>
        <p style={{ textAlign: "center" }}>
          Any number of users you select can be used as Super Admin, Admin, or
          Users. For example, if you select 5 users, you can allocate them as 1
          Super Admin, 2 Admins, and 2 Users, or 1 Super Admin, 1 Admin, and 3
          Users.
        </p>
      </div>
    </div>
  );

  const InfoSection = () => (
    <div className="info-container">
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <CheckCircleFilled style={{ color: "green", fontSize: "20px" }} />
        <span>Renewal charges are 80% of total users cost.</span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <CheckCircleFilled style={{ color: "green", fontSize: "20px" }} />
        <span>Set up cost is a one-time charge only.</span>
      </div>
    </div>
  );

  const CouponCodeSection = ({
    state,
    setState,
    handleApplyCouponCode,
    subcriptionData,
  }) => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef.current && state?.isCoupon) {
        inputRef.current.focus();
      }
    }, [state?.isCoupon]);

    return (
      <div>
        {state?.isCoupon ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Input
              style={{ width: "250px" }}
              placeholder="Coupon code"
              value={state?.couponCode}
              ref={inputRef}
              onChange={(event) =>
                setState({
                  ...state,
                  couponCode: event?.target?.value?.toUpperCase(),
                })
              }
            />
            <div>
              <span
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={handleApplyCouponCode}
              >
                Apply
              </span>
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={() =>
                  setState({
                    ...state,
                    isCoupon: false,
                    couponCode: null,
                    couponAttempt: 1,
                    discountPrice: null,
                  })
                }
              >
                Cancel
              </span>
            </div>
          </div>
        ) : state?.couponCode && !state.isCoupon ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <span style={{ color: "#2e82d1", fontSize: "16px" }}>
              Coupon Applied: {state?.couponCode} ({state?.discountPrice} INR)
            </span>
            <span
              style={{ color: "red", cursor: "pointer" }}
              onClick={() =>
                setState({
                  ...state,
                  isCoupon: false,
                  couponCode: null,
                  couponAttempt: 1,
                  discountPrice: null,
                  price: subcriptionData?.viewData?.perUserPrice,
                })
              }
            >
              Remove
            </span>
          </div>
        ) : (
          <span
            style={{ color: "#2e82d1", fontSize: "16px", cursor: "pointer" }}
            onClick={() => setState({ ...state, isCoupon: true })}
          >
            Have a coupon code?
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        {/* Header Row */}
        <Row>
          <Col span={8}>
            <div
              className="back-to-login"
              onClick={() => navigate(PATH_AUTH.root)}
            >
              <ArrowLeftOutlined /> <span>Back to login</span>
            </div>
          </Col>
          <Col span={8}>
            <div className="header">
              <h1>Pricing Structure</h1>
            </div>
          </Col>
          <Col span={8}>
            <div className="image-container">
              <img
                src={PricingStructurePng}
                alt="pricingStructure"
                style={{ width: "250px", height: "200px" }}
              />
            </div>
          </Col>
        </Row>

        {/* Quote Row */}
        <Row>
          <Col span={8}>
            <div className="quote-container">
              <p style={{ fontStyle: "italic" }}>
                "Price is what you pay. Value is what you get."
              </p>
              <p>- Warren Buffet.</p>
            </div>
          </Col>

          {/* Pricing Info */}
          <Col span={8}>
            <div style={{ textAlign: "center" }}>
              <p>
                One year per user license:{" "}
                <strong>
                  {subcriptionData?.viewData?.perUserPrice || 0} INR
                </strong>
                <span> (Including GST {state.GST}%)</span>
              </p>

              {/* License Input Section */}
              <div className="license-container">
                <div>Add Nos. of users license you require</div>
                <div className="license-input">
                  <Tooltip title="Decrease Licenses">
                    <span className="icon-button" onClick={handleMinusValue}>
                      <MinusCircleOutlined />
                    </span>
                  </Tooltip>
                  <Input
                    value={state.noOfLicense}
                    style={{
                      width: "50px",
                      background: "#d9ecff",
                      border: "none",
                    }}
                    readOnly
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) e.preventDefault();
                    }}
                  />
                  <Tooltip title="Increase Licenses">
                    <span className="icon-button" onClick={handlePlusValue}>
                      <PlusCircleOutlined />
                    </span>
                  </Tooltip>
                </div>
              </div>

              <div>
                <span style={{ fontSize: "28px" }}>
                  <PlusOutlined />
                </span>
              </div>

              {/* Setup Cost */}
              <div className="license-container">
                <p>
                  One time setup cost: <span>{state.setupCost}%</span> of Total
                  Value (including GST)
                </p>
              </div>

              <div>
                <span style={{ fontSize: "28px" }}>
                  <PauseOutlined rotate={90} />
                </span>
              </div>

              {/* Coupon Code Section */}
              <CouponCodeSection
                state={state}
                setState={setState}
                handleApplyCouponCode={handleApplyCouponCode}
                subcriptionData={subcriptionData}
              />

              {/* Total Amount */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className={`total-container ${
                    state?.isCoupon ? "disabled-total" : ""
                  }`}
                  onClick={() => !state?.isCoupon && handleTotal(state)}
                >
                  <p>
                    Total = <strong>{getFinanlAmount(state)}</strong> INR
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* Additional Info */}
        <InfoSection />

        <Divider />

        {/* Licensing Roles Info */}
        <LicensingRolesInfo />
      </div>
    </>
  );
};

export default PricingStructure;
