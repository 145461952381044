import { Button, Card, Col, Modal, Row, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  potentialMapActions,
  selectPotentialMap,
} from "../../redux/slices/potentialMapSlice";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "../../routes/path";
import PotentialMapPagination from "./PotentialMapPagination";
import {
  customerActions,
  selectCustomer,
} from "../../redux/slices/customerSlice";
import { renderPresetWisePrice } from "../../utils/shared/presetWiseValue";

const { Option } = Select;

const PotentialMapped = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [potentialMaps, setPotentialMaps] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [filterCustomer, setFilterCustomer] = useState("All");
  const [productData, setProductData] = useState({
    isOpen: false,
    data: null,
  });

  const loggedUser = useSelector(selectLoggedUser);
  const potentialMapData = useSelector(selectPotentialMap);
  const customerData = useSelector(selectCustomer);

  const funnelData = loggedUser?.activeFunnel;
  const viewPotentialData = funnelData;

  const presetsData = loggedUser?.presets;

  useEffect(() => {
    dispatch(customerActions.customerList({ limit: 9999, page: 1 }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (customerData) {
      let customerList = customerData?.list?.map((val) => {
        return {
          label: val?.name,
          value: val?.name,
        };
      });

      customerList?.unshift({ label: "All", value: "All" });
      setCustomers(customerList);
    }
  }, [customerData]);

  const columns = [
    {
      title: <span style={{ fontSize: "12px" }}>Competition Selection</span>,
      dataIndex: "competitionSelection",
      render: (val, elm) => {
        return <span>{elm?.potentialValue?.competitionSelection || ""}</span>;
      },
    },
    {
      title: <span style={{ fontSize: "12px" }}>Competition Volume</span>,
      dataIndex: "competitionVolume",
      render: (val, elm) => {
        return <span>{elm?.potentialValue?.competitionVolume || ""}</span>;
      },
    },
    {
      title: <span style={{ fontSize: "12px" }}>Competition Price</span>,
      dataIndex: "competitionPrice",
      render: (val, elm) => {
        return (
          <span>
            {elm?.potentialValue?.competitionPrice
              ? renderPresetWisePrice(
                  elm?.potentialValue?.competitionPrice,
                  presetsData
                )
              : 0}
          </span>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>Order Conversion Value (a)</span>
      ),
      dataIndex: "orderConversionValue",
      render: (val, elm) => {
        return (
          <span>
            {elm?.potentialValue?.orderConversionValue
              ? renderPresetWisePrice(
                  elm?.potentialValue?.orderConversionValue,
                  presetsData
                )
              : 0}
          </span>
        );
      },
    },
    {
      title: <span style={{ fontSize: "12px" }}>Product</span>,
      dataIndex: "product",
      render: (val, elm) => {
        return (
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => handleViewProductData(elm)}
          >
            {elm?.productAgaintCompetition?.name || ""}
          </span>
        );
      },
    },
    {
      title: <span style={{ fontSize: "12px" }}>Existing Sale Volume</span>,
      dataIndex: "existingSaleVolume",
      render: (val, elm) => {
        return <span>{elm?.potentialValue?.existingSaleVolume || ""}</span>;
      },
    },
    {
      title: <span style={{ fontSize: "12px" }}>Product Price</span>,
      dataIndex: "productPrice",
      render: (val, elm) => {
        return (
          <span>
            {elm?.potentialValue?.productPrice
              ? renderPresetWisePrice(
                  elm?.potentialValue?.productPrice,
                  presetsData
                )
              : 0}
          </span>
        );
      },
    },
    {
      title: <span style={{ fontSize: "12px" }}>Existing Sale Value (b)</span>,
      dataIndex: "existingSaleValue",
      render: (val, elm) => {
        return (
          <span>
            {elm?.potentialValue?.existingSaleVolue
              ? renderPresetWisePrice(
                  elm?.potentialValue?.existingSaleVolue,
                  presetsData
                )
              : 0}
          </span>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>
          Total <br /> (a + b)
        </span>
      ),
      dataIndex: "total",
      render: (val, elm) => {
        return (
          <span>
            {elm?.potentialValue?.total
              ? renderPresetWisePrice(elm?.potentialValue?.total, presetsData)
              : 0}
          </span>
        );
      },
    },
    {
      title: <span style={{ fontSize: "12px" }}>Actions</span>,
      dataIndex: "actions",
      align: "center",
      render: (_, elm) => (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => navigate(PATH_DASHBOARD.listFunnelRow)}
          >
            View
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (viewPotentialData?._id) {
      dispatch(
        potentialMapActions.potentialMapList({
          limit: 10,
          page: 1,
          funnelId: viewPotentialData?._id,
          userId: loggedUser?._id,
          isDelete: false,
          isCarryForward: false,
        })
      );
      dispatch(
        potentialMapActions.potentialMapTotal({
          funnelId: viewPotentialData?._id,
          userId: loggedUser?._id,
        })
      );
    }
    // eslint-disable-next-line
  }, [viewPotentialData]);

  useEffect(() => {
    if (potentialMapData) {
      let filteredList = potentialMapData?.list;

      setPotentialMaps(filteredList);
    }
    // eslint-disable-next-line
  }, [potentialMapData]);

  const handleChangeCustomer = (value) => {
    setFilterCustomer(value);
  };

  const handleApplyFilter = () => {
    dispatch(
      potentialMapActions.potentialMapFilter({
        funnelId: viewPotentialData?._id,
        customerName: filterCustomer,
      })
    );
    dispatch(
      potentialMapActions.potentialMapTotal({
        funnelId: viewPotentialData?._id,
        userId: loggedUser?._id,
        customerName: filterCustomer,
      })
    );
  };

  const handleViewProductData = (data) => {
    setProductData((pre) => ({
      ...pre,
      data,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    setProductData((pre) => ({
      ...pre,
      data: null,
      isOpen: false,
    }));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
        }}
      >
        <h2>Potential Mapped</h2>
        <h2>
          All Over Total:{" "}
          {potentialMapData?.totalPotential
            ? renderPresetWisePrice(
                potentialMapData?.totalPotential,
                presetsData
              )
            : 0}
        </h2>
      </div>

      <div>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Select
              onChange={handleChangeCustomer}
              placeholder="Filter Customer"
              style={{ width: "100%" }}
            >
              {customers?.map((val, index) => (
                <Option key={index} value={val?.value}>
                  {val?.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Button type="primary" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: "20px" }} className="table-responsive">
        <Card>
          <>
            <Table
              columns={columns}
              dataSource={potentialMaps}
              loading={potentialMapData?.isLoading}
              rowKey="id"
              pagination={false}
              scroll={{
                x: 1000,
              }}
            />
            <div style={{ marginTop: "20px" }}>
              <PotentialMapPagination />
            </div>
          </>
        </Card>
      </div>

      <Modal
        title="Product Details"
        open={productData.isOpen}
        onCancel={handleClose}
        closable
        footer={null}
      >
        <div>
          <Row
            gutter={12}
            style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
          >
            <Col span={12}>
              <span>Product Name:-</span>
            </Col>
            <Col span={12}>
              <span>
                {productData?.data?.productAgaintCompetition?.name ?? "-"}
              </span>
            </Col>
          </Row>
          <Row
            gutter={12}
            style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
          >
            <Col span={12}>
              <span>Product Price:-</span>
            </Col>
            <Col span={12}>
              <span>
                {productData?.data?.productAgaintCompetition?.price ?? "-"}
              </span>
            </Col>
          </Row>
          <Row
            gutter={12}
            style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
          >
            <Col span={12}>
              <span>Product Descriptions:-</span>
            </Col>
            <Col span={12}>
              <span>
                {productData?.data?.productAgaintCompetition?.productSpecs ??
                  "-"}
              </span>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default PotentialMapped;
