import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  addFunnelRowApi,
  customerListApi,
  editFunnelRowApi,
  getCustomerApi,
} from "../../redux/apis/dashboardApi";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { funnelRowActions } from "../../redux/slices/funnelRowSlice";
const { Option } = Select;

const rules = {
  customerId: [
    {
      required: true,
      message: "Please Select Customer Name",
    },
  ],
  location: [
    {
      required: true,
      message: "Please Enter Location",
    },
  ],
};

const AddEditFunnelRow = ({ data, setData, type }) => {
  const [addEditFunnelRowForm] = Form.useForm();

  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoggedUser);

  const [isSubmit, setIsSubmit] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [queryStringsCustomers, setQueryStringsCustomers] = useState({
    loading: false,
    isNext: false,
    limit: 100,
    page: 1,
    sort: "createdAt",
    sortType: "desc",
  });

  useEffect(() => {
    (async () => {
      if (data?.model) {
        try {
          setQueryStringsCustomers({
            ...queryStringsCustomers,
            loading: true,
          });

          const res = await customerListApi({ ...queryStringsCustomers });

          if (res?.data?.success) {
            setQueryStringsCustomers({
              ...queryStringsCustomers,
              loading: false,
              page: queryStringsCustomers.page,
              isNext: res?.data?.isNext,
            });
            setCustomerOptions(res?.data?.data);
          } else {
            setQueryStringsCustomers({
              ...queryStringsCustomers,
              loading: false,
              page: queryStringsCustomers.page,
              isNext: false,
            });
            setCustomerOptions([]);
            message.error(res?.data?.message);
          }
        } catch (error) {
          setQueryStringsCustomers({
            ...queryStringsCustomers,
            loading: false,
            page: queryStringsCustomers.page,
            isNext: false,
          });
          setCustomerOptions([]);
          message.error(error?.response?.data?.message);
        }
      }
    })();

    // eslint-disable-next-line
  }, [data?.model]);

  useEffect(() => {
    if (type === "Edit") {
      addEditFunnelRowForm.setFieldsValue({
        customerId: data?.data?.customerId?._id,
        location: data?.data?.location,
      });
    }
    // eslint-disable-next-line
  }, [type, data]);

  const onFinish = async (values) => {
    try {
      setIsSubmit(true);
      const submitData = {
        ...values,
        funnelId: loggedUser?.activeFunnel?._id,
        preset: loggedUser?.presets?._id,
      };

      let addEditRes = null;

      if (type === "New") {
        addEditRes = await addFunnelRowApi(submitData);
      } else if (type === "Edit") {
        addEditRes = await editFunnelRowApi(data?.data?._id, submitData);
      }

      if (addEditRes?.data?.success) {
        message.success(addEditRes?.data?.message);
        setIsSubmit(false);
        addEditFunnelRowForm.resetFields();
        setData({ data: null, model: false, type: null });
        setTimeout(() => {
          setData({ data: null, model: false, type: null });
          dispatch(
            funnelRowActions.funnelRowList({
              limit: 10,
              page: 1,
              funnelId: loggedUser?.activeFunnel?._id,
            })
          );
        }, 1000);
      } else {
        setIsSubmit(false);
        message.error(addEditRes?.data?.message);
      }
    } catch (error) {
      setIsSubmit(false);
      message.error(error?.response.data?.message);
    }
  };

  const handleCancle = () => {
    addEditFunnelRowForm.resetFields();
    setData({ data: null, model: false, type: null });
  };

  const customerInData = async (value) => {
    if (value !== undefined && value !== null) {
      try {
        const res = await customerListApi({
          ...queryStringsCustomers,
          searchText: value,
        });

        if (res?.data?.success) {
          setQueryStringsCustomers({
            ...queryStringsCustomers,
            loading: false,
            page: queryStringsCustomers.page,
            isNext: res?.data?.isNext,
          });
          setCustomerOptions(res?.data?.data);
        } else {
          setQueryStringsCustomers({
            ...queryStringsCustomers,
            loading: false,
            page: queryStringsCustomers.page,
            isNext: false,
          });
          setCustomerOptions([]);
          message.error(res?.data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  // eslint-disable-next-line
  const debounceCustomer = useCallback(
    helperMethods.debounce(customerInData, 500),
    []
  );

  const handleChangeCustomer = async (value) => {
    try {
      if (value) {
        debounceCustomer(value);
        const customerDetail = await getCustomerApi(value);
        if (customerDetail?.data?.success) {
          addEditFunnelRowForm.setFieldsValue({
            location: customerDetail?.data?.data?.location,
          });
        } else {
          addEditFunnelRowForm.resetFields();
          message.error(customerDetail?.data?.message);
        }
      } else {
        addEditFunnelRowForm.resetFields();
      }
    } catch (error) {
      addEditFunnelRowForm.resetFields();
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Modal
        title={`${type} Funnel Row`}
        open={data?.model}
        onCancel={handleCancle}
        destroyOnClose
        footer={null}
      >
        <Form
          name="addEditFunnelRowForm"
          layout="vertical"
          form={addEditFunnelRowForm}
          onFinish={onFinish}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="customerId"
                label="Customer Name"
                rules={rules.customerId}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  optionFilterProp="children"
                  notFoundContent={
                    queryStringsCustomers.loading ? <Spin size="small" /> : null
                  }
                  loading={queryStringsCustomers.loading}
                  onPopupScroll={async (e) => {
                    let scroll =
                      Math.ceil(e.target.offsetHeight + e.target.scrollTop) >=
                      e.target.scrollHeight;
                    if (scroll) {
                      if (queryStringsCustomers?.isNext) {
                        setQueryStringsCustomers({
                          ...queryStringsCustomers,
                          loading: true,
                        });
                        let customerList = await customerListApi({
                          ...queryStringsCustomers,
                          page: queryStringsCustomers.page + 1,
                        });

                        setQueryStringsCustomers({
                          ...queryStringsCustomers,
                          loading: false,
                          page: queryStringsCustomers.page + 1,
                          isNext: customerList?.data?.isNext,
                        });

                        setCustomerOptions([
                          ...customerOptions,
                          ...customerList?.data?.data,
                        ]);
                      }
                    }
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select Customer"
                  onChange={handleChangeCustomer}
                  allowClear
                  onClear={() => debounceCustomer("")}
                >
                  {customerOptions.map((elm) => (
                    <Option key={elm?._id}>{elm?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="location"
                label="Location"
                rules={rules.location}
              >
                <Input
                  disabled={true}
                  style={{ width: "100%" }}
                  placeholder="Location"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Col span={4}>
              <Button
                onClick={handleCancle}
                style={{ width: "100%" }}
                type="default"
              >
                Cancel
              </Button>
            </Col>
            <Col span={4}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
                loading={isSubmit}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditFunnelRow;
