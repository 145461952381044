import { Button, Input, message } from "antd";
import React, { useState } from "react";
import { editPricingApi } from "../../redux/apis/dashboardApi";

const AllProducts = ({ loggedUser }) => {
  const [productChangedValue, setProductChangedValue] = useState({
    inc: null,
    dec: null,
    loader: false,
  });

  const handleChangeValue = (event, type) => {
    if (event) {
      if (type === "inc") {
        setProductChangedValue({
          ...productChangedValue,
          inc: event.target.value,
        });
      } else if (type === "dec") {
        setProductChangedValue({
          ...productChangedValue,
          dec: event.target.value,
        });
      }
    } else {
      message.error("Please Provide Valid Value");
    }
  };

  const handleSave = async () => {
    setProductChangedValue({ ...productChangedValue, loader: true });
    if (productChangedValue.inc || productChangedValue.dec) {
      const submitData = {
        funnelId: loggedUser?.activeFunnel?._id,
      };

      if (productChangedValue.inc) {
        submitData.type = "all";
        submitData.isInc = true;
        submitData.incPrice = Number(productChangedValue.inc);
      } else if (productChangedValue.dec) {
        submitData.type = "all";
        submitData.isDesc = true;
        submitData.descPrice = Number(productChangedValue.dec);
      }

      try {
        const res = await editPricingApi(submitData);

        if (res?.data?.success) {
          setProductChangedValue({ inc: null, dec: null, loader: false });
          message.success(res?.data?.message);
        } else {
          setProductChangedValue({ ...productChangedValue, loader: false });
          message.error(res?.data?.message);
        }
      } catch (error) {
        setProductChangedValue({ ...productChangedValue, loader: false });
        message.error(
          error?.response?.data?.message ??
            "Something went wrong while saving data."
        );
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "40px",
            justifyContent: "center",
            marginTop: "2rem",
            border: "1px solid #c3c3c3",
            padding: "40px",
            width: "fit-content",
            borderRadius: "8px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "40px" }}
          >
            <span>Products</span>
            <span>All by default</span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "40px" }}
          >
            <span>Increase By %</span>
            <span>
              <Input
                placeholder="5%"
                max={100}
                min={1}
                onChange={(event) => handleChangeValue(event, "inc")}
                className="hide-arrows"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "40px" }}
          >
            <span>Decrease By %</span>
            <span>
              <Input
                placeholder="5%"
                max={100}
                min={1}
                onChange={(event) => handleChangeValue(event, "dec")}
                className="hide-arrows"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </span>
          </div>
        </div>
        <div style={{ display: "flex", gap: "40px" }}>
          <Button
            type="primary"
            size="large"
            onClick={handleSave}
            loading={productChangedValue?.loader}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default AllProducts;
