import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { categoryListApi, productListApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  list: [],
  categoryList: [],
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
  funnelId: null,
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    productList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.funnelId = action.payload.funnelId;
      state.searchText = "";
      state.isNext = false;
    },
    productListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
    },
    productListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    productListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
    },
    productListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
    },
    productSearch(state, action) {
      state.searchText = action.payload.searchText;
      state.page = 1;
      state.limit = 10;
    },
    categoryList(state, action) {
      state.isLoading = true;
      state.funnelId = action.payload.funnelId;
      state.searchText = "";
      state.isNext = false;
    },
    categoryListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.categoryList = action.payload.data;
    },
    categoryListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.categoryList = [];
    },
  },
});

export const {
  actions: productActions,
  reducer: productReducer,
  name: productKey,
} = productSlice;

const selectDomain = (state) => state.products || initialState;

export const selectProduct = createSelector(
  [selectDomain],
  (productState) => productState
);

export const getState = (state) => state.products;

export function* productListReq() {
  try {
    const states = yield select(getState);
    const res = yield productListApi(states);

    if (res.data.success) {
      yield put(productActions.productListSuccess(res.data));
    } else {
      yield put(productActions.productListFailed(res.data));
    }
  } catch (error) {
    yield put(productActions.productListFailed(error));
  }
}

export function* categoryListReq() {
  try {
    const states = yield select(getState);
    const res = yield categoryListApi(states);

    if (res.data.success) {
      yield put(productActions.categoryListSuccess(res.data));
    } else {
      yield put(productActions.categoryListFailed(res.data));
    }
  } catch (error) {
    yield put(productActions.categoryListFailed(error));
  }
}

export function* productSaga() {
  yield takeLatest(productActions.productList.type, productListReq);
  yield takeLatest(productActions.productListPageLimit.type, productListReq);
  yield takeLatest(productActions.productListLimit.type, productListReq);
  yield takeLatest(productActions.productSearch.type, productListReq);
  yield takeLatest(productActions.categoryList.type, categoryListReq);
}
