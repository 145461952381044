import React, { useState } from "react";
import { Modal, Form, Button, Col, Row, Select, Input } from "antd";
import { selectUnitQty } from "../../utils/general-constant";

const AddInquiryForm = ({
  open,
  setOpen,
  formDefine,
  handleSubmit,
  submitLoading,
  rowData,
}) => {
  const [inquiryValueData, setInquiryValueData] = useState(null);

  const handleChangeValue = (values) => {
    if (values) {
      const updatedData = { ...inquiryValueData, ...values };

      // Calculate new values
      let val1 = 0;

      if (updatedData?.qty) {
        val1 =
          Number(updatedData?.qty) *
          Number(rowData?.productAgaintCompetition?.price);
      } else {
        val1 = Number(rowData?.productAgaintCompetition?.price);
      }

      const updatedValues = {
        ...updatedData,
        value: val1,
      };

      // Update state and form field values
      setInquiryValueData(updatedValues);
      formDefine?.setFieldsValue({ value: val1 });
    }
  };

  const handleCancel = () => {
    setOpen(false);
    formDefine?.resetFields();
  };

  return (
    <>
      <Modal
        title="Add/Edit Inquiry"
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name={formDefine}
          layout="vertical"
          form={formDefine}
          onFinish={handleSubmit}
          onValuesChange={handleChangeValue}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="product"
                label="Product"
                rules={[{ required: true, message: "Product is required" }]}
              >
                <Input disabled={true} placeholder="Product" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="qty"
                label="Qty"
                rules={[{ required: true, message: "Qty is required" }]}
              >
                <Input
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  className="hide-arrows"
                  style={{ width: "100%" }}
                  placeholder="Qty"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="unit"
                label="Qty Unit"
                rules={[{ required: true, message: "Qty Unit is required" }]}
              >
                <Select
                  style={{
                    width: "100%",
                  }}
                  options={selectUnitQty}
                  placeholder="Select Unit"
                  allowClear
                  showSearch
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                name="price"
                label="Price"
                rules={[{ required: true, message: "Price is required" }]}
              >
                <Input
                  disabled={true}
                  style={{ width: "100%" }}
                  placeholder="Price"
                  className="hide-arrows"
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="value"
                label="Value"
                rules={[{ required: true, message: "Value is required" }]}
              >
                <Input
                  disabled={true}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  style={{ width: "100%" }}
                  placeholder="Value"
                  className="hide-arrows"
                />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button onClick={handleCancel} htmlType="reset" type="default">
              Cancel
            </Button>
            <Button loading={submitLoading} htmlType="submit" type="primary">
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddInquiryForm;
