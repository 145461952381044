import React, { useState, useEffect } from "react";
import { Spin, message } from "antd";
import { useParams } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";

import Invoice from "./Invoice";
import { getTransactionApi } from "../../redux/apis/dashboardApi";
import {
  selectSubcription,
  subcriptionActions,
} from "../../redux/slices/subcriptionSlice";

const initialState = {
  invoiceData: null,
  isLoading: false,
};

const MainInvoiceFile = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const subcriptionData = useSelector(selectSubcription);

  const [state, setState] = useState(initialState);

  const fetchTranslation = async () => {
    try {
      const id = params.id;

      setState({
        ...state,
        isLoading: true,
      });

      const res = await getTransactionApi(id);

      if (res?.data?.success) {
        setTimeout(() => {
          setState({
            ...state,
            invoiceData: res?.data?.data || null,
            isLoading: false,
          });
        }, 1000);
      } else {
        message.error(res?.data?.message);
        setState({
          ...state,
          isLoading: false,
        });
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
      });
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (params?.id) {
      fetchTranslation();
    }
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    dispatch(subcriptionActions.subcription());
    // eslint-disable-next-line
  }, []);

  if (state.isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Spin />
        <p>Loading....</p>
      </div>
    );
  }

  return (
    <>
      <PDFViewer width="1000" height="600" className="app">
        <Invoice
          subcriptionData={subcriptionData}
          state={state}
          setState={setState}
        />
      </PDFViewer>
    </>
  );
};

export default MainInvoiceFile;
