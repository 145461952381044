import React from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";

const AddRemarks = ({
  open,
  setOpen,
  formDefine,
  handleSubmit,
  submitLoading,
  rowData,
}) => {
  const rules = {
    remarks: [
      {
        required: true,
        message: "Please Enter Remarks",
      },
    ],
  };

  const handleCancel = () => {
    setOpen(false);
    formDefine?.resetFields();
  };

  return (
    <>
      <Modal
        title="Add/Edit Remarks"
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="d-flex flex-column justify-content-center">
          <div className="mt-4">
            <Form
              name={formDefine}
              layout="vertical"
              form={formDefine}
              onFinish={handleSubmit}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="remarks"
                    label="Remarks"
                    rules={rules.remarks}
                  >
                    <Input.TextArea
                      style={{ width: "100%" }}
                      placeholder="Remarks"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handleCancel} htmlType="reset" type="default">
                  Cancel
                </Button>
                <Button
                  loading={submitLoading}
                  htmlType="submit"
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddRemarks;
