import { Select, Button, message } from "antd";
import React, { useEffect, useState } from "react";

import { getDashboardApi } from "../../redux/apis/dashboardApi";
import PieChart from "./PieChart";

const { Option } = Select;

const intialFilterState = {
  customer: null,
  product: null,
};

const InitialAndCurrentMarketShare = ({
  loggedUser,
  selectionFilter,
  customers,
  products,
}) => {
  const [resultData, setResultData] = useState(null);
  const [filterStates, setFilterStates] = useState(intialFilterState);

  // dasboard api call
  const getDashboardData = async (data) => {
    try {
      const res = await getDashboardApi(data);

      if (res?.data?.success) {
        setResultData(res?.data?.data);
      } else {
        message.error(res?.data?.message || "Something went wrong");
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (loggedUser && selectionFilter) {
      let data = {
        userId: loggedUser?._id,
        funnelId: loggedUser?.activeFunnel?._id || null,
      };

      if (selectionFilter?.chartFilter) {
        data.chartFilter = selectionFilter?.chartFilter;
      }

      getDashboardData(data);
    }
    // eslint-disable-next-line
  }, [loggedUser, selectionFilter]);

  const handleCustomerFilter = (customer) => {
    setFilterStates({
      ...filterStates,
      customer: customer,
    });
  };

  const handleProductFilter = (product) => {
    setFilterStates({
      ...filterStates,
      product: product,
    });
  };

  const applyFilters = () => {
    let data = {
      userId: loggedUser?._id,
      funnelId: loggedUser?.activeFunnel?._id || null,
    };

    if (selectionFilter?.chartFilter) {
      data.chartFilter = selectionFilter?.chartFilter;
    }

    if (filterStates.customer) {
      data.selectedCustomer = filterStates.customer;
    }

    if (filterStates.product) {
      data.selectedProduct = filterStates.product;
    }

    getDashboardData(data);
  };

  const handleClearFilters = () => {
    setFilterStates(intialFilterState);

    let data = {
      userId: loggedUser?._id,
      funnelId: loggedUser?.activeFunnel?._id || null,
    };

    if (selectionFilter?.chartFilter) {
      data.chartFilter = selectionFilter?.chartFilter;
    }

    getDashboardData(data);
  };

  return (
    <>
      <span style={{ fontSize: "16px", fontWeight: 500 }}>Filters</span>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Select
            value={filterStates?.customer}
            onChange={handleCustomerFilter}
            placeholder="Select Customer"
            style={{ width: 200 }}
          >
            {customers?.map((customer) => (
              <Option key={customer?._id} value={customer?.name}>
                {customer?.name}
              </Option>
            ))}
          </Select>

          <Select
            value={filterStates?.product}
            onChange={handleProductFilter}
            placeholder="Select Product"
            style={{ width: 200 }}
          >
            {products?.map((product) => (
              <Option key={product?._id} value={product?.name}>
                {product?.name}
              </Option>
            ))}
          </Select>

          <Button type="primary" onClick={applyFilters}>
            Apply Filters
          </Button>

          <span
            style={{
              fontSize: "14px",
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => handleClearFilters()}
          >
            Clear filters
          </span>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60%",
          marginTop: "2rem",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: window.innerWidth > 768 ? "nowrap" : "wrap",
            marginBottom: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <PieChart
              colors={["#77C2FE", "#1578CF"]}
              resultData={resultData?.intialMarket}
            />
            <span>Initial Market Share</span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <PieChart
              colors={["#77C2FE", "#1578CF"]}
              resultData={resultData?.currentMarket}
            />
            <span>Current Market Share</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InitialAndCurrentMarketShare;
