import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ resultData }) => {
  const bartChartData = {
    series: [
      {
        name: "Plan",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 88, 12, 102],
      },
      {
        name: "Actual",
        data: [46, 50, 50, 60, 65, 60, 70, 67, 80, 88, 20, 105],
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -25,
        style: {
          fontSize: "12px",
          fontWeight: 400,
          colors: ["#000"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val?.toFixed(2);
          },
        },
      },
    },
  };

  const [chartOptions, setChartOptions] = useState({
    series: bartChartData.series,
    options: bartChartData.options,
    chartType: "bar",
    width: 700,
  });

  useEffect(() => {
    setChartOptions({
      options: {
        ...bartChartData.options,
        xaxis: {
          categories: resultData?.categories || [],
        },
      },
      series: resultData?.series || [],
      chartType: resultData?.chartType || "bar",
      width: window.innerWidth > 768 ? 700 : 400,
    });
    // eslint-disable-next-line
  }, [resultData]);

  return (
    <>
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        type={chartOptions.chartType}
        width={chartOptions.width}
      />
    </>
  );
};

export default BarChart;
