import { Modal, Form, Input, Select, Row, Col, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  addPaymentLicenseIdsApi,
  addSubUserAdminApi,
  editSubUserAdminApi,
  mainRolesListApi,
  updateProfile,
} from "../../redux/apis/dashboardApi";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSuperUser,
  superUserActions,
} from "../../redux/slices/superUserSlice";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import uniqueArrayList from "../../utils/UniqueArrayList";
import { roleActions } from "../../redux/slices/roleSlice";
import { authActions } from "../../redux/slices/authSlice";
import {
  selectSubcription,
  subcriptionActions,
} from "../../redux/slices/subcriptionSlice";
import { STATE_LIST } from "../../utils/contants/stateList";

const { Option } = Select;

const initialLicenseData = {
  id: null,
  data: null,
  paymentId: null,
};

const initialCityAndStateData = {
  cities: [],
  states: [],
  selectedState: null,
  selectedCity: null,
};

const AddEditRoleModel = ({ data, setData, loggedUser }) => {
  const [addEditRoleForm] = Form.useForm();

  const [roles, setRoles] = useState([]);
  const [superUsers, setSuperUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [licenseData, setLicenseData] = useState(initialLicenseData);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [listOfCityAndState, setListOfCityAndState] = useState(
    initialCityAndStateData
  );
  const [submitLoader, setSubmitLoader] = useState(false);

  const dispatch = useDispatch();

  const superUserData = useSelector(selectSuperUser);
  const subcriptionData = useSelector(selectSubcription);

  useEffect(() => {
    if (data?.type === "edit") {
      let reportingTo = [];

      if (data?.data?.reportingTo?.length > 0) {
        reportingTo = data?.data?.reportingTo?.map((v) => v?._id);
      }

      addEditRoleForm.setFieldsValue({
        firstName: data?.data?.firstName,
        lastName: data?.data?.lastName,
        designation: data?.data?.designation,
        email: data?.data?.email,
        mobile: data?.data?.mobile,
        role:
          data?.data?.role?.roleName === "superUser"
            ? "Super Admin"
            : data?.data?.role?.roleName === "subUser"
            ? "User"
            : data?.data?.role?.roleName === "userAdmin"
            ? "Manager"
            : data?.data?.role?.roleName,
        companyId: data?.data?.companyId,
        reportingTo,
        location: data?.data?.location,
        state: data?.data?.state,
      });

      let licenseIds =
        subcriptionData?.licenseIds?.length > 0
          ? [...subcriptionData?.licenseIds]
          : [];

      if (licenseIds.length > 0) {
        let getLId = licenseIds?.find(
          (lic) => lic?.user && lic.user?.email === data?.data?.email
        );

        setLicenseData({
          ...licenseData,
          id: getLId?.licId,
          paymentId: getLId?.paymentId,
        });
      } else {
        licenseIds = [];
      }
    }
    // eslint-disable-next-line
  }, [data?.type, subcriptionData]);

  const rolesListFetch = async () => {
    try {
      const res = await mainRolesListApi();
      if (res?.data?.success) {
        let allRole = res?.data?.data;

        if (allRole?.length > 0) {
          let userAdminRoles = allRole?.filter(
            (v) => v?.roleName === "userAdmin" || v?.roleName === "subUser"
          );
          setRoles(userAdminRoles);
        }
      } else {
        message.error(res?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (data?.model) {
      rolesListFetch();
      dispatch(
        superUserActions.superUserList({
          limit: 9999,
          page: currentPage,
          managementSuperUser: loggedUser?._id,
        })
      );
      dispatch(subcriptionActions.licenseIds({ userId: loggedUser?._id }));
    }
    // eslint-disable-next-line
  }, [data?.model]);

  useEffect(() => {
    if (superUserData) {
      // const filteredSuperUsers = superUserData?.list?.filter((user) => {
      //   // Check if loggedUser._id matches the createdBy._id or the user's own _id
      //   const isCreatedByLoggedUser = user?.createdBy?._id === loggedUser?._id;
      //   const isLoggedUser = user?._id === loggedUser?._id;
      //   return isCreatedByLoggedUser || isLoggedUser;
      // });

      // const uniqueSuperUsers = uniqueArrayList(filteredSuperUsers, "_id");

      // if (uniqueSuperUsers?.length) {
      //   setSuperUsers(uniqueSuperUsers);
      // }

      // let superUsersList = superUserData?.list?.filter(
      //   (v) => v?.role?.roleName === "superUser"
      // );

      let newArr = [...superUserData?.list];
      let finalArr = uniqueArrayList(newArr, "_id");
      if (finalArr?.length) {
        setSuperUsers(finalArr);
      }
    }
    // eslint-disable-next-line
  }, [superUserData]);

  useEffect(() => {
    if (STATE_LIST.length) {
      let states = STATE_LIST.map((val) => {
        return {
          label: val?.state,
          value: val?.state,
        };
      });

      let newUniqueList = uniqueArrayList(states, "value");

      newUniqueList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      );

      setListOfCityAndState({
        ...listOfCityAndState,
        states: newUniqueList,
        selectedState: null,
        cities: [],
      });
    }
    // eslint-disable-next-line
  }, [STATE_LIST]);

  useEffect(() => {
    if (data?.data && loggedUser) {
      let checkedSuperAdmin = data?.data?._id === loggedUser?._id;

      setIsSuperAdmin(checkedSuperAdmin);
    } else {
      setIsSuperAdmin(false);
    }
  }, [data?.data, loggedUser]);

  const superUserInData = (val) => {
    if (val) {
      dispatch(superUserActions?.superUserSearch({ searchText: val }));
    }
  };

  // eslint-disable-next-line
  const debounceSuperUser = useCallback(
    helperMethods.debounce(superUserInData, 500),
    []
  );

  const handleCancel = () => {
    addEditRoleForm.resetFields();
    setData({ data: null, model: false });
  };

  const fetchLicenseId = () => {
    let licenseIds =
      subcriptionData?.licenseIds?.length > 0
        ? [...subcriptionData?.licenseIds]
        : [];

    if (licenseIds.length > 0) {
      let getLId = licenseIds?.find(
        (lic) =>
          !lic.user ||
          (typeof lic.user === "object" && Object.keys(lic.user).length === 0)
      );

      setLicenseData({
        ...licenseData,
        id: getLId?.licId,
        paymentId: getLId?.paymentId,
      });
    } else {
      licenseIds = [];
    }
  };

  useEffect(() => {
    if (subcriptionData && data?.type !== "edit") {
      fetchLicenseId();
    }
    // eslint-disable-next-line
  }, [subcriptionData]);

  const onFinish = async (values) => {
    try {
      if (values) {
        let res = null;

        setSubmitLoader(true);
        if (data?.type === "edit") {
          if (isSuperAdmin) {
            res = await updateProfile(loggedUser?._id, {
              ...values,
              role: data?.data?.role[0]?._id,
            });
          } else {
            res = await editSubUserAdminApi(data?.data?._id, {
              ...values,
              createdBy: loggedUser?._id,
              role: data?.data?.role[0]?._id,
            });
          }
        } else {
          const submitData = {
            ...values,
            createdBy: loggedUser?._id,
            licenseData: licenseData,
          };

          res = await addSubUserAdminApi(submitData);
        }

        if (res?.data?.success) {
          setSubmitLoader(false);
          message.success(res?.data?.message);
          setCurrentPage(1);
          addEditRoleForm.resetFields();
          dispatch(authActions.isLogin());
          dispatch(
            roleActions.roleList({
              limit: 10,
              page: 1,
              createdBy: loggedUser?._id,
            })
          );

          if (!isSuperAdmin) {
            setLicenseData({
              ...licenseData,
              data: res?.data?.data,
            });
            addPaymentLicenseIds({
              ...values,
              createdBy: loggedUser?._id,
              role: data?.data?.role[0]?._id,
            });
          } else {
            setData({ data: null, model: false });
          }
        } else {
          setSubmitLoader(false);
          message.error(res?.data?.message);
        }
      }
    } catch (error) {
      setSubmitLoader(false);
      message.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };

  const addPaymentLicenseIds = async (user) => {
    try {
      if (user) {
        const payload = {
          userId: loggedUser?._id || "",
          paymentId: licenseData?.paymentId || "",
          licId: licenseData?.id || "",
          user,
        };

        const res = await addPaymentLicenseIdsApi(payload);

        if (res?.data?.success) {
          setData({ data: null, model: false });

          setLicenseData(initialLicenseData);
        } else {
          message.error(res?.data?.message ?? "Something went wrong");
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };

  const handleChangeState = (val) => {
    let cities = STATE_LIST.filter((state) => state.state === val).map((v) => {
      return {
        label: v?.name,
        value: v?.name,
      };
    });

    let newUniqueList = uniqueArrayList(cities, "value");

    newUniqueList.sort((a, b) =>
      a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
    );

    if (newUniqueList.length) {
      setListOfCityAndState((pre) => ({
        ...pre,
        cities: newUniqueList,
      }));
    }

    setListOfCityAndState((pre) => ({
      ...pre,
      selectedState: val,
      selectedCity: null,
      cities: newUniqueList?.length ? newUniqueList : [],
    }));

    addEditRoleForm.setFieldValue("location", null);
  };

  const handleChangeCity = (val) => {
    setListOfCityAndState((pre) => ({
      ...pre,
      selectedCity: val,
    }));
  };

  return (
    <>
      <Modal
        title={data?.type === "add" ? "Add Admin/User" : "Edit Admin/User"}
        open={data?.model}
        onCancel={() => handleCancel()}
        okText="Submit"
        onOk={() => addEditRoleForm.submit()}
        okButtonProps={{
          loading: submitLoader,
        }}
      >
        <Form
          layout="vertical"
          form={addEditRoleForm}
          onFinish={onFinish}
          initialValues={{ companyId: "" }}
        >
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: "Please enter first name" }]}
              >
                <Input placeholder="First Name" disabled={isSuperAdmin} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "Please enter last name" }]}
              >
                <Input placeholder="Last Name" disabled={isSuperAdmin} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="designation"
                label="Designation"
                rules={[
                  { required: true, message: "Please enter a designation" },
                ]}
              >
                <Input placeholder="Designation" disabled={isSuperAdmin} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please enter an email" },
                  { type: "email", message: "Invalid email format" },
                ]}
              >
                <Input placeholder="Email" disabled={isSuperAdmin} />
              </Form.Item>
            </Col>

            {!isSuperAdmin && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: data?.type === "edit" ? false : true,
                      message: "Please enter password",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "Please Enter State",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select State"
                  onChange={handleChangeState}
                  showSearch
                  allowClear
                  disabled={isSuperAdmin}
                >
                  {listOfCityAndState.states?.length &&
                    listOfCityAndState.states?.map((state) => (
                      <Option key={state?.value} value={state.value}>
                        {state.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="location"
                label="Location / City"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Location / City",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select location / city"
                  showSearch
                  allowClear
                  disabled={
                    (isSuperAdmin && data?.type === "edit") ||
                    listOfCityAndState.cities?.length === 0 ||
                    [null, undefined, ""].includes(
                      listOfCityAndState.selectedState
                    )
                      ? true
                      : false
                  }
                  value={listOfCityAndState.selectedCity}
                  onChange={handleChangeCity}
                >
                  {listOfCityAndState.cities?.length &&
                    listOfCityAndState.cities?.map((city) => (
                      <Option key={city?.value} value={city.value}>
                        {city.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="companyId"
                label="Company ID"
                rules={[
                  { required: true, message: "Please enter a company ID" },
                ]}
              >
                <Input placeholder="Company Id" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                  { required: true, message: "Please enter a mobile number" },
                  {
                    pattern: /^\d{10}$/,
                    message: "Invalid mobile number format",
                  },
                ]}
              >
                <Input placeholder="Mobile" disabled={isSuperAdmin} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="reportingTo"
                label="Reporting To"
                rules={[
                  {
                    required: !isSuperAdmin,
                    message: "Please select reporting user(s)",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Reporting"
                  onChange={(e) => debounceSuperUser(e)}
                  style={{ width: "100%" }}
                  // value={defaultSelectedProduct}
                  allowClear
                  onPopupScroll={(e) => {
                    const target = e.target;
                    if (
                      target.scrollTop + target.clientHeight ===
                      target.scrollHeight
                    ) {
                      if (superUserData?.isNext) {
                        dispatch(
                          superUserActions.superUserList({
                            limit: 100,
                            page: currentPage + 1,
                            managementSuperUser: loggedUser?._id,
                          })
                        );
                        setCurrentPage(currentPage + 1);
                      }
                    }
                  }}
                  onClear={() => debounceSuperUser("")}
                  showSearch
                  disabled={isSuperAdmin}
                >
                  {superUsers.map((user) => (
                    <Option key={user?._id} value={user?._id}>
                      {user?.firstName} {user?.lastName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="role"
                label="Select Role"
                rules={[
                  {
                    required: !isSuperAdmin,
                    message: "Please select role",
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  disabled={data?.type === "edit" ? true : false}
                >
                  {roles?.map((role) => (
                    <Option
                      disabled={
                        role?.roleName === "userAdmin" &&
                        loggedUser?.accountType === "demo"
                      }
                      key={role?._id}
                      value={role?._id}
                    >
                      {role?.roleName === "subUser"
                        ? "User"
                        : role?.roleName === "userAdmin"
                        ? "Manager"
                        : role?.roleName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div>
          <span style={{ color: "red" }}>License ID:- </span>
          <span style={{ fontWeight: 600 }}>{licenseData?.id || ""}</span>
        </div>
      </Modal>
    </>
  );
};

export default AddEditRoleModel;
