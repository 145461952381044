import { Button, Card, Divider, Empty, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { useSelector } from "react-redux";
import { Tree, TreeNode } from "react-organizational-chart";
import { getOrgChartApi } from "../../redux/apis/dashboardApi";
import ViewEmployeeDetailsModel from "./ViewEmployeeDetailsModel";
import { PlusCircleOutlined } from "@ant-design/icons";
import { PATH_DASHBOARD } from "../../routes/path";

const ViewChart = () => {
  const navigate = useNavigate();
  const params = useParams();

  const loggedUser = useSelector(selectLoggedUser);

  const [loadingChart, setLoadingChart] = useState(false);
  const [orgChartData, setOrgChartData] = useState(null);
  const [arrangeChartData, setArrangeChartData] = useState([]);
  const [employeeData, setEmployeeData] = useState({
    model: false,
    data: null,
  });

  const getChartData = async () => {
    try {
      setLoadingChart(true);
      const data = {
        funnelId: loggedUser?.activeFunnel?._id,
        userId: loggedUser?._id,
        customerId: params?.customerId,
      };
      const res = await getOrgChartApi(data);

      if (res?.data?.success) {
        setLoadingChart(false);
        setOrgChartData(res?.data?.data);
      } else {
        setOrgChartData(null);
        setLoadingChart(false);
      }
    } catch (error) {
      setOrgChartData(null);
      setLoadingChart(false);
      message.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };

  useEffect(() => {
    if (loggedUser) {
      getChartData();
    }
    // eslint-disable-next-line
  }, [loggedUser]);

  useEffect(() => {
    if (orgChartData) {
      const transformedHierarchy = createEmployeeHierarchy(orgChartData, null);

      if (transformedHierarchy?.length) {
        let setTree = transformedHierarchy?.filter(
          (v) => v?.children?.length > 0
        );
        setArrangeChartData(setTree);
      }
    }
    // eslint-disable-next-line
  }, [orgChartData]);

  function createEmployeeHierarchy(apiData, manager) {
    const hierarchy = [];

    apiData.forEach((item) => {
      if (item.reporting === manager) {
        const children = createEmployeeHierarchy(apiData, item.employeeName);
        const employeeNode = {
          name: item?.employeeName,
          designation: item?.designation,
          department: item?.department,
        };

        if (children.length > 0) {
          employeeNode.children = children;
        }

        hierarchy.push(employeeNode);
      }
    });

    return hierarchy;
  }

  const StyledNode = ({ children }) => {
    return children ? (
      <div
        style={{
          padding: "5px",
          borderRadius: "8px",
          display: "inline-block",
          border: "1px solid #3e9c03",
          width: "130px",
          textAlign: "center",
        }}
      >
        {children}
      </div>
    ) : (
      <></>
    );
  };

  const renderTree = (data) => {
    const renderTreeNodeRecursive = (node) => (
      <TreeNode
        key={node.name}
        label={
          <StyledNode>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span>{node?.name}</span>{" "}
              <span style={{ fontSize: "10px", fontWeight: 700 }}>
                ({node?.designation})
              </span>
            </div>
          </StyledNode>
        }
      >
        {node.children?.map(renderTreeNodeRecursive)}
      </TreeNode>
    );

    return (
      <Tree
        lineWidth={"2px"}
        lineColor={"green"}
        lineBorderRadius={"10px"}
        label={
          <StyledNode>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span>{data?.name}</span>{" "}
              <span style={{ fontSize: "10px", fontWeight: 700 }}>
                ({data?.designation})
              </span>
            </div>
          </StyledNode>
        }
      >
        {data?.children?.map(renderTreeNodeRecursive)}
      </Tree>
    );
  };

  const handleViewEmployee = (row) => {
    setEmployeeData({ data: row, model: true });
  };

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Card
          title="Organization Chart"
          extra={
            <div style={{ textAlign: "end" }}>
              <Button
                onClick={() => {
                  window.localStorage.setItem("customerId", params?.customerId);
                  navigate(-1);
                }}
              >
                Back
              </Button>
            </div>
          }
        >
          {loadingChart ? (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spin />
              </div>
            </>
          ) : orgChartData && orgChartData?.length > 1 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <span>Employee Name</span>
                <span style={{ fontWeight: 700 }}>
                  Total: {orgChartData?.length}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  overflowX: "auto",
                  maxWidth: "100%",
                }}
              >
                {orgChartData?.map((v) => (
                  <>
                    <StyledNode key={v?._id}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleViewEmployee(v)}
                      >
                        <span>{v?.employeeName}</span>{" "}
                        <span style={{ fontSize: "10px", fontWeight: 700 }}>
                          ({v?.designation})
                        </span>
                      </div>
                    </StyledNode>
                  </>
                ))}
              </div>
              <Divider>Chart</Divider>
              <div>{arrangeChartData?.map((item) => renderTree(item))}</div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Empty description="" />
                <Button
                  onClick={() => navigate(PATH_DASHBOARD.organizationChart)}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add Employee For Organization Chart
                </Button>
              </div>
            </>
          )}
        </Card>
      </div>

      <ViewEmployeeDetailsModel data={employeeData} setData={setEmployeeData} />
    </>
  );
};

export default ViewChart;
