import { Select, Button, message, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import PieChart from "./PieChart";
import { getDashboardApi } from "../../redux/apis/dashboardApi";

const { Option } = Select;

const intialFilterState = {
  customerList: [],
  productList: [],
  filterYear: null,
};

const ConversionRatios = ({
  loggedUser,
  selectionFilter,
  customers,
  products,
}) => {
  const [resultData, setResultData] = useState(null);
  const [filterStates, setFilterStates] = useState(intialFilterState);

  // dasboard api call
  const getDashboardData = async (data) => {
    try {
      const res = await getDashboardApi(data);

      if (res?.data?.success) {
        setResultData(res?.data?.data);
      } else {
        message.error(res?.data?.message || "Something went wrong");
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (loggedUser && selectionFilter) {
      let data = {
        userId: loggedUser?._id,
        funnelId: loggedUser?.activeFunnel?._id || null,
      };

      if (selectionFilter?.chartFilter) {
        data.chartFilter = selectionFilter?.chartFilter;
      }

      getDashboardData(data);
    }
    // eslint-disable-next-line
  }, [loggedUser, selectionFilter]);

  const handleCustomerFilter = (customer) => {
    setFilterStates({
      ...filterStates,
      customerList: customer,
    });
  };

  const handleProductFilter = (product) => {
    setFilterStates({
      ...filterStates,
      productList: product,
    });
  };

  const handleChangeYear = (date) => {
    setFilterStates({
      ...filterStates,
      filterYear: date,
    });
  };

  const applyFilters = () => {
    let data = {
      userId: loggedUser?._id,
      funnelId: loggedUser?.activeFunnel?._id || null,
    };

    if (selectionFilter?.chartFilter) {
      data.chartFilter = selectionFilter?.chartFilter;
    }

    if (filterStates.filterYear) {
      data.filterYear = dayjs(filterStates.filterYear).year();
    }

    if (filterStates.customerList?.length > 0) {
      data.customerList = filterStates.customerList;
    }

    if (filterStates.productList?.length > 0) {
      data.productList = filterStates.productList;
    }

    getDashboardData(data);
  };

  const handleClearFilters = () => {
    setFilterStates(intialFilterState);

    let data = {
      userId: loggedUser?._id,
      funnelId: loggedUser?.activeFunnel?._id || null,
    };

    if (selectionFilter?.chartFilter) {
      data.chartFilter = selectionFilter?.chartFilter;
    }

    getDashboardData(data);
  };

  return (
    <>
      <span style={{ fontSize: "16px", fontWeight: 500 }}>Filters</span>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <DatePicker
            picker="year"
            value={filterStates?.filterYear}
            onChange={handleChangeYear}
          />

          <Select
            showSearch
            allowClear
            mode="multiple"
            value={filterStates?.customerList}
            onChange={handleCustomerFilter}
            placeholder="Select Customer"
            style={{ width: 200 }}
          >
            {customers?.map((customer) => (
              <Option key={customer?._id} value={customer?.name}>
                {customer?.name}
              </Option>
            ))}
          </Select>

          <Select
            showSearch
            allowClear
            mode="multiple"
            value={filterStates?.productList}
            onChange={handleProductFilter}
            placeholder="Select Product"
            style={{ width: 200 }}
          >
            {products?.map((product) => (
              <Option key={product?._id} value={product?.name}>
                {product?.name}
              </Option>
            ))}
          </Select>

          <Button type="primary" onClick={applyFilters}>
            Apply Filters
          </Button>

          <span
            style={{
              fontSize: "14px",
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => handleClearFilters()}
          >
            Clear filters
          </span>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "65vh",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <PieChart
              type="conversionRatio"
              colors={["#4A5596", "#97A1D9"]}
              resultData={resultData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConversionRatios;
