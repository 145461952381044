import { Tabs } from "antd";
import React from "react";
import AllProducts from "../../sections/ProductPricingManage/AllProducts";
import ByCategory from "../../sections/ProductPricingManage/ByCategory";
import ByProductWise from "../../sections/ProductPricingManage/ByProductWise";

const ProductPricingManage = ({ loggedUser }) => {
  const ProductOptions = [
    {
      key: 1,
      label: "All products",
      children: <AllProducts loggedUser={loggedUser} />,
    },
    {
      key: 2,
      label: "By Category",
      children: <ByCategory loggedUser={loggedUser} />,
    },
    {
      key: 3,
      label: "By Product Wise",
      children: <ByProductWise loggedUser={loggedUser} />
    },
  ];

  return (
    <>
      <h3 style={{ textAlign: "center" }}>Directly change pricing</h3>
      <Tabs
        defaultActiveKey={"1"}
        items={ProductOptions}
      />
    </>
  );
};

export default ProductPricingManage;
