import React from "react";
import { useNavigate } from "react-router";
import { PATH_AUTH } from "../../routes/path";

const { termsAndConditions, privacyPolicy, aboutUs, contactUs } = PATH_AUTH;

const Footer = () => {
  const navigate = useNavigate();

  const handleRedirect = (type) => {
    if (type === "privacy") {
      navigate(privacyPolicy);
    }

    if (type === "terms") {
      navigate(termsAndConditions);
    }

    if (type === "about") {
      navigate(aboutUs);
    }

    if (type === "contact") {
      navigate(contactUs);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "70px",
          background: "#bdbdbb",
          justifyContent: "space-between",
        }}
      >
        <div>
          <ul
            style={{
              display: "flex",
              listStyleType: "none",
              justifyContent: "space-around",
              gap: "50px",
            }}
          >
            <li
              style={{ cursor: "pointer" }}
              onClick={() => handleRedirect("about")}
            >
              About Us
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => handleRedirect("contact")}
            >
              Contact Us
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => handleRedirect("privacy")}
            >
              Privacy Policy
            </li>
            <li
              style={{ cursor: "pointer" }}
              onClick={() => handleRedirect("terms")}
            >
              Terms & Conditions
            </li>
          </ul>
        </div>
        <div
          style={{
            float: "right",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "10rem",
          }}
        >
          <p>All rights reserved</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
