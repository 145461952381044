import { Button, Col, Form, Input, Row, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerApi } from "../../redux/apis/auth";
import { PATH_AUTH } from "../../routes/path";
import "./RegisterCss.css";

const RegisterForm = () => {
  const [regForm] = Form.useForm();
  const navigate = useNavigate();

  const [isSubmit, setSubmit] = useState(false);

  const rules = {
    firstName: [{ required: true, message: "Please Enter First Name" }],
    lastName: [{ required: true, message: "Please Enter Last Name" }],
    companyName: [{ required: true, message: "Please Enter Company Name" }],
    industry: [{ required: true, message: "Please Enter Industry" }],
    // department: [{ required: true, message: "Please Enter Department" }],
    designation: [{ required: true, message: "Please Enter Designation" }],
    email: [
      { required: true, message: "Please Enter Email" },
      {
        type: "email",
        message: "Please Enter A Valid Email",
      },
    ],
    mobile: [
      {
        required: true,
        message: "Please Enter Mobile Number",
      },
      {
        max: 10,
        message: "Please Enter Valid Phone Number",
      },
    ],
    password: [
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{6,}$/.test(value)) {
            return Promise.resolve();
          } else if (value.length < 6) {
            return Promise.reject(
              "Password should be at least 6 characters long"
            );
          } else if (!/(?=.*[A-Z])/.test(value)) {
            return Promise.reject(
              "Password should contain at least one uppercase letter"
            );
          } else if (!/(?=.*[!@#$%^&*])/.test(value)) {
            return Promise.reject(
              "Password should contain at least one special character"
            );
          } else if (!/(?=.*[0-9])/.test(value)) {
            return Promise.reject("Password should contain at least one digit");
          }
        },
      }),
    ],
    confirmPassword: [
      {
        required: true,
        message: "Please Enter Confirm Password",
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject("Password and Confirm Password must be same");
        },
      }),
    ],
  };

  const onFinish = async (values) => {
    try {
      setSubmit(true);
      const res = await registerApi({ ...values, accountType: "demo" });
      if (res?.data?.success) {
        setSubmit(false);
        message.success(res?.data?.message);

        setTimeout(() => {
          navigate(PATH_AUTH.root);
        });
      } else {
        setSubmit(false);
        message.error(res?.data?.message);
      }
    } catch (error) {
      setSubmit(false);
      console.log(error);
      message.error(error?.response.data?.message);
    }
  };

  return (
    <>
      <Link to={PATH_AUTH.root} style={{ textDecoration: "none" }}>
        <div style={{ cursor: "pointer", marginTop: "10px" }}>
          <ArrowLeftOutlined style={{ marginRight: 10 }} />
          Login
        </div>
      </Link>
      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <p
            style={{ fontSize: "22px", fontWeight: 500, margin: 0, padding: 0 }}
          >
            Sign Up
          </p>
        </div>
        <div style={{ padding: 10, justifyContent: "center", display: "flex" }}>
          <Form
            form={regForm}
            name="regForm"
            layout="vertical"
            onFinish={onFinish}
          >
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={rules.firstName}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={rules.lastName}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Company Name"
                  name="companyName"
                  rules={rules.companyName}
                >
                  <Input placeholder="Company Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Industry"
                  name="industry"
                  rules={rules.industry}
                >
                  <Input placeholder="Industry" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  label="Department/Division"
                  name="department"
                  rules={rules.department}
                >
                  <Input placeholder="Department/Division" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Designation"
                  name="designation"
                  rules={rules.designation}
                >
                  <Input placeholder="Designation" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email Id" name="email" rules={rules.email}>
                  <Input placeholder="Email Id" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Mobile Number"
                  rules={rules.mobile}
                  name="mobile"
                >
                  <Input
                    placeholder="Mobile Number"
                    className="hide-arrows"
                    style={{ width: "100%" }}
                    stringMode={true}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={rules.password}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Confim Password"
                  name="confirmPassword"
                  rules={rules.confirmPassword}
                >
                  <Input.Password placeholder="Confirm Password" />
                </Form.Item>
              </Col>
            </Row>
            <Button type="primary" loading={isSubmit} htmlType="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
