import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../redux/slices/authSlice";

const ListOfProgressReportStages = () => {
  const loggedUser = useSelector(selectLoggedUser);

  const [listProgress, setListProgress] = useState([]);

  useEffect(() => {
    if (loggedUser?.stages?.length > 0) {
      setListProgress(loggedUser?.stages);
    } else {
      setListProgress([]);
    }
  }, [loggedUser]);

  return (
    <>
      <div>
        <div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {listProgress?.length > 0 ? (
              listProgress?.map((v, index) => (
                <>
                  <div
                    style={{
                      width: "90px",
                      textAlign: "center",
                      height: "65px",
                    }}
                  >
                    <Tooltip title={v?.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "5px",
                          fontSize: "12px",
                          borderRadius: "5px",
                          background: `${v?.bg}`,
                          color: `#000000`,
                          fontWeight: 600,
                          letterSpacing: "0.5px",
                          position: "relative",
                          height: "100%",
                        }}
                      >
                        <span>{index + 1}</span>
                        <span style={{ wordBreak: "break-word" }}>
                          {(v?.name).length > 18
                            ? (v?.name).slice(0, 18) + "..."
                            : v?.name}
                        </span>
                        {index !== listProgress.length - 1 && (
                          <div
                            className="connection-line"
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "-24px",
                              width: "24px",
                              height: "2px",
                              background: "#ccc",
                            }}
                          />
                        )}
                      </div>
                    </Tooltip>
                  </div>
                </>
              ))
            ) : (
              <>No Stages</>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListOfProgressReportStages;
