/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Card, Col, Form, Row, Spin, message } from "antd";
import { PATH_DASHBOARD } from "../../routes/path";
import { editProductApi, getProductApi } from "../../redux/apis/dashboardApi";
import AddEditProduct from "../../sections/ProductManage/AddEditProduct";

const EditProduct = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const params = useParams();
  const [viewData, setViewData] = useState(null);
  const [isViewData, setIsViewData] = useState(null);

  const [addEditProductForm] = Form.useForm();

  useEffect(() => {
    if (location) {
      if (location.pathname.includes("edit")) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
      }
    }
  }, [location]);

  const handleCancle = () => {
    navigate(PATH_DASHBOARD.product);
  };

  useEffect(() => {
    if (isEdit && params) {
      (async () => {
        try {
          setIsViewData(true);
          const viewRes = await getProductApi(params?.id);
          if (viewRes?.data?.success) {
            setIsViewData(false);
            setViewData(viewRes?.data?.data);
          } else {
            setIsViewData(false);
            setViewData(null);
          }
        } catch (error) {
          console.log("error ---->", error);
          setIsViewData(false);
          setViewData(null);
        }
      })();
    }
  }, [params, isEdit]);

  useEffect(() => {
    if (viewData) {
      addEditProductForm.setFieldsValue({
        name: viewData?.name,
        category: viewData?.category,
        productDetails: viewData?.productDetails,
        price: viewData?.price,
      });
    }
  }, [viewData, isEdit]);

  const onFinish = async (values) => {
    try {
      setIsSubmit(true);

      const data = {
        ...values,
        lastPrice: viewData?.price,
      };

      const editRes = await editProductApi(params?.id, data);

      if (editRes.data.success) {
        message.success(editRes.data.message);
        setIsSubmit(false);
        setTimeout(() => {
          navigate(PATH_DASHBOARD.product);
        }, 1000);
      } else {
        setIsSubmit(false);
        message.error(editRes.data.message);
      }
    } catch (error) {
      setIsSubmit(false);
      message.error(error?.response.data?.message);
    }
  };

  if (isViewData && isEdit) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spin />
        </div>
      </>
    );
  }

  return (
    <>
      <Card title="Edit Product">
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="mt-4">
            <Form
              name="addEditProductForm"
              layout="vertical"
              form={addEditProductForm}
              onFinish={onFinish}
            >
              <AddEditProduct
                isEdit={isEdit}
                isSubmit={isSubmit}
                handleCancle={handleCancle}
              />
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};

export default EditProduct;
