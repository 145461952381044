export const PlusIcon = () => {
  return (
    <svg
      height="62px"
      id="Layer_1"
      style={{ enableBackground: "new 0 0 42 42" }}
      version="1.1"
      viewBox="0 0 42 42"
      width="62px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="#494949"
        d="M28,14H18V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2
                s2-0.896,2-2V18h10c1.104,0,2-0.896,2-2S29.104,14,28,14z"
      />
    </svg>
  );
};

export const RightHandIcon = () => {
  return (
    <svg
      height="32px"
      viewBox="0 0 24 24"
      width="32px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#ffffff"
        d="M7 21V18M14 21V17.6599C14 16.6974 14.6855 15.8715 15.6314 15.6941L21.1085 14.6672C21.6254 14.5702 22 14.1189 22 13.5929C22 12.9893 21.5107 12.5 20.9071 12.5H9.5C6.18629 12.5 3.5 15.1863 3.5 18.5V21M21 8L19 6M21 8L19 10.2592M21 8H16.5M14 6.5C14 8.70914 12.2091 10.5 10 10.5C7.79086 10.5 6 8.70914 6 6.5C6 4.29086 7.79086 2.5 10 2.5C12.2091 2.5 14 4.29086 14 6.5Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </svg>
  );
};
