import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { STATE_LIST } from "../../utils/contants/stateList";
import uniqueArrayList from "../../utils/UniqueArrayList";

const rules = {
  name: [
    {
      required: true,
      message: "Please Enter Name",
    },
  ],
  // industry: [
  //   {
  //     required: true,
  //     message: "Please Enter Industry",
  //   },
  // ],
  // subIndustry: [
  //   {
  //     required: true,
  //     message: "Please Enter Sub Industry",
  //   },
  // ],
  // gstNo: [
  //   {
  //     required: true,
  //     message: "Please Enter GST Number",
  //   },
  // ],
  // officeAddress: [
  //   {
  //     required: true,
  //     message: "Please Enter Registered Office Address",
  //   },
  // ],
  // totalNoOfPlant: [
  //   {
  //     required: true,
  //     message: "Please Enter Total No Of Plants",
  //   },
  // ],
  // cusPlantAddress: [
  //   {
  //     required: true,
  //     message: "Please Enter Customer Plant Address",
  //   },
  // ],
  // plantOneAddress: [
  //   {
  //     required: true,
  //     message: "Please Enter Plant 1 Address",
  //   },
  // ],
  // plantTwoAddress: [
  //   {
  //     required: true,
  //     message: "Please Enter Plant 2 Address",
  //   },
  // ],
  // custSiteAddress: [
  //   {
  //     required: true,
  //     message: "Please Enter Site Address",
  //   },
  // ],
  location: [
    {
      required: true,
      message: "Please Enter Location / City",
    },
  ],
  // paymentTerms: [
  //   {
  //     required: true,
  //     message: "Please Enter Payment Terms",
  //   },
  // ],
  state: [
    {
      required: true,
      message: "Please Enter State",
    },
  ],
  // website: [
  //   {
  //     required: true,
  //     message: "Please Enter State",
  //   },
  // ],
};

const { Option } = Select;

const AddEditCustomer = ({ isEdit, isSubmit, handleCancle, viewData = {} }) => {
  const [listOfCityAndState, setListOfCityAndState] = useState({
    cities: [],
    states: [],
    selectedState: null,
  });

  useEffect(() => {
    if (STATE_LIST.length) {
      let states = STATE_LIST.map((val) => {
        return {
          label: val?.state,
          value: val?.state,
        };
      });

      let newUniqueList = uniqueArrayList(states, "value");

      newUniqueList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      );

      setListOfCityAndState({ ...listOfCityAndState, states: newUniqueList });
    }
    // eslint-disable-next-line
  }, [STATE_LIST]);

  const handleChangeState = (val) => {
    let cities = STATE_LIST.filter((state) => state.state === val).map((v) => {
      return {
        label: v?.name,
        value: v?.name,
      };
    });

    let newUniqueList = uniqueArrayList(cities, "value");

    newUniqueList.sort((a, b) =>
      a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
    );

    if (cities.length) {
      setListOfCityAndState({
        ...listOfCityAndState,
        cities: newUniqueList,
        selectedState: val,
      });
    }
  };

  useEffect(() => {
    if (viewData && Object.keys(viewData).length > 0) {
      let states = STATE_LIST.map((val) => {
        return {
          label: val?.state,
          value: val?.state,
        };
      });

      let newUniqueListState = uniqueArrayList(states, "value");

      newUniqueListState.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      );

      let cities = STATE_LIST.filter(
        (state) => state.state === viewData?.state
      ).map((v) => {
        return {
          label: v?.name,
          value: v?.name,
        };
      });

      let newUniqueList = uniqueArrayList(cities, "value");

      newUniqueList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      );

      if (cities.length) {
        setListOfCityAndState({
          cities: newUniqueList,
          selectedState: viewData?.location,
          states: newUniqueListState,
        });
      }
    }
    // eslint-disable-next-line
  }, [viewData]);

  return (
    <>
      <div>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="name" label="Customer Name" rules={rules.name}>
              <Input placeholder="Customer Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="industry"
              label="Industry"
              // rules={rules.industry}
            >
              <Input placeholder="Industry" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="gstNo"
              label="GST Number"
              // rules={rules.gstNo}
            >
              <Input placeholder="GST Number" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="subIndustry"
              label="Sub Industry"
              // rules={rules.subIndustry}
            >
              <Input placeholder="Sub Industry" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="officeAddress"
              label="Registered Office Address"
              // rules={rules.officeAddress}
            >
              <Input placeholder="Registered Office Address" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="totalNoOfPlant"
              label="Total Nos. Of Plants"
              // rules={rules.totalNoOfPlant}
            >
              <Input placeholder="Total Nos. Of Plants" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="cusPlantAddress"
              label="Customer Plant Address"
              // rules={rules.cusPlantAddress}
            >
              <Input placeholder="Customer Plant Address" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="plantOneAddress"
              label="Plant 1 Address"
              // rules={rules.plantOneAddress}
            >
              <Input placeholder="Plant 1 Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="plantTwoAddress"
              label="Plant 2 Address"
              // rules={rules.plantTwoAddress}
            >
              <Input placeholder="Plant 2 Address" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="custSiteAddress"
              label="Site Address"
              // rules={rules.custSiteAddress}
            >
              <Input placeholder="Site Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="state" label="State" rules={rules.state}>
              <Select
                style={{ width: "100%" }}
                placeholder="Select State"
                onChange={handleChangeState}
                showSearch
              >
                {listOfCityAndState.states?.length &&
                  listOfCityAndState.states?.map((state) => (
                    <Option key={state?.value} value={state.value}>
                      {state.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="paymentTerms"
              label="Payment Terms (In Days)"
              // rules={rules.custSiteAddress}
            >
              <Input
                className="hide-arrows"
                style={{ width: "100%" }}
                placeholder="Payment Terms"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="location"
              label="Location / City"
              rules={rules.location}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Select location / city"
                showSearch
                disabled={
                  !isEdit &&
                  (listOfCityAndState.cities?.length === 0 ||
                    listOfCityAndState.selectedState === null)
                    ? true
                    : false
                }
              >
                {listOfCityAndState.cities?.length &&
                  listOfCityAndState.cities?.map((city) => (
                    <Option key={city?.value} value={city.value}>
                      {city.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="website"
              label="Website"
              // rules={rules.website}
            >
              <Input placeholder="Website" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item name="isNewProject" valuePropName="checked">
              <Checkbox>
                Click here if this is new project in the market.
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Col span={6}>
            <Button
              onClick={handleCancle}
              style={{ width: "100%" }}
              type="default"
            >
              Cancel
            </Button>
          </Col>
          <Col span={6}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
              loading={isSubmit}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddEditCustomer;
