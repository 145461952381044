import React from "react";
import RegisterForm from "../../sections/RegisterForm/RegisterForm";

const Register = () => {
  return (
    <>
      {/* <div>
        <div>
          <Header />
        </div> */}
      <RegisterForm />
      {/* <div>
          <Footer />
        </div> */}
      {/* </div> */}
    </>
  );
};

export default Register;
