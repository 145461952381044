import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { roleListApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  list: [],
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
  createdBy: null,
  roleId: null,
  viewData: null,
};

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    roleList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.createdBy = action.payload.createdBy;
      state.searchText = "";
      state.isNext = false;
    },
    roleListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
    },
    roleListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    roleListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
    },
    roleListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
    },
    roleSearch(state, action) {
      state.searchText = action.payload.searchText;
      state.page = 1;
      state.limit = 10;
    },
    viewRole(state, action) {
      state.roleId = action.payload.roleId;
      state.isLoading = true;
    },
    viewRoleSuccess(state, action) {
      state.viewData = action.payload.data;
      state.isLoading = false;
    },
    viewRoleFailed(state) {
      state.viewData = null;
      state.isLoading = false;
    },
  },
});

export const {
  actions: roleActions,
  reducer: roleReducer,
  name: roleKey,
} = roleSlice;

const selectDomain = (state) => state.roles || initialState;

export const selectRole = createSelector(
  [selectDomain],
  (roleState) => roleState
);

export const getState = (state) => state.roles;

export function* roleListReq() {
  try {
    const states = yield select(getState);
    const res = yield roleListApi(states);

    if (res.data.success) {
      yield put(roleActions.roleListSuccess(res.data));
    } else {
      yield put(roleActions.roleListFailed(res.data));
    }
  } catch (error) {
    yield put(roleActions.roleListFailed(error));
  }
}

// export function* viewroleReq() {
//   try {
//     const states = yield select(getState);
//     const res = yield getroleApi(states);

//     if (res.data.success) {
//       yield put(roleActions.viewroleSuccess(res.data));
//     } else {
//       yield put(roleActions.viewroleFailed(res.data));
//     }
//   } catch (error) {
//     yield put(roleActions.viewroleFailed(error));
//   }
// }

export function* roleSaga() {
  yield takeLatest(roleActions.roleList.type, roleListReq);
  yield takeLatest(roleActions.roleListPageLimit.type, roleListReq);
  yield takeLatest(roleActions.roleListLimit.type, roleListReq);
  yield takeLatest(roleActions.roleSearch.type, roleListReq);
  //   yield takeLatest(roleActions.viewrole.type, viewroleReq);
}
