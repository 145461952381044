import React from "react";
import { Button, Col, Form, Modal, Row, Input } from "antd";

const AddConversionSaleValue = ({
  open,
  setOpen,
  formDefine,
  handleSubmit,
  submitLoading,
  rowData,
}) => {
  const rules = {
    conversionSale: [
      {
        required: true,
        message: "Please Enter Conversion Sale Value",
      },
      {
        validator: (rule, value) => {
          if (
            value <=
            Number(rowData?.potentialValue?.total) -
              Number(rowData?.potentialValue?.existingSaleCurrentVolue)
          ) {
            return Promise.resolve();
          } else {
            return Promise.reject(
              `Please Add Values Under ${
                Number(rowData?.potentialValue?.total) -
                  Number(rowData?.potentialValue?.existingSaleCurrentVolue) ||
                "N/A"
              }`
            );
          }
        },
      },
    ],
  };

  const handleCancel = () => {
    setOpen(false);
    formDefine?.resetFields();
  };

  return (
    <>
      <Modal
        title="Add/Edit Conversion Sale Value"
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <div>
            <Form
              name={formDefine}
              layout="vertical"
              form={formDefine}
              onFinish={handleSubmit}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="conversionSale"
                    label="Conversion Sale Value"
                    rules={rules.conversionSale}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Conversion Sale Value"
                      className="hide-arrows"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      disabled={rowData?.potentialValue?.total ? false : true}
                    />
                  </Form.Item>
                  <p style={{ fontSize: "12px", color: "red" }}>
                    {rowData?.potentialValue?.total
                      ? ""
                      : "*Please Add Potential Data First"}
                  </p>
                </Col>
              </Row>

              <div>
                <span style={{ color: "red" }}>
                  Note:- Please enter value as like Eg. 100000{" "}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handleCancel} htmlType="reset" type="default">
                  Cancel
                </Button>
                <Button
                  loading={submitLoading}
                  htmlType="submit"
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddConversionSaleValue;
