import React, { useEffect, useState } from "react";
import { Select } from "antd";
import {
  customerActions,
  selectCustomer,
} from "../../redux/slices/customerSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import InitialAndCurrentMarketShare from "../../sections/DashboardManage/InitialAndCurrentMarketShare";
import ConversionRatios from "../../sections/DashboardManage/ConversionRatios";
import ProgressReports from "../../sections/DashboardManage/ProgressReports";
import SalesVsActual from "../../sections/DashboardManage/SalesVsActual";
import CustomersReport from "../../sections/DashboardManage/CustomersReport";
import ProductsReport from "../../sections/DashboardManage/ProductsReport";
import { productActions, selectProduct } from "../../redux/slices/productSlice";

const { Option } = Select;

const filteredOptions = [
  {
    label: "Initial Vs Current Market Share",
    value: "initialVsCurrentMarketShare",
  },
  {
    label: "Conversion Ratios (Plans vs Conversion)",
    value: "conversionRatio",
  },
  {
    label: "Progress Reports",
    value: "progressReports",
  },
  {
    label: "Sales Plan Vs Actual",
    value: "salesVsActual",
  },
  {
    label: "Customers Report",
    value: "customersReport",
  },
  {
    label: "Products Report",
    value: "productsReport",
  },
];

const DashboardCharts = () => {
  const dispatch = useDispatch();

  const customerData = useSelector(selectCustomer);
  const productsData = useSelector(selectProduct);
  const loggedUser = useSelector(selectLoggedUser);

  const [selectionFilter, setSelectionFilter] = useState({
    chartFilter: filteredOptions[0].value,
  });
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);

  const handleChange = (val) => {
    setSelectionFilter({
      ...selectionFilter,
      chartFilter: val,
    });
  };

  useEffect(() => {
    dispatch(customerActions.customerList({ limit: 9999, page: 1 }));
    dispatch(
      productActions.productList({
        limit: 9999,
        page: 1,
        funnelId: loggedUser?.activeFunnel?._id,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (customerData) {
      setCustomers(customerData?.list);
    }
  }, [customerData]);

  useEffect(() => {
    if (productsData) {
      setProducts(productsData?.list);
    }
  }, [productsData]);

  return (
    <>
      <h2>Dashboard</h2>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <Select
          value={selectionFilter?.chartFilter}
          style={{ width: 300 }}
          onChange={handleChange}
        >
          {filteredOptions?.map((val, index) => (
            <Option value={val.value} key={index}>
              {val.label}
            </Option>
          ))}
        </Select>
      </div>

      {selectionFilter?.chartFilter === "initialVsCurrentMarketShare" && (
        <InitialAndCurrentMarketShare
          loggedUser={loggedUser}
          selectionFilter={selectionFilter}
          customers={customers}
          products={products}
        />
      )}

      {selectionFilter?.chartFilter === "conversionRatio" && (
        <ConversionRatios
          loggedUser={loggedUser}
          selectionFilter={selectionFilter}
          customers={customers}
          products={products}
        />
      )}

      {selectionFilter?.chartFilter === "progressReports" && (
        <ProgressReports
          loggedUser={loggedUser}
          selectionFilter={selectionFilter}
          customers={customers}
          products={products}
        />
      )}

      {selectionFilter?.chartFilter === "salesVsActual" && (
        <SalesVsActual
          loggedUser={loggedUser}
          selectionFilter={selectionFilter}
          customers={customers}
          products={products}
        />
      )}

      {selectionFilter?.chartFilter === "customersReport" && (
        <CustomersReport
          loggedUser={loggedUser}
          selectionFilter={selectionFilter}
          customers={customers}
          products={products}
        />
      )}

      {selectionFilter?.chartFilter === "productsReport" && (
        <ProductsReport
          loggedUser={loggedUser}
          selectionFilter={selectionFilter}
          customers={customers}
          products={products}
        />
      )}
    </>
  );
};

export default DashboardCharts;
