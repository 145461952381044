import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";

const styles = StyleSheet.create({
  invoiceDateContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    width: 35,
  },
});

const InvoiceNo = ({ state }) => {
  return (
    <>
      <Fragment>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date: </Text>
          <Text>
            {state?.invoiceData?.startSubcription
              ? dayjs(state?.invoiceData?.startSubcription).format("DD/MM/YYYY")
              : ""}
          </Text>
        </View>
      </Fragment>
    </>
  );
};

export default InvoiceNo;
