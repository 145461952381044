export const PATH_DASHBOARD = {
  root: "/app/dashboard",
  customer: "/app/customer",
  addCustomer: "/app/customer/add",
  editCustomer: (id) => `/app/customer/edit/${id}`,
  product: "/app/product",
  addProduct: "/app/product/add",
  editProduct: (id) => `/app/product/edit/${id}`,
  funnel: "/app/funnel",
  addFunnel: "/app/funnel/add",
  editFunnel: (id) => `/app/funnel/edit/${id}`,
  viewFunnelRow: (id) => `/app/funnel-row/${id}`,
  listFunnelRow: `/app/funnel-row`,
  addFunnelRow: (id) => `/app/funnel-row/add/${id}`,
  editFunnelRow: (id, rowId) => `/app/funnel-row/edit/${id}/${rowId}`,
  progressReport: "/app/progress-report",
  organizationChart: "/app/organization-chart",
  viewOrganizationChart: (customerId) =>
    `/app/organization-chart/view/${customerId}`,
  management: "/app/management",
  potentialMap: "/app/potential-map",
  salesVsActual: "/app/sales-vs-actual",
  subscriptionPrice: "/app/subscription-price",
  renewalSubscription: "/app/renewal-subscription",
  subscriptions: "/app/subscriptions",
  mainInvoiceFile: (id) => `/app/subcription/invoice/${id}`,
};

export const PATH_AUTH = {
  root: "/auth/login",
  register: "/auth/register",
  pricingStructure: "/pricing-structure",
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
  aboutUs: "/about-us",
  helpAndSupport: "/help-and-support",
  inquiry: "/inquiry",
  contactUs: "/contact-us",
};
