import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { salesVsActualListApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  list: [],
  funnelId: null,
  userId: null,
  filterBy: "overview",
  filterYear: "current",
  selectedCustomer: null,
};

const salesVsActualSlice = createSlice({
  name: "salesVsActual",
  initialState,
  reducers: {
    salesVsActualList(state, action) {
      state.isLoading = true;
      state.funnelId = action.payload.funnelId;
      state.userId = action.payload.userId;
      state.filterBy = action.payload.filterBy;
      state.filterYear = action.payload.filterYear;
      state.selectedCustomer = action.payload.selectedCustomer;
    },
    salesVsActualListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
    },
    salesVsActualListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.list = [];
    },
  },
});

export const {
  actions: salesVsActualActions,
  reducer: salesVsActualReducer,
  name: salesVsActualKey,
} = salesVsActualSlice;

const selectDomain = (state) => state.salesVsActual || initialState;

export const selectSalesVsActual = createSelector(
  [selectDomain],
  (salesVsActualState) => salesVsActualState
);

export const getState = (state) => state.salesVsActual;

export function* salesVsActualListReq() {
  try {
    const states = yield select(getState);
    const res = yield salesVsActualListApi(states);

    if (res.data.success) {
      yield put(salesVsActualActions.salesVsActualListSuccess(res.data));
    } else {
      yield put(salesVsActualActions.salesVsActualListFailed(res.data));
    }
  } catch (error) {
    yield put(salesVsActualActions.salesVsActualListFailed(error));
  }
}

export function* salesVsActualSaga() {
  yield takeLatest(
    salesVsActualActions.salesVsActualList.type,
    salesVsActualListReq
  );
}
