import { Segmented, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  funnelRowActions,
  selectFunnelRow,
} from "../../redux/slices/funnelRowSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import CustomPagination from "../../pages/funnelRow/Pagination";
import dayjs from "dayjs";
import { PATH_DASHBOARD } from "../../routes/path";

const segmentOptions = ["All", "Ongoing", "Converted"];

const FunnelRowProgressReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const funnelRowData = useSelector(selectFunnelRow);
  const loggedUser = useSelector(selectLoggedUser);

  const funnelData = loggedUser?.activeFunnel;
  const viewFunnelData = funnelData;

  const [funnelRows, setFunnelRows] = useState([]);
  const [listProgress, setListProgress] = useState([]);

  useEffect(() => {
    if (loggedUser?.stages?.length > 0) {
      setListProgress(loggedUser?.stages);
    } else {
      setListProgress([]);
    }
  }, [loggedUser]);

  useEffect(() => {
    if (viewFunnelData?._id) {
      dispatch(
        funnelRowActions.funnelRowList({
          limit: 10,
          page: 1,
          funnelId: viewFunnelData?._id,
          userId: loggedUser?._id,
          isDelete: false,
          isCarryForward: false,
        })
      );
    }
    // eslint-disable-next-line
  }, [viewFunnelData]);

  useEffect(() => {
    if (funnelRowData) {
      setFunnelRows(funnelRowData?.list);
    }
  }, [funnelRowData]);

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerId",
      fixed: "left",
      width: 100,
      render: (_, elm) => (
        <span>{elm?.customerId ? elm?.customerId?.name : "-"}</span>
      ),
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>
          Competition Brand & Product Details
        </span>
      ),
      dataIndex: "competitionBrand",
      width: 120,
      render: (val, elm) => (
        <>
          {val ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                wordBreak: "break-all",
                gap: 2,
              }}
            >
              {val &&
                Object.keys(val).map((keys) => (
                  <>
                    <span style={{ margin: 0 }}>
                      {val[keys] && val
                        ? `${
                            val[keys]?.length > 15
                              ? `${val[keys].slice(0, 12)}...`
                              : val[keys]
                          }`
                        : "-"}
                      ,
                    </span>
                  </>
                ))}
            </div>
          ) : (
            <>-</>
          )}
        </>
      ),
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>
          {loggedUser?.companyName || ""} Product Details against competition
        </span>
      ),
      dataIndex: "productAgaintCompetition",
      width: 120,
      render: (val, elm) => (
        <>
          {val ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  wordBreak: "break-all",
                  gap: 2,
                }}
              >
                {val &&
                  Object.keys(val).map((keys) => (
                    <>
                      <span style={{ margin: 0 }}>
                        {val[keys] && val
                          ? `${
                              val[keys]?.length > 15
                                ? `${val[keys].slice(0, 12)}...`
                                : val[keys]
                            }`
                          : "-"}
                        ,
                      </span>
                    </>
                  ))}
              </div>
            </>
          ) : (
            <>-</>
          )}
        </>
      ),
    },
    {
      title: "Progress Stages",
      dataIndex: "progressStage",
      align: "center",
      width: 600,
      render: (val) => {
        return (
          <>
            <div style={{ display: "flex", gap: "20px" }}>
              {listProgress?.length > 0 ? (
                listProgress?.map((v, index) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "5px",
                        fontSize: "12px",
                        borderRadius: "50%",
                        background: `${val?.bg === v?.bg ? val?.bg : "#fff"}`,
                        color: "#000",
                        fontWeight: 600,
                        letterSpacing: "0.5px",
                        position: "relative",
                        width: "50px",
                        border: "1px solid #c3c3c3",
                      }}
                    >
                      <span>{index + 1}</span>
                      {index !== listProgress.length - 1 && (
                        <div
                          className="connection-line"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "-24px",
                            width: "24px",
                            height: "2px",
                            background: "#ccc",
                          }}
                        />
                      )}
                    </div>
                  </>
                ))
              ) : (
                <>No Stages</>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: <span style={{ fontSize: "12px" }}>Conversion days due</span>,
      dataIndex: "conversionDueDay",
      width: 110,
      render: (val, elm) => {
        const currentDate = dayjs(val).startOf("day");
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline ? (
              <div>{diffInDays < 0 ? "0" : diffInDays} days left</div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Funnel",
      dataIndex: "funnelId",
      fixed: "right",
      width: 90,
      render: (val, elm) => (
        <>
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => handleViewFunnelRow(elm)}
          >
            View
          </span>
        </>
      ),
    },
  ];

  const handleViewFunnelRow = (row) => {
    if (row) {
      navigate(PATH_DASHBOARD.listFunnelRow);
    }
  };

  const handleChange = (val) => {
    if (val) {
      dispatch(
        funnelRowActions.funnelRowList({
          limit: 10,
          page: 1,
          funnelId: viewFunnelData?._id,
          userId: loggedUser?._id,
          isDelete: false,
          isCarryForward: false,
          stageFilter: val ? val : "All",
        })
      );
    }
  };

  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <Segmented options={segmentOptions} onChange={handleChange} />
      </div>
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={funnelRows}
          loading={funnelRowData?.isLoading}
          pagination={false}
          bordered
          rowKey="id"
          scroll={{
            x: 1000,
            y: 400,
          }}
        />
        <div style={{ marginTop: "20px" }}>
          <CustomPagination />
        </div>
      </div>
    </>
  );
};

export default FunnelRowProgressReport;
