/* eslint-disable */
import {
  Avatar,
  Button,
  Col,
  Input,
  Form,
  Row,
  Upload,
  message,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WarningOutlined } from "@ant-design/icons";

import { authActions, selectLoggedUser } from "../../redux/slices/authSlice";
import userLogo from "../../assets/images/user.png";
import { updateProfile } from "../../redux/apis/dashboardApi";
import { STATE_LIST } from "../../utils/contants/stateList";

import "../../sections/RegisterForm/RegisterCss.css";
import uniqueArrayList from "../../utils/UniqueArrayList";

const { Option } = Select;

const rules = {
  firstName: [
    {
      required: true,
      message: "Please enter first name",
    },
    {
      pattern: /^[A-Za-z]+$/,
      message: "Please enter a valid first name",
    },
  ],
  lastName: [
    {
      required: true,
      message: "Please enter last name",
    },
    {
      pattern: /^[A-Za-z]+$/,
      message: "Please enter valid last name",
    },
  ],
  email: [
    {
      required: true,
      message: "Please enter email",
    },
    {
      type: "email",
      message: "Please enter a valid email",
    },
  ],
  mobile: [
    {
      required: true,
      message: "Please enter mobile",
    },
    {
      max: 12,
      message: "Please enter valid mobile",
    },
  ],
  state: [
    {
      required: true,
      message: "Please enter state",
    },
  ],
  location: [
    {
      required: true,
      message: "Please enter address",
    },
  ],
  companyName: [
    {
      required: true,
      message: "Please enter company name",
    },
  ],
  department: [
    {
      required: true,
      message: "Please enter department",
    },
  ],
  designation: [
    {
      required: true,
      message: "Please enter designation",
    },
  ],
};

const initialCityAndStateData = {
  cities: [],
  states: [],
  selectedState: null,
  selectedCity: null,
};

const EditProfile = () => {
  const [editProfileForm] = Form.useForm();

  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoggedUser);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [avatarUrlData, setAvatarUrlData] = useState(loggedUser?.image);
  const [listOfCityAndState, setListOfCityAndState] = useState(
    initialCityAndStateData
  );

  useEffect(() => {
    if (loggedUser) {
      editProfileForm.setFieldsValue({
        firstName: loggedUser?.firstName,
        lastName: loggedUser?.lastName,
        email: loggedUser?.email,
        mobile: loggedUser?.mobile,
        location: loggedUser?.location,
        companyName: loggedUser?.companyName,
        department: loggedUser?.department,
        designation: loggedUser?.designation,
        imgage: loggedUser?.image,
        state: loggedUser?.state,
      });
    }
  }, [loggedUser]);

  useEffect(() => {
    if (STATE_LIST.length) {
      let states = STATE_LIST.map((val) => {
        return {
          label: val?.state,
          value: val?.state,
        };
      });

      let newUniqueList = uniqueArrayList(states, "value");

      newUniqueList.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
      );

      setListOfCityAndState({
        ...listOfCityAndState,
        states: newUniqueList,
        selectedState: null,
        cities: [],
      });
    }
    // eslint-disable-next-line
  }, [STATE_LIST]);

  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    listType: "picture",

    customRequest({ file, onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return message.error("Image must smaller than 2MB!");
      } else {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const img = document.createElement("img");
            img.src = reader.result;
            img.onload = () => {
              const canvas = document.createElement("canvas");
              canvas.width = img.naturalWidth;
              canvas.height = img.naturalHeight;
              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0);
              ctx.textBaseline = "middle";
              canvas.toBlob(resolve);
            };
          };
        });
      }
    },
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleChangeUploadProfile = async (info) => {
    if (info.file.status === "uploading") {
      // console.log("info ---", info.file.size / 1024 / 1024 < 2)
      const isLt2M = info.file.size / 1024 / 1024 < 2;
      if (isLt2M) {
        setUploadLoading(true);
        info.file.status = "done";
      }
    }
    if (info.file.status === "done") {
      const isLt2M = info.file.size / 1024 / 1024 < 2;
      if (isLt2M) {
        const previous_file = new File(
          [info.file.originFileObj],
          `${window.location.host}_${new Date().getTime()}_${info.file.name}`,
          { type: info.file.type }
        );

        const base64Profile = await getBase64(previous_file);

        if (base64Profile) {
          setAvatarUrlData(base64Profile);
          setUploadLoading(false);
          message.success(`Profile Upload Successfully`);
        } else {
          setAvatarUrlData(userLogo);
          setUploadLoading(false);
          message.error(`Something went wrong, Please try again`);
        }
      }
    }
    if (info.file.status === "error") {
      setUploadLoading(false);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onRemoveAvater = () => {
    setAvatarUrlData(userLogo);
    setUploadLoading(false);
  };

  const onFinish = async (values) => {
    try {
      const data = {
        companyName: values?.companyName,
        department: values?.department,
        designation: values?.designation,
        email: values?.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        location: values?.location,
        mobile: values?.mobile,
        image: avatarUrlData ?? null,
        state: values?.state,
      };

      setSubmitLoading(true);
      const res = await updateProfile(loggedUser?._id, data);
      if (res.data?.success) {
        message.success(res.data?.message);

        setTimeout(() => {
          setSubmitLoading(false);
          dispatch(authActions.isLogin());
        }, 1000);
      } else {
        setSubmitLoading(false);
        message.error(res.data?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.error(error?.response.data?.message);
    }
  };

  const handleChangeState = (val) => {
    let cities = STATE_LIST.filter((state) => state.state === val).map((v) => {
      return {
        label: v?.name,
        value: v?.name,
      };
    });

    let newUniqueList = uniqueArrayList(cities, "value");

    newUniqueList.sort((a, b) =>
      a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
    );

    if (newUniqueList.length) {
      setListOfCityAndState((pre) => ({
        ...pre,
        cities: newUniqueList,
      }));
    }

    setListOfCityAndState((pre) => ({
      ...pre,
      selectedState: val,
      selectedCity: null,
      cities: newUniqueList?.length ? newUniqueList : [],
    }));

    editProfileForm.setFieldValue("location", null);
  };

  const handleChangeCity = (val) => {
    setListOfCityAndState((pre) => ({
      ...pre,
      selectedCity: val,
    }));
  };

  return (
    <>
      {loggedUser?.accountType === "demo" && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              marginBottom: "20px",
              fontWeight: 600,
              fontStyle: "italic",
              fontSize: "18px",
            }}
          >
            <span style={{ color: "orange", fontSize: "20px" }}>
              <WarningOutlined />
            </span>
            <span style={{ color: "orange" }}>Demo Account</span>
          </div>
        </>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          gap: 6,
          justifyContent: "center",
        }}
      >
        <Avatar
          style={{ border: "1px solid #d3d3d3" }}
          size={90}
          src={avatarUrlData ?? userLogo}
        />
        <div style={{ marginLeft: "30px" }}>
          <Upload
            {...uploadProps}
            showUploadList={false}
            accept=".jpg, .png"
            onChange={handleChangeUploadProfile}
          >
            <Button
              loading={uploadLoading}
              type="primary"
              style={{ marginRight: "10px" }}
            >
              Change Profile
            </Button>
          </Upload>
          <Button className="ml-2" onClick={onRemoveAvater}>
            Remove
          </Button>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        <Form
          name="editProfileForm"
          form={editProfileForm}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col xs={24} sm={24} md={24} lg={18}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={rules.firstName}
                  >
                    <Input maxLength={35} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={rules.lastName}
                  >
                    <Input maxLength={35} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Email" name="email" rules={rules.email}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Mobile" name="mobile" rules={rules.mobile}>
                    <Input
                      maxLength={12}
                      stringMode={true}
                      style={{ width: "100%" }}
                      className="hide-arrows"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Company Name"
                    name="companyName"
                    rules={rules.companyName}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Department"
                    name="department"
                    rules={rules.department}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8}>
                  <Form.Item
                    label="Designation"
                    name="designation"
                    rules={rules.designation}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {/* <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Address"
                    name="location"
                    rules={rules.location}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}

                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter State",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select State"
                      onChange={handleChangeState}
                      showSearch
                      allowClear
                    >
                      {listOfCityAndState.states?.length &&
                        listOfCityAndState.states?.map((state) => (
                          <Option key={state?.value} value={state.value}>
                            {state.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Form.Item
                    name="location"
                    label="Location / City"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Location / City",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select location / city"
                      showSearch
                      allowClear
                      disabled={
                        listOfCityAndState.cities?.length === 0 ||
                        [null, undefined, ""].includes(
                          listOfCityAndState.selectedState
                        )
                          ? true
                          : false
                      }
                      value={listOfCityAndState.selectedCity}
                      onChange={handleChangeCity}
                    >
                      {listOfCityAndState.cities?.length &&
                        listOfCityAndState.cities?.map((city) => (
                          <Option key={city?.value} value={city.value}>
                            {city.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ marginTop: "20px" }}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={submitLoading}
                >
                  Save Changes
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default EditProfile;
