import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import {
  potentialMapListApi,
  potentialMapTotalApi,
} from "../apis/dashboardApi";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  list: [],
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
  funnelId: null,
  userId: null,
  isDelete: false,
  isCarryForward: false,
  stageFilter: "All",
  totalPotential: null,
  customerName: "All",
};

const potentialMapSlice = createSlice({
  name: "potentialMaps",
  initialState,
  reducers: {
    potentialMapList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.searchText = "";
      state.isNext = false;
      state.funnelId = action.payload.funnelId;
      state.userId = action.payload.userId;
      state.isDelete = action.payload.isDelete;
      state.isCarryForward = action.payload.isCarryForward;
      state.stageFilter = action.payload.stageFilter;
    },
    potentialMapListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
    },
    potentialMapListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    potentialMapListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
      state.funnelId = action.payload.funnelId;
    },
    potentialMapListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
      state.funnelId = action.payload.funnelId;
    },
    potentialMapFilter(state, action) {
      state.customerName = action.payload.customerName;
      state.page = 1;
      state.limit = 10;
      state.funnelId = action.payload.funnelId;
    },
    potentialMapTotal(state, action) {
      state.funnelId = action.payload.funnelId;
      state.customerName = action.payload.customerName;
      state.userId = action.payload.userId;
      state.searchText = "";
    },
    potentialMapTotalSuccess(state, action) {
      state.totalPotential = action.payload.data;
    },
  },
});

export const {
  actions: potentialMapActions,
  reducer: potentialMapReducer,
  name: potentialMapKey,
} = potentialMapSlice;

const selectDomain = (state) => state.potentialMaps || initialState;

export const selectPotentialMap = createSelector(
  [selectDomain],
  (potentialMapState) => potentialMapState
);

export const getState = (state) => state.potentialMaps;

export function* potentialMapListReq() {
  try {
    const states = yield select(getState);
    const res = yield potentialMapListApi(states);

    if (res.data.success) {
      yield put(potentialMapActions.potentialMapListSuccess(res.data));
    } else {
      yield put(potentialMapActions.potentialMapListFailed(res.data));
    }
  } catch (error) {
    yield put(potentialMapActions.potentialMapListFailed(error));
  }
}

export function* potentialMapTotalReq() {
  try {
    const states = yield select(getState);
    const res = yield potentialMapTotalApi(states);

    if (res.data.success) {
      yield put(potentialMapActions.potentialMapTotalSuccess(res.data));
    } else {
      yield put(potentialMapActions.potentialMapTotalSuccess(res.data));
    }
  } catch (error) {
    yield put(potentialMapActions.potentialMapTotalSuccess(error));
  }
}

export function* potentialMapSaga() {
  yield takeLatest(
    potentialMapActions.potentialMapList.type,
    potentialMapListReq
  );
  yield takeLatest(
    potentialMapActions.potentialMapListPageLimit.type,
    potentialMapListReq
  );
  yield takeLatest(
    potentialMapActions.potentialMapListLimit.type,
    potentialMapListReq
  );
  yield takeLatest(
    potentialMapActions.potentialMapFilter.type,
    potentialMapListReq
  );
  yield takeLatest(
    potentialMapActions.potentialMapTotal.type,
    potentialMapTotalReq
  );
}
