import React from "react";
import { Button, Row, Col, Dropdown, Menu } from "antd";
import { LeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  carryForwardRowActions,
  selectCarryForwardRow,
} from "../../redux/slices/carryForwardRow";

export function CarryForwardRowPagination() {
  const pageSizeOptions = ["10", "20", "50"];
  const funnelsData = useSelector(selectCarryForwardRow);
  const dispatch = useDispatch();

  const Mymenu = (
    <Menu>
      {pageSizeOptions.map((e, i) => (
        <Menu.Item
          onClick={() => {
            dispatch(
              carryForwardRowActions.carryForwardRowListLimit({
                limit: Number(e),
              })
            );
          }}
          key={i}
        >
          {e}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Row gutter={10}>
          <Col>
            <Button
              size="middle"
              disabled={funnelsData.page <= 1}
              onClick={() => {
                dispatch(
                  carryForwardRowActions.carryForwardRowListPageLimit({
                    page: funnelsData.page - 1,
                  })
                );
              }}
              icon={<LeftOutlined />}
            />
          </Col>
          <Col>
            <Button size="middle">{funnelsData.page}</Button>
          </Col>
          <Col>
            <Button
              size="middle"
              disabled={!funnelsData.isNext}
              onClick={() => {
                dispatch(
                  carryForwardRowActions.carryForwardRowListPageLimit({
                    page: funnelsData.page + 1,
                  })
                );
              }}
              icon={<RightOutlined />}
            />
          </Col>
          <Col>
            <Dropdown overlay={Mymenu}>
              <Button size="middle">
                {funnelsData.limit} <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CarryForwardRowPagination;
