import saga from "redux-saga";
// import { all, fork } from 'redux-saga/effects'
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { rootReducer } from "./rootReducer";
import { authSaga } from "./slices/authSlice";
import { all, fork } from "redux-saga/effects";
import { customerSaga } from "./slices/customerSlice";
import { productSaga } from "./slices/productSlice";
import { funnelSaga } from "./slices/funnelSlice";
import { funnelRowSaga } from "./slices/funnelRowSlice";
import { carryForwardRowSaga } from "./slices/carryForwardRow";
import { employeeSaga } from "./slices/employeeSlice";
import { roleSaga } from "./slices/roleSlice";
import { superUserSaga } from "./slices/superUserSlice";
import { potentialMapSaga } from "./slices/potentialMapSlice";
import { salesVsActualSaga } from "./slices/salesVsActualSlice";
import { subcriptionSaga } from "./slices/subcriptionSlice";

function* RootSaga() {
  yield all([fork(authSaga)]);
  yield all([fork(customerSaga)]);
  yield all([fork(productSaga)]);
  yield all([fork(funnelSaga)]);
  yield all([fork(funnelRowSaga)]);
  yield all([fork(carryForwardRowSaga)]);
  yield all([fork(employeeSaga)]);
  yield all([fork(roleSaga)]);
  yield all([fork(superUserSaga)]);
  yield all([fork(potentialMapSaga)]);
  yield all([fork(salesVsActualSaga)]);
  yield all([fork(subcriptionSaga)]);
}

const sagaMiddlerware = saga();

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
    sagaMiddlerware,
  ],
  devTools: process.env.NODE_ENV !== "production",
});

sagaMiddlerware.run(RootSaga);

const persistor = persistStore(store);

export { store, persistor };
