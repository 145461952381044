import { Col, Modal, Row } from "antd";
import React from "react";

const ViewCustomerDetailsPopup = ({ data, setData }) => {
  const handleClose = () => {
    setData({ data: null, model: false });
  };

  return (
    <>
      <Modal
        title="Customer Details"
        open={data?.model}
        onCancel={handleClose}
        destroyOnClose
        footer={null}
      >
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Customer Name:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.name ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Location:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.location ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>State:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.state ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>GST No:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.gstNo ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Plant Address:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.cusPlantAddress ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Site Address:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.custSiteAddress ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Office Address:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.officeAddress ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Industry:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.industry ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Payment Terms:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.paymentTerms ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Plant One Address:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.plantOneAddress ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Plant Two Address:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.plantTwoAddress ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ borderBottom: "1px solid #c3c3c3", padding: "5px 0px" }}
        >
          <Col span={12}>
            <span>Sub Industry:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.subIndustry ?? "-"}</span>
          </Col>
        </Row>
        <Row
          gutter={12}
          style={{ padding: "10px 0px" }}
        >
          <Col span={12}>
            <span>New Project In The Market:-</span>
          </Col>
          <Col span={12}>
            <span>{data?.data?.isNewProject ? "Yes" : "No"}</span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ViewCustomerDetailsPopup;
