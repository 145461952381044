import React from "react";
import { Button, Row, Col, Dropdown, Menu } from "antd";
import { LeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { productActions, selectProduct } from "../../redux/slices/productSlice";

export function CustomPagination() {
  const pageSizeOptions = ["10", "20", "50"];
  const productsData = useSelector(selectProduct);
  const dispatch = useDispatch();

  const Mymenu = (
    <Menu>
      {pageSizeOptions.map((e, i) => (
        <Menu.Item
          onClick={() => {
            dispatch(productActions.productListLimit({ limit: Number(e) }));
          }}
          key={i}
        >
          {e}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Row gutter={10}>
          <Col>
            <Button
              size="middle"
              disabled={productsData.page <= 1}
              onClick={() => {
                dispatch(
                  productActions.productListPageLimit({
                    page: productsData.page - 1,
                  })
                );
              }}
              icon={<LeftOutlined />}
            />
          </Col>
          <Col>
            <Button size="middle">{productsData.page}</Button>
          </Col>
          <Col>
            <Button
              size="middle"
              disabled={!productsData.isNext}
              onClick={() => {
                dispatch(
                  productActions.productListPageLimit({
                    page: productsData.page + 1,
                  })
                );
              }}
              icon={<RightOutlined />}
            />
          </Col>
          <Col>
            <Dropdown overlay={Mymenu}>
              <Button size="middle">
                {productsData.limit} <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CustomPagination;
