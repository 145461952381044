import { Select, Button, message, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { getDashboardApi } from "../../redux/apis/dashboardApi";
import BarChart from "./BarChart";
import { monthsArrayList } from "../../utils/general-constant";

const { Option } = Select;

const intialFilterState = {
  customerList: [],
  productList: [],
  filterYear: null,
  filterMonth: null,
  filterQuater: null,
};

const initialQueryState = {
  list: [],
  start: null,
  end: null,
  selectedQuarter: null,
};

const SalesVsActual = ({
  loggedUser,
  selectionFilter,
  customers,
  products,
}) => {
  const [resultData, setResultData] = useState(null);
  const [filterStates, setFilterStates] = useState(intialFilterState);
  const [quarterData, setQuarterData] = useState(initialQueryState);

  // dasboard api call
  const getDashboardData = async (data) => {
    try {
      const res = await getDashboardApi(data);

      if (res?.data?.success) {
        setResultData(res?.data?.data);
      } else {
        message.error(res?.data?.message || "Something went wrong");
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const getQuarterData = () => {
    let startMonth = loggedUser?.presets?.financialYear
      ? monthsArrayList?.findIndex(
          (val) => val === loggedUser?.presets?.financialYear?.start
        )
      : 0;

    let endMonth = loggedUser?.presets?.financialYear
      ? monthsArrayList?.findIndex(
          (val) => val === loggedUser?.presets?.financialYear?.end
        )
      : 11;

    let resetMonthsArrayList = [
      ...monthsArrayList.slice(startMonth),
      ...monthsArrayList.slice(0, startMonth),
    ];

    let quarterList = resetMonthsArrayList.reduce((quarters, month, index) => {
      if (index % 3 === 0) {
        let nextTwoMonths = resetMonthsArrayList.slice(index, index + 3);
        let label = `Q${Math.ceil((index + 1) / 3)} (${nextTwoMonths[0]}-${
          nextTwoMonths[2]
        })`;

        let value = nextTwoMonths.map((m) => monthsArrayList.indexOf(m));
        value = value?.join(",");

        quarters.push({ label, value });
      }
      return quarters;
    }, []);

    setQuarterData({
      ...quarterData,
      list: quarterList,
      start: startMonth,
      end: endMonth,
    });
  };

  useEffect(() => {
    if (loggedUser && selectionFilter) {
      let data = {
        userId: loggedUser?._id,
        funnelId: loggedUser?.activeFunnel?._id || null,
      };

      if (selectionFilter?.chartFilter) {
        data.chartFilter = selectionFilter?.chartFilter;
      }

      if (loggedUser?.presets) {
        getQuarterData();
      }

      getDashboardData(data);
    }
    // eslint-disable-next-line
  }, [loggedUser, selectionFilter]);

  const handleCustomerFilter = (customer) => {
    setFilterStates({
      ...filterStates,
      customerList: customer,
    });
  };

  const handleProductFilter = (product) => {
    setFilterStates({
      ...filterStates,
      productList: product,
    });
  };

  const handleChangeYear = (date) => {
    setFilterStates({
      ...filterStates,
      filterYear: date,
    });
  };

  const handleChangeMonth = (month) => {
    setFilterStates({
      ...filterStates,
      filterMonth: month,
    });
  };

  const handleChangeQuarter = (quarter) => {
    setQuarterData({
      ...quarterData,
      selectedQuarter: quarter,
    });
  };

  const applyFilters = () => {
    let data = {
      userId: loggedUser?._id,
      funnelId: loggedUser?.activeFunnel?._id || null,
    };

    if (selectionFilter?.chartFilter) {
      data.chartFilter = selectionFilter?.chartFilter;
    }

    if (
      filterStates.filterMonth !== null &&
      filterStates.filterMonth !== undefined
    ) {
      data.filterMonth = filterStates.filterMonth;
    }

    if (filterStates.filterYear) {
      data.filterYear = dayjs(filterStates.filterYear).year();
    }

    if (filterStates.customerList?.length > 0) {
      data.customerList = filterStates.customerList;
    }

    if (filterStates.productList?.length > 0) {
      data.productList = filterStates.productList;
    }

    if (
      quarterData.selectedQuarter !== null &&
      quarterData.selectedQuarter !== ""
    ) {
      data.filterQuater = quarterData.selectedQuarter;
    }

    getDashboardData(data);
  };

  const handleClearFilters = () => {
    setFilterStates(intialFilterState);
    setQuarterData({
      ...quarterData,
      selectedQuarter: null,
    });

    let data = {
      userId: loggedUser?._id,
      funnelId: loggedUser?.activeFunnel?._id || null,
    };

    if (selectionFilter?.chartFilter) {
      data.chartFilter = selectionFilter?.chartFilter;
    }

    getDashboardData(data);
  };

  return (
    <>
      <span style={{ fontSize: "16px", fontWeight: 500 }}>Filters</span>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Select
            showSearch
            allowClear
            mode="multiple"
            value={filterStates?.customerList}
            onChange={handleCustomerFilter}
            placeholder="Select Customer"
            style={{ width: 200 }}
          >
            {customers?.map((customer) => (
              <Option key={customer?._id} value={customer?.name}>
                {customer?.name}
              </Option>
            ))}
          </Select>

          <Select
            showSearch
            allowClear
            mode="multiple"
            value={filterStates?.productList}
            onChange={handleProductFilter}
            placeholder="Select Product"
            style={{ width: 200 }}
          >
            {products?.map((product) => (
              <Option key={product?._id} value={product?.name}>
                {product?.name}
              </Option>
            ))}
          </Select>

          <DatePicker
            picker="year"
            value={filterStates?.filterYear}
            onChange={handleChangeYear}
          />

          <Select
            placeholder="Select month"
            style={{ width: 150 }}
            value={filterStates?.filterMonth}
            onChange={handleChangeMonth}
          >
            {monthsArrayList?.map((val, i) => (
              <Option key={val} value={i}>
                {val}
              </Option>
            ))}
          </Select>

          <Select
            placeholder="Select quarter"
            style={{ width: 250 }}
            value={quarterData?.selectedQuarter}
            onChange={handleChangeQuarter}
          >
            {quarterData.list?.map((val, i) => (
              <Option key={i} value={val?.value}>
                {val?.label}
              </Option>
            ))}
          </Select>

          <Button type="primary" onClick={applyFilters}>
            Apply Filters
          </Button>

          <span
            style={{
              fontSize: "14px",
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => handleClearFilters()}
          >
            Clear filters
          </span>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "65vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BarChart resultData={resultData} />
          <span style={{ fontSize: "18px" }}>
            (In {loggedUser?.presets?.potentialValue || ""})
          </span>
        </div>
      </div>
    </>
  );
};

export default SalesVsActual;
