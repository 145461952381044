import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { isLoggedInApi, logOutApi } from "../apis/auth";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  loggedUser: null,
  isAuthenticated: false,
  presetData: null,
};

// Reducers and Actions

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    isLogin(state) {
      state.isLoading = true;
      state.presetData = null;
    },
    isLoginSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.loggedUser = action.payload.data;
      state.success = action.payload.success;
    },
    isLoginFailed(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.loggedUser = null;
      state.success = action.payload.success;
      state.error = null;
    },
    logout(state) {
      state.isLoading = true;
    },
    logoutSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.loggedUser = null;
      state.success = action.payload.success;
    },
    logoutFailed(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.loggedUser = null;
      state.success = action.payload.success;
      state.error = action.payload.message;
    },
    presetDataAction(state, action) {
      state.isLoading = false;
      state.presetData = action.payload.data;
      state.success = false;
      state.error = null;
    },
  },
});

export const {
  actions: authActions,
  reducer: authReducer,
  name: authSliceKey,
} = authSlice;

// Create Selector
const selectDomain = (state) => state.auth || initialState;

export const selectAuth = createSelector(
  [selectDomain],
  (AuthState) => AuthState
);

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  (AuthState) => AuthState.isAuthenticated
);

export const selectLoggedUser = createSelector(
  [selectDomain],
  (AuthState) => AuthState.loggedUser
);

// Saga
export function* isLoggedInReq() {
  try {
    const res = yield isLoggedInApi();
    if (res.data.success) {
      yield put(authActions.isLoginSuccess(res.data));
    } else {
      yield put(authActions.isLoginFailed(res.data));
    }
  } catch (error) {
    yield put(authActions.isLoginFailed(error));
  }
}
export function* logoutReq() {
  try {
    const res = yield logOutApi();
    if (res.data.success) {
      window.sessionStorage.setItem("pathname", null);
      yield put(authActions.logoutSuccess(res.data));
      authActions.isLogin();
    } else {
      yield put(authActions.logoutFailed(res.data));
    }
  } catch (error) {
    yield put(authActions.logoutFailed(error));
  }
}
export function* authSaga() {
  yield takeLatest(authActions.isLogin.type, isLoggedInReq);
  yield takeLatest(authActions.logout.type, logoutReq);
}
