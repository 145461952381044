import { Select, Button, message } from "antd";
import React, { useState, useEffect } from "react";

import { getDashboardApi } from "../../redux/apis/dashboardApi";
import BarChart from "./BarChart";

const { Option } = Select;

const intialFilterState = {
  productList: [],
};

const CustomersReport = ({ loggedUser, selectionFilter, products }) => {
  const [resultData, setResultData] = useState(null);
  const [filterStates, setFilterStates] = useState(intialFilterState);

  // dasboard api call
  const getDashboardData = async (data) => {
    try {
      const res = await getDashboardApi(data);

      if (res?.data?.success) {
        setResultData(res?.data?.data);
      } else {
        message.error(res?.data?.message || "Something went wrong");
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (loggedUser && selectionFilter) {
      let data = {
        userId: loggedUser?._id,
        funnelId: loggedUser?.activeFunnel?._id || null,
      };

      if (selectionFilter?.chartFilter) {
        data.chartFilter = selectionFilter?.chartFilter;
      }

      getDashboardData(data);
    }
    // eslint-disable-next-line
  }, [loggedUser, selectionFilter]);

  const handleProductFilter = (product) => {
    setFilterStates({
      ...filterStates,
      productList: product,
    });
  };

  const applyFilters = () => {
    let data = {
      userId: loggedUser?._id,
      funnelId: loggedUser?.activeFunnel?._id || null,
    };

    if (selectionFilter?.chartFilter) {
      data.chartFilter = selectionFilter?.chartFilter;
    }

    if (filterStates.productList?.length > 0) {
      data.productList = filterStates.productList;
    }

    getDashboardData(data);
  };

  const handleClearFilters = () => {
    setFilterStates(intialFilterState);

    let data = {
      userId: loggedUser?._id,
      funnelId: loggedUser?.activeFunnel?._id || null,
    };

    if (selectionFilter?.chartFilter) {
      data.chartFilter = selectionFilter?.chartFilter;
    }

    getDashboardData(data);
  };

  return (
    <>
      <span style={{ fontSize: "16px", fontWeight: 500 }}>Filters</span>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Select
            showSearch
            allowClear
            mode="multiple"
            value={filterStates?.productList}
            onChange={handleProductFilter}
            placeholder="Select Product"
            style={{ width: 200 }}
          >
            {products?.map((product) => (
              <Option key={product?._id} value={product?.name}>
                {product?.name}
              </Option>
            ))}
          </Select>

          <Button type="primary" onClick={applyFilters}>
            Apply Filters
          </Button>

          <span
            style={{
              fontSize: "14px",
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => handleClearFilters()}
          >
            Clear filters
          </span>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "65vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BarChart resultData={resultData} />
          <span style={{ fontSize: "18px" }}>
            (In {loggedUser?.presets?.potentialValue || ""})
          </span>
        </div>
      </div>
    </>
  );
};

export default CustomersReport;
