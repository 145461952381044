import { ApartmentOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { PATH_DASHBOARD } from "../../routes/path";

const OrgChart = ({ selectedCustomer }) => {
  const navigate = useNavigate();

  const handleViewChart = () => {
    window.localStorage.removeItem("customerId");

    navigate(
      PATH_DASHBOARD.viewOrganizationChart(selectedCustomer.value || "")
    );
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Button
          disabled={selectedCustomer.isDisabled}
          onClick={() => handleViewChart()}
          type="primary"
          size="large"
          icon={<ApartmentOutlined />}
        >
          View Organization Chart
        </Button>
      </div>
    </>
  );
};

export default OrgChart;
