import React from "react";
import { Button, Row, Col, Dropdown, Menu } from "antd";
import { LeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { employeeActions, selectEmployee } from "../../redux/slices/employeeSlice";

export function OrgCharPagination() {
  const pageSizeOptions = ["10", "20", "50"];
  const employeeData = useSelector(selectEmployee);
  const dispatch = useDispatch();

  const Mymenu = (
    <Menu>
      {pageSizeOptions.map((e, i) => (
        <Menu.Item
          onClick={() => {
            dispatch(employeeActions.employeeListLimit({ limit: Number(e) }));
          }}
          key={i}
        >
          {e}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Row gutter={10}>
          <Col>
            <Button
              size="middle"
              disabled={employeeData.page <= 1}
              onClick={() => {
                dispatch(
                  employeeActions.employeeListPageLimit({
                    page: employeeData.page - 1,
                  })
                );
              }}
              icon={<LeftOutlined />}
            />
          </Col>
          <Col>
            <Button size="middle">{employeeData.page}</Button>
          </Col>
          <Col>
            <Button
              size="middle"
              disabled={!employeeData.isNext}
              onClick={() => {
                dispatch(
                  employeeActions.employeeListPageLimit({
                    page: employeeData.page + 1,
                  })
                );
              }}
              icon={<RightOutlined />}
            />
          </Col>
          <Col>
            <Dropdown overlay={Mymenu}>
              <Button size="middle">
                {employeeData.limit} <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default OrgCharPagination;
