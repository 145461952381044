import { Col, Radio, Row, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  addEditDeleteRightsManagementList,
  downloadRightsManagementList,
  viewOnlyRightsManagementList,
} from "../../utils/general-constant";
import { editRightsApi, getRoleApi } from "../../redux/apis/dashboardApi";

const RightsManagement = () => {
  const [radioVal, setRadioVal] = useState("userAdmin");
  const [rightsChecked, setRightsChecked] = useState({
    viewData: viewOnlyRightsManagementList,
    addEditDeleteData: addEditDeleteRightsManagementList,
    isDownload: [],
  });

  useEffect(() => {
    (async () => {
      if (radioVal) {
        try {
          let res = await getRoleApi(radioVal);

          if (res?.data?.success) {
            setRightsChecked((prevRightsChecked) => ({
              ...prevRightsChecked,
              addEditDeleteData:
                res?.data?.data?.addEditDeleteRights ||
                addEditDeleteRightsManagementList,
              viewData:
                res?.data?.data?.viewOnlyRights || viewOnlyRightsManagementList,
              isDownload: [],
              // isDownload:
              //   res?.data?.data?.downloadRights || downloadRightsManagementList,
            }));
          } else {
            message.error(res?.data?.message);
          }
        } catch (error) {
          message.error(
            error?.response?.data?.message || "Something went wrong"
          );
        }
      }
    })();
    // eslint-disable-next-line
  }, [radioVal]);

  const handleChangeValue = (e) => {
    setRadioVal(e.target.value);
  };

  const handleChangeCheckedData = async (event, dataItem, index) => {
    try {
      if (dataItem === "isDownload") {
        let oldData = [...rightsChecked.isDownload];

        if (oldData.length > 0) {
          oldData[index] = { ...oldData[index], isDownload: event };

          const submitData = {
            type: "isDownload",
            data: oldData,
            role: radioVal,
          };

          const res = await editRightsApi(submitData);

          if (res?.data?.success) {
            message.success(res?.data?.message);
            setRightsChecked({ ...rightsChecked, isDownload: oldData });
          } else {
            message.error(res?.data?.message);
            setRightsChecked({ ...rightsChecked });
          }
        }
      }

      if (dataItem === "isView") {
        let oldData = [...rightsChecked.viewData];

        if (oldData.length > 0) {
          oldData[index] = { ...oldData[index], isView: event };

          const submitData = {
            type: "isView",
            data: oldData,
            role: radioVal,
          };

          const res = await editRightsApi(submitData);

          if (res?.data?.success) {
            message.success(res?.data?.message);
            setRightsChecked({ ...rightsChecked, viewData: oldData });
          } else {
            message.error(res?.data?.message);
            setRightsChecked({ ...rightsChecked });
          }
        }
      }

      if (dataItem === "isAddEditDelete") {
        let oldData = [...rightsChecked.addEditDeleteData];

        if (oldData.length > 0) {
          oldData[index] = { ...oldData[index], isAddEditDelete: event };

          const submitData = {
            type: "isAddEditDelete",
            data: oldData,
            role: radioVal,
          };

          const res = await editRightsApi(submitData);

          if (res?.data?.success) {
            message.success(res?.data?.message);
            setRightsChecked({ ...rightsChecked, addEditDeleteData: oldData });
          } else {
            message.error(res?.data?.message);
            setRightsChecked({ ...rightsChecked });
          }
        }
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message ?? "Something went wrong");
    }
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ fontWeight: 500 }}>Manage Rights</h2>

        <div>
          <Radio.Group
            defaultValue="userAdmin"
            size="large"
            buttonStyle="solid"
            value={radioVal}
            onChange={handleChangeValue}
          >
            <Radio.Button value="userAdmin">Manager Rights</Radio.Button>
            <Radio.Button value="subUser">User Rights</Radio.Button>
          </Radio.Group>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <div
            style={{
              border: "1px solid #c3c3c3",
              marginTop: "2rem",
              padding: "5px 7px",
              borderRadius: "8px",
              width: "300px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
                fontWeight: 600,
                color: "#1677ff",
              }}
            >
              Add/Edit/Delete Rights
            </div>
            <div>
              {rightsChecked?.addEditDeleteData?.map((item, index) => (
                <>
                  <Row
                    key={index}
                    gutter={16}
                    style={{
                      borderTop: "1px solid #c3c3c3",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                  >
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <span>{item?.label}</span>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Switch
                        checked={item?.isAddEditDelete}
                        onChange={(e) =>
                          handleChangeCheckedData(e, "isAddEditDelete", index)
                        }
                      />
                    </Col>
                  </Row>
                </>
              ))}
            </div>
          </div>

          <div
            style={{
              border: "1px solid #c3c3c3",
              marginTop: "2rem",
              padding: "5px 7px",
              borderRadius: "8px",
              width: "300px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
                fontWeight: 600,
                color: "#1677ff",
              }}
            >
              View Only Rights
            </div>
            <div>
              {rightsChecked?.viewData?.map((item, index) => (
                <>
                  <Row
                    key={index}
                    gutter={16}
                    style={{
                      borderTop: "1px solid #c3c3c3",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                  >
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <span>{item?.label}</span>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Switch
                        checked={item?.isView}
                        onChange={(e) =>
                          handleChangeCheckedData(e, "isView", index)
                        }
                      />
                    </Col>
                  </Row>
                </>
              ))}
            </div>
          </div>
        </div>

        {rightsChecked?.isDownload?.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                border: "1px solid #c3c3c3",
                marginTop: "10px",
                padding: "5px 7px",
                borderRadius: "8px",
                width: "300px",
                textAlign: "center",
              }}
            >
              {rightsChecked?.isDownload?.map((item, index) => (
                <>
                  <Row key={index} gutter={16}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <span>{item?.label}</span>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Switch
                        checked={item?.isDownload}
                        onChange={(e) =>
                          handleChangeCheckedData(e, "isDownload", index)
                        }
                      />
                    </Col>
                  </Row>
                </>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RightsManagement;
