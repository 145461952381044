import React, { useState } from "react";
import { Outlet } from "react-router-dom";

// antd
import { Button, Layout, Tooltip } from "antd";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import HeaderComponent from "../header/HeaderComponent";
import Navigation from "../navigation/Navigation";

const { Sider, Content, Header } = Layout;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout
      style={{
        padding: 0,
        marginLeft: "-8px",
        marginRight: "-8px",
        marginTop: "-8px",
      }}
    >
      <Header
        style={{
          backgroundColor: "#bdbdbb",
          position: "sticky",
          top: 0,
          zIndex: 999,
          padding: 0,
          lineHeight: 0,
          boxShadow:
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
        }}
      >
        <HeaderComponent />
      </Header>
      <Layout>
        <Sider
          width={270}
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            // overflow: 'auto',
            // height: '90vh',
            // position: 'fixed',
            // left: 0,
            // top: 60,
            // bottom: 0,
            position: "relative",
          }}
        >
          <Tooltip
            title={collapsed ? "Expand" : "Collapse"}
            arrow={false}
            placement="right"
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="text"
                style={{
                  width: 25,
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
                icon={
                  collapsed ? (
                    <RightOutlined
                      style={{
                        fontSize: "12px",
                      }}
                    />
                  ) : (
                    <LeftOutlined
                      style={{
                        fontSize: "12px",
                      }}
                    />
                  )
                }
                onClick={() => setCollapsed(!collapsed)}
              />
            </div>
          </Tooltip>
          <div
            style={{
              margin: "19px 0px 0px 0px",
            }}
          >
            <Navigation setCollapsed={setCollapsed} />
          </div>
        </Sider>

        <Layout
          style={{
            margin: "12px 20px",
            minHeight: "86vh",
          }}
        >
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Sidebar;
