import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./failed.css";
import LottieAnimation from "../../../utils/shared/lottieAnimation";
import { PATH_DASHBOARD } from "../../../routes/path";
import failedData from "../../../assets/lottie/failed.json";

const FailurePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="container">
        <div className="message-box">
          <div className="success-animation">
            <LottieAnimation animationData={failedData} />
          </div>
          <h2> Your payment failed </h2>
          <p> Try again later </p>
          <Button
            onClick={() => navigate(PATH_DASHBOARD.subscriptions)}
            style={{ marginTop: "20px" }}
            type="primary"
          >
            Go To Subcription
          </Button>
        </div>
      </div>
    </>
  );
};

export default FailurePage;
