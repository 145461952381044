import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// antd
import { Menu } from "antd";
import {
  ClusterOutlined,
  ControlOutlined,
  DashboardOutlined,
  FilterOutlined,
  FundOutlined,
  InteractionOutlined,
  LineChartOutlined,
  SafetyOutlined,
  ShoppingOutlined,
  TeamOutlined,
  ToolOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { useSelector } from "react-redux";

const Navigation = ({ setCollapsed }) => {
  const nav = useNavigate();
  const { pathname } = useLocation();

  const [selected, setSelected] = useState();

  const loggedUser = useSelector(selectLoggedUser);

  const navigate = async (key) => {
    if (pathname.split("/")[2] !== key || pathname.split("/")[3]) {
      nav("/app/" + key);
    }
  };

  useEffect(() => {
    setSelected(pathname.split("/")[2]);
  }, [pathname]);

  let navbarItems = [
    {
      key: "dashboard",
      label: "Dashboard",
      icon: <DashboardOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "customer",
      label: "Customers Files",
      icon: <TeamOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "product",
      label: "Products",
      icon: <ShoppingOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "presets",
      label: "Presets",
      icon: <ControlOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "funnel-row",
      label: "Funnels",
      icon: <FilterOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "potential-map",
      label: "Potential Mapped",
      icon: <InteractionOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "sales-vs-actual",
      label: "Sales Plan & Achievements",
      icon: <LineChartOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "organization-chart",
      label: "Organization Charts",
      icon: <ClusterOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "progress-report",
      label: "Progress Report",
      icon: <FundOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "management",
      label: "Managements",
      icon: <SafetyOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "profile",
      label: "Settings",
      icon: <ToolOutlined />,
      disabled: loggedUser?.isTrialAttemptExpired,
    },
    {
      key: "subscriptions",
      label: "Subcriptions",
      icon: <WalletOutlined />,
      disabled: false,
    },
  ];

  const [items, setItems] = useState(navbarItems);

  useEffect(() => {
    if (loggedUser) {
      let newNavbarItems = [...navbarItems];

      if (
        // loggedUser?.viewOnlyRights?.length > 0 &&
        // loggedUser?.viewOnlyRights
        //   ?.filter((v) => v.value === "preset")
        //   .some((val) => val?.isView === true)
        loggedUser?.role?.roleName === "superUser"
      ) {
        newNavbarItems = [...newNavbarItems];
      } else {
        newNavbarItems = newNavbarItems.filter((v) => v?.key !== "presets");
      }

      if (
        loggedUser?.viewOnlyRights?.length > 0 &&
        loggedUser?.viewOnlyRights
          ?.filter((v) => v.value === "charts")
          .some((val) => val?.isView === true)
      ) {
        newNavbarItems = [...newNavbarItems];
      } else {
        newNavbarItems = newNavbarItems.filter((v) => v?.key !== "dashboard");
      }

      if (
        loggedUser?.viewOnlyRights?.length > 0 &&
        loggedUser?.viewOnlyRights
          ?.filter((v) => v.value === "funnel")
          .some((val) => val?.isView === true)
      ) {
        newNavbarItems = [...newNavbarItems];
      } else {
        newNavbarItems = newNavbarItems.filter((v) => v?.key !== "funnel-row");
      }

      if (
        loggedUser?.viewOnlyRights?.length > 0 &&
        loggedUser?.viewOnlyRights
          ?.filter((v) => v.value === "customerFiles")
          .some((val) => val?.isView === true)
      ) {
        newNavbarItems = [...newNavbarItems];
      } else {
        newNavbarItems = newNavbarItems.filter((v) => v?.key !== "customer");
      }

      if (
        loggedUser?.viewOnlyRights?.length > 0 &&
        loggedUser?.viewOnlyRights
          ?.filter((v) => v.value === "products")
          .some((val) => val?.isView === true)
      ) {
        newNavbarItems = [...newNavbarItems];
      } else {
        newNavbarItems = newNavbarItems.filter((v) => v?.key !== "product");
      }

      if (loggedUser?.role?.roleName === "superUser") {
        newNavbarItems = [...newNavbarItems];
      } else {
        newNavbarItems = newNavbarItems.filter((v) => v?.key !== "management");
        newNavbarItems = newNavbarItems.filter(
          (v) => v?.key !== "subscriptions"
        );
      }

      setItems(newNavbarItems);
    }
    // eslint-disable-next-line
  }, [loggedUser]);

  useEffect(() => {
    if (pathname.includes("funnel-row")) {
      setCollapsed(true);
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (loggedUser?.isTrialAttemptExpired) {
      navigate("subscriptions");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Menu
        theme="dark"
        mode="inline"
        // items={items}
        style={{ fontSize: 16 }}
        selectedKeys={[selected]}
        // disabled={items}
        onClick={({ key }) => {
          navigate(key);
          setSelected(key);
        }}
      >
        {items.map((item) => (
          <Menu.Item key={item.key} icon={item.icon} disabled={item.disabled}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>

      {loggedUser?.reportingTo &&
        loggedUser?.role?.roleName !== "superUser" && (
          <div
            style={{
              color: "#c8c8c8",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
              border: "0.5px solid #c8c8c8",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <span>Reporting To </span>
            <span>
              {Array.isArray(loggedUser?.reportingTo)
                ? `${loggedUser?.reportingTo[0]?.firstName} ${loggedUser?.reportingTo[0]?.lastName}`
                : "-"}
            </span>
          </div>
        )}
    </>
  );
};

export default Navigation;
