import { Col, Form, Input, Row, Button, message } from "antd";
import React, { useState } from "react";
import { changePassword } from "../../redux/apis/dashboardApi";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { useSelector } from "react-redux";

const rules = {
  currentPassword: [
    {
      required: true,
      message: "Please Enter Current Password",
    },
    {
      pattern: /^(?=.*[A-Za-z0-9]).{6,}$/,
      message: "Password must be 6 or more character",
    },
  ],

  newPassword: [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (/^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{6,}$/.test(value)) {
          return Promise.resolve();
        } else if (value.length < 6) {
          return Promise.reject(
            "Password should be at least 6 characters long"
          );
        } else if (!/(?=.*[A-Z])/.test(value)) {
          return Promise.reject(
            "Password should contain at least one uppercase letter"
          );
        } else if (!/(?=.*[!@#$%^&*])/.test(value)) {
          return Promise.reject(
            "Password should contain at least one special character"
          );
        } else if (!/(?=.*[0-9])/.test(value)) {
          return Promise.reject("Password should contain at least one digit");
        }
      },
    }),
  ],

  confirmNewPassword: [
    {
      required: true,
      message: "Please Enter Confirm New Password",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("newPassword") === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          "New Password and Confirm New Password must be same"
        );
      },
    }),
  ],
};

const ChangePassword = () => {
  const [passwordForm] = Form.useForm();

  const loggedUser = useSelector(selectLoggedUser);

  const [submitLoading, setSubmitLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      const data = {
        currentPassword: values?.currentPassword,
        newPassword: values?.newPassword,
        confirmNewPassword: values?.confirmNewPassword,
      };

      setSubmitLoading(true);
      const res = await changePassword(loggedUser?._id, data);
      if (res.data?.success) {
        message.success(res.data?.message);

        setTimeout(() => {
          passwordForm.resetFields();
          setSubmitLoading(false);
        }, 1000);
      } else {
        setSubmitLoading(false);
        message.error(res.data?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.error(error?.response.data?.message);
    }
  };

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Form
          name="passwordForm"
          form={passwordForm}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    label="Current Password"
                    name="currentPassword"
                    rules={rules.currentPassword}
                  >
                    <Input.Password placeholder="Current Password" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={rules.newPassword}
                  >
                    <Input.Password placeholder="New Password" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    rules={rules.confirmNewPassword}
                  >
                    <Input.Password placeholder="Confirm New Password" />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{ marginTop: "20px" }}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={submitLoading}
                >
                  Change Password
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
