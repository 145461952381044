import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import "../../pages/Product/product.css";

const rules = {
  name: [
    {
      required: true,
      message: "Please Enter Name",
    },
  ],
  category: [
    {
      required: true,
      message: "Please Enter Category",
    },
  ],
  productDetails: [
    {
      required: true,
      message: "Please Enter Product Details",
    },
  ],
  price: [
    {
      required: true,
      message: "Please Enter Price",
    },
  ],
};

const AddEditProduct = ({ isEdit, isSubmit, handleCancle }) => {
  return (
    <>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item name="name" label="Product Name" rules={rules.name}>
            <Input placeholder="Product Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item name="category" label="Category" rules={rules.category}>
            <Input placeholder="Category" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item
            name="productDetails"
            label="Product Details And Specifications"
            rules={rules.productDetails}
          >
            <Input.TextArea
              placeholder="Product Details And Specifications"
              rows={3}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={20} lg={12} xl={12}>
          <Form.Item name="price" label="Price" rules={rules.price}>
            <Input
              style={{ width: "100%" }}
              placeholder="Price"
              className="hide-arrows"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={12} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Col span={6}>
          <Button
            onClick={handleCancle}
            style={{ width: "100%" }}
            type="default"
          >
            Cancel
          </Button>
        </Col>
        <Col span={6}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            loading={isSubmit}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AddEditProduct;
