import { Modal, Switch } from "antd";
import React from "react";

const ClearAllDataModal = ({ state, setState, handleClearAllData }) => {
  const hideModal = () => {
    setState((Pre) => ({
      ...Pre,
      clearAllDemoData: true,
      clearAllDemoDataModal: false,
    }));
  };

  const handleChangeSwitch = (event) => {
    setState((Pre) => ({
      ...Pre,
      clearAllDemoData: event,
    }));
  };

  return (
    <>
      <Modal
        title="Delete All Demo Data"
        open={state.clearAllDemoDataModal}
        onOk={handleClearAllData}
        onCancel={hideModal}
        closable
        okText="Confirm & Continue"
        cancelText="Cancel"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4 style={{ marginRight: "10px" }}>Clear All Demo Data: </h4>
          <span>
            {" "}
            <Switch
              checked={state?.clearAllDemoData}
              onChange={handleChangeSwitch}
            />{" "}
          </span>
        </div>

        <p style={{ color: "red", fontSize: "14px", margin: "10px 0" }}>
          <strong>Warning:</strong> This action will permanently delete all demo
          data and cannot be undone. Please proceed with caution.
        </p>
      </Modal>
    </>
  );
};

export default ClearAllDataModal;
