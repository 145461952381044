import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

const SuccessAnimation = ({
  animationData = null,
  height = "fit-content",
  width = "200px",
}) => {
  const container = useRef(null);

  useEffect(() => {
    if (animationData) {
      lottie.loadAnimation({
        container: container.current,
        animationData: animationData,
        renderer: "svg",
        loop: true,
        autoplay: true,
      });
    }
  }, [animationData]);

  return <div style={{ height, width }} ref={container}></div>;
};

export default SuccessAnimation;
