import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
  discount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
    color: "red",
  },
});

const InvoiceTableFooter = ({ state, subcriptionData }) => {
  let subTotal = state?.invoiceData?.isMainLicense
    ? (
        state.invoiceData?.mainLicense * subcriptionData?.viewData?.perUserPrice
      ).toFixed(2)
    : (
        state.invoiceData?.subLicense * subcriptionData?.viewData?.perUserPrice
      ).toFixed(2);

  subTotal = state?.invoiceData?.setupFees ? Number(subTotal) * 2 : subTotal;

  let gstWithoutAmount = Number(subTotal) / 1.18;

  return (
    <>
      <View style={styles.row}>
        <Text style={styles.description}>Sub Total</Text>
        <Text style={styles.total}>{gstWithoutAmount?.toFixed(2) || "0"}</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.description}>GST (18%)</Text>
        <Text style={styles.total}>
          {subTotal ? (Number(subTotal) - gstWithoutAmount).toFixed(2) : "0"}
        </Text>
      </View>

      {state?.invoiceData?.setupFees && (
        <View style={styles.row}>
          <Text style={styles.description}>Setup Fee</Text>
          <Text style={styles.total}>
            {" "}
            {state?.invoiceData?.setupFees || "0"}
          </Text>
        </View>
      )}

      {!state?.invoiceData?.setupFees && state?.invoiceData?.isRenewal && (
        <View style={styles.row}>
          <Text style={styles.description}>Renewal Off</Text>
          <Text style={styles.discount}>
            {" "}
            {state?.invoiceData?.amount
              ? "-" + (state?.invoiceData?.amount * 0.2).toFixed(2)
              : "0"}
          </Text>
        </View>
      )}

      {state?.invoiceData?.coupon?.amt && (
        <View style={styles.row}>
          <Text style={styles.description}>Discount</Text>
          <Text style={styles.discount}>
            {state?.invoiceData?.coupon?.amt
              ? `- ${state?.invoiceData?.coupon?.amt?.toFixed(2)}`
              : "0"}
          </Text>
        </View>
      )}

      <View style={styles.row}>
        <Text style={styles.description}>Total</Text>
        <Text style={styles.total}>
          {state?.invoiceData?.amount
            ? state?.invoiceData?.amount?.toFixed(2)
            : "0"}
        </Text>
      </View>
    </>
  );
};

export default InvoiceTableFooter;
