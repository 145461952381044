import { Card, Col, Form, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import AddEditFunnelRow from "../../sections/FunnelRowManage/AddEditFunnelRow";
import { PATH_DASHBOARD } from "../../routes/path";
import { addFunnelRowApi } from "../../redux/apis/dashboardApi";

const AddFunnelRow = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [isEdit, setIsEdit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [addEditFunnelRowForm] = Form.useForm();

  useEffect(() => {
    if (location) {
      if (location.pathname.includes("edit")) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
      }
    }
  }, [location]);

  const handleCancle = () => {
    navigate(-1);
  };

  const onFinish = async (values) => {
    try {
      setIsSubmit(true);
      const data = {
        ...values,
        conversionTimeline: new Date(
          values?.conversionTimeline["$d"]
        ).getTime(),
        funnelId: params?.id,
      };

      const addRes = await addFunnelRowApi(data);

      if (addRes.data.success) {
        message.success(addRes.data.message);
        setIsSubmit(false);
        setTimeout(() => {
          navigate(PATH_DASHBOARD.listFunnelRow(params?.id));
        }, 1000);
      } else {
        setIsSubmit(false);
        message.error(addRes.data.message);
      }
    } catch (error) {
      setIsSubmit(false);
      message.error(error?.response.data?.message);
    }
  };

  return (
    <>
      <Card title="Add Funnel Row" style={{ marginTop: "20px" }}>
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={24} sm={24} md={20} lg={12} xl={18}>
              <div className="mt-4">
                <Form
                  name="addEditFunnelRowForm"
                  layout="vertical"
                  form={addEditFunnelRowForm}
                  onFinish={onFinish}
                >
                  <AddEditFunnelRow
                    isEdit={isEdit}
                    isSubmit={isSubmit}
                    handleCancle={handleCancle}
                  />
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default AddFunnelRow;
