import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { funnelRowListApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  list: [],
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
  funnelId: null,
  userId: null,
  isDelete: false,
  isCarryForward: false,
  stageFilter: "All",
};

const funnelRowSlice = createSlice({
  name: "funnelRows",
  initialState,
  reducers: {
    funnelRowList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.searchText = "";
      state.isNext = false;
      state.funnelId = action.payload.funnelId;
      state.userId = action.payload.userId;
      state.isDelete = action.payload.isDelete;
      state.isCarryForward = action.payload.isCarryForward;
      state.stageFilter = action.payload.stageFilter;
    },
    funnelRowListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
    },
    funnelRowListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    funnelRowListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
      state.funnelId = action.payload.funnelId;
    },
    funnelRowListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
      state.funnelId = action.payload.funnelId;
    },
    funnelRowSearch(state, action) {
      state.searchText = action.payload.searchText;
      state.page = 1;
      state.limit = 10;
      state.funnelId = action.payload.funnelId;
    },
  },
});

export const {
  actions: funnelRowActions,
  reducer: funnelRowReducer,
  name: funnelRowKey,
} = funnelRowSlice;

const selectDomain = (state) => state.funnelRows || initialState;

export const selectFunnelRow = createSelector(
  [selectDomain],
  (funnelRowState) => funnelRowState
);

export const getState = (state) => state.funnelRows;

export function* funnelRowListReq() {
  try {
    const states = yield select(getState);
    const res = yield funnelRowListApi(states);

    if (res.data.success) {
      yield put(funnelRowActions.funnelRowListSuccess(res.data));
    } else {
      yield put(funnelRowActions.funnelRowListFailed(res.data));
    }
  } catch (error) {
    yield put(funnelRowActions.funnelRowListFailed(error));
  }
}

export function* funnelRowSaga() {
  yield takeLatest(funnelRowActions.funnelRowList.type, funnelRowListReq);
  yield takeLatest(
    funnelRowActions.funnelRowListPageLimit.type,
    funnelRowListReq
  );
  yield takeLatest(funnelRowActions.funnelRowListLimit.type, funnelRowListReq);
  yield takeLatest(funnelRowActions.funnelRowSearch.type, funnelRowListReq);
}
