import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Table, Tooltip } from "antd";
import {
  CheckCircleFilled,
  FileTextFilled,
  PlusCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import "./subscriptions.css";
import { PATH_DASHBOARD } from "../../routes/path";
import {
  selectSubcription,
  subcriptionActions,
} from "../../redux/slices/subcriptionSlice";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import dayjs from "dayjs";
import ClearAllDataModal from "./ClearAllDataModal";

const initialState = {
  total: 0,
  clearAllDemoData: true,
  clearAllDemoDataModal: false,
  row: null,
};

const Subscriptions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subscriptionsReducerData = useSelector(selectSubcription);
  const loggedUser = useSelector(selectLoggedUser);

  const [subscriptionData, setSubscriptionData] = useState(null);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    dispatch(
      subcriptionActions.activeSubcriptionList({ superUserId: loggedUser?._id })
    );
    // eslint-disable-next-line
  }, []);

  const LicenseTooltip = ({ row }) => {
    return (
      <>
        <div>
          {row?.licenseIds &&
            row?.licenseIds?.map((lic) => (
              <p style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{lic?.licId}</span>
              </p>
            ))}
        </div>
      </>
    );
  };

  const columns = [
    {
      title: "No of Licenses subscribed",
      dataIndex: "noOfLicenses",
      align: "center",
      render: (val, elm) => {
        let totalLicense = 0;

        if (elm?.isMainLicense) {
          totalLicense = elm?.mainLicense;
        } else {
          totalLicense = elm?.initialSubLicense;
        }

        return (
          <>
            <Tooltip title={<LicenseTooltip row={elm} />}>
              <div style={{ fontSize: "16px", cursor: "pointer" }}>
                <span>{totalLicense}</span>
              </div>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Subscription Start Date",
      dataIndex: "subscriptionDate",
      align: "center",
      render: (val, elm) => {
        return (
          <>
            <div style={{ fontSize: "16px" }}>
              <span>
                {elm?.startSubcription
                  ? dayjs(elm?.startSubcription).format("DD-MM-YYYY")
                  : null}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Subscription End Date",
      dataIndex: "endSubcription",
      align: "center",
      render: (val, elm) => {
        return (
          <>
            <div style={{ fontSize: "16px" }}>
              <span>
                {elm?.endSubcription
                  ? dayjs(elm?.endSubcription).format("DD-MM-YYYY")
                  : null}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Day left to renew",
      dataIndex: "dayLeft",
      align: "center",
      render: (val, elm) => {
        let currentDate = dayjs(new Date()).startOf("day");
        const targetDate = dayjs(elm?.endSubcription);
        const leftDay = targetDate.diff(currentDate, "day");

        return (
          <>
            <div style={{ fontSize: "16px" }}>
              {leftDay > 10 ? (
                <span> {leftDay}</span>
              ) : (
                <span style={{ color: "#ff4d4f", fontWeight: 700 }}>
                  {leftDay < 0 ? 0 : leftDay}
                </span>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "View Invoice",
      dataIndex: "invoice",
      align: "center",
      render: (val, elm) => {
        return (
          <>
            <div
              onClick={() => handleViewInvoice(elm)}
              style={{ fontSize: "18px", color: "#37b4de", cursor: "pointer" }}
            >
              <FileTextFilled />
            </div>
          </>
        );
      },
    },
    {
      title: "Renewal",
      dataIndex: "renewal",
      align: "center",
      render: (val, elm) => {
        let currentDate = dayjs(new Date()).startOf("day");
        const targetDate = dayjs(elm?.endSubcription);
        const leftDay = targetDate.diff(currentDate, "day");

        // const updatedDate = elm?.updatedAt
        //   ? dayjs(elm?.updatedAt).get("date")
        //   : 0;

        let isDisabled =
          (leftDay < 10 || loggedUser?.accountType === "demo") &&
          elm?.isMainLicense === false &&
          loggedUser?.isTrialAttemptExpired
            ? true
            : false;

        return (
          <>
            {elm?.status === "approved" ? (
              <Button
                type="primary"
                danger
                disabled={isDisabled}
                onClick={() => handleRenewal(elm)}
              >
                {loggedUser?.accountType === "demo" ? "Get Premium" : "Renewal"}
              </Button>
            ) : (
              <Button
                type="primary"
                style={{
                  backgroundColor: "#f5d16e",
                  color: "#b08409",
                  border: "none",
                }}
                onClick={() => handleCreateSubcription(elm)}
              >
                Retry
              </Button>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (subscriptionsReducerData) {
      setSubscriptionData(subscriptionsReducerData?.activeList);
      setState({
        ...state,
        total: subscriptionsReducerData?.totalActiveSubcriptions || 0,
      });
    }
    // eslint-disable-next-line
  }, [subscriptionsReducerData]);

  const handleViewInvoice = (row) => {
    navigate(PATH_DASHBOARD.mainInvoiceFile(row?._id));
  };

  const handleRenewal = (row) => {
    let license = 0;

    if (loggedUser?.accountType === "demo") {
      setState((Pre) => ({
        ...Pre,
        row,
        clearAllDemoDataModal: true,
      }));
    } else {
      if (loggedUser?.accountType === "demo") {
        window.localStorage.setItem("clearDemoData", state.clearAllDemoData);
        license = subscriptionData.reduce((total, subscription) => {
          const { mainLicense, subLicense } = subscription;
          return total + mainLicense + subLicense;
        }, 0);
      } else {
        license = row?.isMainLicense ? row?.mainLicense : row?.subLicense;
      }

      navigate(
        `${PATH_DASHBOARD.renewalSubscription}?license=${license}&subId=${row?._id}&isMainLicense=${row?.isMainLicense}&status=${row?.status}`
      );
    }
  };

  const handleClearAllData = () => {
    let row = state.row;

    let license = 0;

    if (loggedUser?.accountType === "demo") {
      window.localStorage.setItem("clearDemoData", state.clearAllDemoData);
      license = subscriptionData.reduce((total, subscription) => {
        const { mainLicense, subLicense } = subscription;
        return total + mainLicense + subLicense;
      }, 0);
    } else {
      license = row?.isMainLicense ? row?.mainLicense : row?.subLicense;
    }

    navigate(
      `${PATH_DASHBOARD.renewalSubscription}?license=${license}&subId=${row?._id}&isMainLicense=${row?.isMainLicense}&status=${row?.status}`
    );
  };

  const handleCreateSubcription = (row) => {
    let license = row?.isMainLicense ? row?.mainLicense : row?.subLicense;

    navigate(
      `${PATH_DASHBOARD.renewalSubscription}?license=${license}&subId=${row?._id}&isMainLicense=${row?.isMainLicense}&status=${row?.status}`
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            background: "#37b4de",
            color: "#fff",
            width: "fit-content",
            fontWeight: 500,
            padding: "10px 20px",
            borderRadius: "10px",
          }}
        >
          Total Active Users Licenses: {state?.total}
        </h2>

        <div style={{ display: "flex", gap: "10px" }}>
          <div>
            <Button
              size="large"
              type="primary"
              onClick={() => navigate(PATH_DASHBOARD.management)}
              disabled={loggedUser?.isTrialAttemptExpired}
            >
              Roles
            </Button>
          </div>
          <div>
            <Button
              size="large"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => navigate(PATH_DASHBOARD.subscriptionPrice)}
              disabled={
                loggedUser?.isTrialAttemptExpired ||
                loggedUser?.accountType === "demo"
              }
            >
              Add Subscription
            </Button>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3>Super Admin Subscription</h3>
        <Table
          className="sub-table"
          style={{ fontWeight: 600, fontSize: "16px" }}
          bordered
          columns={columns}
          dataSource={
            subscriptionData &&
            subscriptionData?.filter((val) => val?.isMainLicense)
          }
          pagination={false}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3>Admins or Users Subscriptions</h3>

        <Table
          className="sub-table"
          style={{ fontWeight: 600, fontSize: "16px" }}
          bordered
          columns={columns}
          dataSource={
            subscriptionData &&
            subscriptionData?.filter((val) => !val?.isMainLicense)
          }
          pagination={{
            pageSize: 5,
          }}
        />
      </div>

      <div
        style={{
          fontSize: "18px",
          color: "#37b4de",
          fontWeight: 600,
          fontStyle: "italic",
          marginTop: "20px",
          marginLeft: "6rem",
        }}
      >
        {loggedUser?.accountType === "demo" && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <span style={{ color: "orange", fontSize: "20px" }}>
                <WarningOutlined />
              </span>
              <span style={{ color: "orange" }}>Demo Account</span>
            </div>
          </>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          <span style={{ color: "green", fontSize: "20px" }}>
            <CheckCircleFilled />
          </span>
          <span>
            Intially minimum 2 licenses will be required for premium activation.
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <span style={{ color: "green", fontSize: "20px" }}>
            <CheckCircleFilled />
          </span>
          <span>Renewal charges are 80% of total users cost.</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <span style={{ color: "green", fontSize: "20px" }}>
            <CheckCircleFilled />
          </span>
          <span>Set up cost is for one time charge only.</span>
        </div>
      </div>

      <ClearAllDataModal
        state={state}
        setState={setState}
        handleClearAllData={handleClearAllData}
      />
    </>
  );
};

export default Subscriptions;
