import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import {
  getPaymentLicenseIdsApi,
  getUserLicenseListApi,
  getUserpriceApi,
} from "../apis/dashboardApi";
import { message } from "antd";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  viewData: null,
  userId: null,
  activeList: [],
  superUserId: null,
  totalActiveSubcriptions: 0,
  licenseIds: [],
};

const subcriptionSlice = createSlice({
  name: "subcription",
  initialState,
  reducers: {
    subcription(state, action) {
      state.isLoading = true;
      state.superUserId = null;
      state.activeList = [];
      state.totalActiveSubcriptions = 0;
    },
    subcriptionSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload.success;
      state.viewData = action.payload.data;
    },
    subcriptionFailed(state, action) {
      state.isLoading = false;
      state.success = action.payload.success;
      state.viewData = null;
    },
    activeSubcriptionList(state, action) {
      state.isLoading = true;
      state.success = false;
      state.activeList = [];
      state.superUserId = action.payload.superUserId;
    },
    activeSubcriptionListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeList = action.payload.data.list;
      state.totalActiveSubcriptions = action.payload.data.totalActiveLicense;
    },
    activeSubcriptionListFailed(state, action) {
      state.isLoading = true;
      state.success = false;
      state.activeList = [];
      state.superUserId = action.payload.superUserId;
      state.totalActiveSubcriptions = 0;
    },
    licenseIds(state, action) {
      state.userId = action.payload.userId;
    },
    licenseIdsSuccess(state, action) {
      state.licenseIds = action.payload.data;
      state.success = true;
    },
    licenseIdsFailed(state, action) {
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  actions: subcriptionActions,
  reducer: subcriptionReducer,
  name: subcriptionName,
} = subcriptionSlice;

const selectDomain = (state) => state.subcription || initialState;

export const selectSubcription = createSelector(
  [selectDomain],
  (satte) => satte
);

export const getState = (state) => state.subcription;

export function* subcriptionReq() {
  try {
    const res = yield getUserpriceApi();

    if (res.data.success) {
      yield put(subcriptionActions.subcriptionSuccess(res.data));
    } else {
      yield put(subcriptionActions.subcriptionFailed(res.data));
    }
  } catch (error) {
    yield put(subcriptionActions.subcriptionFailed(error));
  }
}

export function* subscriptionListReq() {
  try {
    const states = yield select(getState);

    const res = yield getUserLicenseListApi(states);

    if (res.data.success) {
      yield put(subcriptionActions.activeSubcriptionListSuccess(res.data));
    } else {
      message.error(res?.data?.message);
      yield put(subcriptionActions.activeSubcriptionListFailed(res.data));
    }
  } catch (error) {
    message.error(error?.response?.data?.message || "Something went wrong");
    yield put(subcriptionActions.activeSubcriptionListFailed(error));
  }
}

export function* licenseIdsReq() {
  try {
    const states = yield select(getState);

    const res = yield getPaymentLicenseIdsApi(states);

    if (res.data.success) {
      yield put(subcriptionActions.licenseIdsSuccess(res.data));
    } else {
      message.error(res?.data?.message);
      yield put(subcriptionActions.licenseIdsFailed(res.data));
    }
  } catch (error) {
    message.error(error?.response?.data?.message || "Something went wrong");
    yield put(subcriptionActions.licenseIdsFailed(error));
  }
}

export function* subcriptionSaga() {
  yield takeLatest(subcriptionActions.subcription.type, subcriptionReq);
  yield takeLatest(
    subcriptionActions.activeSubcriptionList.type,
    subscriptionListReq
  );
  yield takeLatest(subcriptionActions.licenseIds.type, licenseIdsReq);
}
