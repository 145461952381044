import {
  Form,
  // Upload,
  // Select,
  Button,
  Modal,
  Row,
  Col,
  Divider,
  Tooltip,
  Input,
  DatePicker,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React from "react";
// import { productActions, selectProduct } from "../../redux/slices/productSlice";
// import { selectLoggedUser } from "../../redux/slices/authSlice";
// import { useDispatch, useSelector } from "react-redux";
// import uniqueArrayList from "../../utils/UniqueArrayList";
// import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import dayjs from "dayjs";

// const { Option } = Select;

const AddOrderValuesForm = ({
  open,
  setOpen,
  formDefine,
  handleSubmit,
  submitLoading,
  rowData,
}) => {
  // const dispatch = useDispatch();

  // const productData = useSelector(selectProduct);
  // const loggedUser = useSelector(selectLoggedUser);

  // const [products, setProducts] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const disabledDate = (current) => {
    return (
      (current && current < dayjs(oneYearAgo)) ||
      (current && current > dayjs().endOf("day"))
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     productActions.productList({
  //       limit: 10,
  //       page: currentPage,
  //       funnelId: loggedUser?.activeFunnel?._id,
  //     })
  //   );
  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   if (productData) {
  //     let newArr = [...products, ...productData?.list];
  //     let finalArr = uniqueArrayList(newArr, "_id");

  //     if (finalArr?.length) {
  //       setProducts(finalArr);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [productData]);

  // const productInData = (val) => {
  //   if (val) {
  //     dispatch(productActions.productSearch({ searchText: val }));
  //   }
  // };

  // eslint-disable-next-line
  // const debounceProduct = useCallback(
  //   helperMethods.debounce(productInData, 500),
  //   []
  // );

  // const handleChangeProduct = async (value, fieldIndex) => {
  //   if (value) {
  //     debounceProduct(value);

  //     formDefine?.setFieldsValue({
  //       [`orderFields[${fieldIndex}].product`]: value || "",
  //     });
  //   } else {
  //     formDefine?.resetFields();
  //   }
  // };

  // const initialData = formDefine.getFieldsValue();

  // const uploadProps = {
  //   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  //   headers: {
  //     authorization: "authorization-text",
  //   },

  //   customRequest({ file, onSuccess }) {
  //     setTimeout(() => {
  //       onSuccess("ok");
  //     }, 0);
  //   },
  // };

  // eslint-disable-next-line
  // const [fileBase64, setFileBase64] = useState(null);

  // const handleFileChange = (info, fieldIndex) => {
  //   // console.log(info.file, fieldIndex);

  //   if (info.file.status === "done") {
  //     formDefine?.setFieldValue(
  //       [`orderFields[${fieldIndex}].attachment`],
  //       `file${fieldIndex}_${info?.file?.name}`
  //     );

  //     //   const reader = new FileReader();
  //     //   reader.onload = (e) => {
  //     //     setFileBase64(e.target.result);

  //     // Set the base64 value as the field value
  //     // formDefine.setFieldsValue({
  //     //   [`orderFields[${fieldIndex}].attachment`]: e.target.result,
  //     // });
  //     //   };
  //     //   reader.readAsDataURL(info.file.originFileObj);
  //   }
  // };

  const handleCancel = () => {
    setOpen(false);
    formDefine?.resetFields();
  };

  return (
    <>
      <>
        <Modal
          title="Add/Edit Order Values"
          open={open}
          onCancel={handleCancel}
          footer={null}
        >
          <div>
            <div>
              <Form
                name={formDefine}
                layout="vertical"
                form={formDefine}
                onFinish={handleSubmit}
              >
                <Form.List name="orderFields">
                  {(orderFields, { add, remove }) => (
                    <>
                      {orderFields.map((field, index) => (
                        <Row
                          gutter={16}
                          key={field.key}
                          style={{ marginBottom: "10px" }}
                        >
                          <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                            <Form.Item
                              label={`Product #${index + 1}`}
                              name={[field.name, "product"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please enter a product!",
                              //   },
                              // ]}
                            >
                              {/* <Select
                                onChange={(info) =>
                                  handleChangeProduct(info, index)
                                }
                                style={{ width: "100%" }}
                                placeholder="Select Product"
                                allowClear
                                onPopupScroll={(e) => {
                                  const target = e.target;
                                  if (
                                    target.scrollTop + target.clientHeight ===
                                    target.scrollHeight
                                  ) {
                                    if (productData?.isNext) {
                                      dispatch(
                                        productActions.productList({
                                          limit: 10,
                                          page: currentPage + 1,
                                          funnelId:
                                            loggedUser?.activeFunnel?._id,
                                        })
                                      );
                                      setCurrentPage(currentPage + 1);
                                    }
                                  }
                                }}
                                onClear={() => debounceProduct("")}
                                showSearch
                              >
                                {products?.map((val) => (
                                  <Option key={val?._id}>{val?.name}</Option>
                                ))}
                              </Select> */}

                              <Input
                                placeholder="Product"
                                style={{ width: "100%" }}
                                disabled={true}
                                value={rowData?.productAgaintCompetition?.name}
                                defaultValue={
                                  rowData?.productAgaintCompetition?.name
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                            <Form.Item
                              label={`Order Values #${index + 1}`}
                              name={[field.name, "orderValue"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter an order value",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Order Value"
                                style={{ width: "100%" }}
                                className="hide-arrows"
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                            <Form.Item
                              label={`Order Date #${index + 1}`}
                              name={[field.name, "orderDate"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter an order date",
                                },
                              ]}
                            >
                              <DatePicker
                                placeholder="Order Date"
                                format="DD/MM/YYYY"
                                style={{ width: "100%" }}
                                disabledDate={disabledDate}
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                            <Form.Item
                              label={`Attachment #${index + 1}`}
                              name={[field.name, "attachment"]}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please upload an attachment!",
                              //   },
                              // ]}
                            >
                              <Upload
                                maxCount={1}
                                multiple={false}
                                fileList={
                                  initialData?.orderFields?.length > 0
                                    ? initialData?.orderFields[index]
                                        ?.attachment?.fileList
                                    : []
                                }
                                onChange={(info) =>
                                  handleFileChange(info, index)
                                }
                                {...uploadProps}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload File
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col> */}
                          <Col
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                            xs={24}
                            sm={24}
                            md={20}
                            lg={12}
                            xl={12}
                          >
                            {orderFields.length > 1 && (
                              <Tooltip title="Delete">
                                <Button
                                  onClick={() => remove(field.name)}
                                  danger
                                  icon={<DeleteOutlined />}
                                />
                              </Tooltip>
                            )}
                          </Col>
                          <Divider />
                        </Row>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: "100%" }}
                        >
                          + Add Order
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <div>
                  <span style={{ color: "red" }}>
                    Note:- Please enter order value as like Eg. 100000{" "}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={handleCancel}
                    htmlType="reset"
                    type="default"
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={submitLoading}
                    htmlType="submit"
                    type="primary"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
      </>
    </>
  );
};

export default AddOrderValuesForm;
