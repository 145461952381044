import React, { useEffect, useState } from "react";
import { helperMethods } from "../../utils/DebounceSearch/helperMethod";
import CustomPagination from "./Pagination";
import {
  Button,
  Tooltip,
  Input,
  Card,
  Table,
  Modal,
  message,
  Tag,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { funnelActions, selectFunnel } from "../../redux/slices/funnelSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addFunnelApi,
  deleteFunnelApi,
  editFunnelApi,
} from "../../redux/apis/dashboardApi";
import { authActions, selectLoggedUser } from "../../redux/slices/authSlice";
// import { useNavigate } from "react-router";
// import { PATH_DASHBOARD } from "../../routes/path";

const FunnelList = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const funnelData = useSelector(selectFunnel);
  const loggedUser = useSelector(selectLoggedUser);

  const columns = [
    {
      title: "Name",
      dataIndex: "funnelName",
      render: (val) => <div style={{ textTransform: "capitalize" }}>{val}</div>,
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      render: (val, elm) => <div>{dayjs(val).format("DD/MM/YYYY")}</div>,
    },
    {
      title: "UpdatedAt",
      dataIndex: "updatedAt",
      render: (val, elm) => <div>{dayjs(val).format("DD/MM/YYYY")}</div>,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (val, elm) => {
        let activeFunnel =
          loggedUser?.activeFunnel?._id === elm?._id ? true : false;

        return (
          <>
            <div>
              {activeFunnel ? (
                <Tag color="green">Current</Tag>
              ) : (
                <Tag color="volcano">Not Selected</Tag>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: <span style={{ fontSize: "12px" }}>Actions</span>,
      dataIndex: "actions",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, elm) => (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: 10,
          }}
        >
          {/* <Tooltip title="View">
            <Button
              className="mr-2"
              icon={<EyeOutlined style={{ fontSize: "16px" }} />}
              type="primary"
              size="middle"
              onClick={() => handleViewFunnel(elm)}
            />
          </Tooltip> */}
          <Tooltip title="Edit">
            <Button
              className="mr-2"
              icon={<EditOutlined style={{ fontSize: "16px" }} />}
              type="primary"
              size="middle"
              onClick={() => handleEditRow(elm)}
            />
          </Tooltip>
          <Popconfirm
            title="Delete the funnel"
            description={
              <>
                <span>
                  Are you sure want to delete this funnel? <br /> if Yes then
                  all funnel rows delete with this funnel related
                </span>
              </>
            }
            onConfirm={() => handleDelete(elm._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger icon={<DeleteOutlined />} size="middle" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const [funnels, setFunnels] = useState([]);
  const [newFunnelModel, setNewFunnelModel] = useState(false);
  const [newFunnelData, setNewFunnelData] = useState({
    funnelName: null,
    isLoading: false,
  });
  const [editFunnelModel, setEditFunnelModel] = useState(false);
  const [editFunnelData, setEditFunnelData] = useState({
    funnelName: null,
    isLoading: false,
    id: null,
  });

  useEffect(() => {
    dispatch(funnelActions.funnelList({ limit: 10, page: 1 }));
    // eslint-disable-next-line
  }, []);

  const searchInData = (e) => {
    const searchInput = e.target.value;

    dispatch(
      funnelActions.funnelSearch({
        searchText: searchInput,
      })
    );
  };

  // eslint-disable-next-line
  const debounceOnSearch = React.useCallback(
    helperMethods.debounce(searchInData, 400),
    []
  );

  useEffect(() => {
    if (funnelData) {
      setFunnels(funnelData?.list);
    }
  }, [funnelData]);

  const handleOpenNewFunnel = () => {
    setNewFunnelModel(true);
  };

  const handleCloseNewFunnel = () => {
    setNewFunnelModel(false);
    setNewFunnelData({ funnelName: null, isLoading: false });
  };

  const handleCloseEditFunnel = () => {
    setEditFunnelModel(false);
    setEditFunnelData({ funnelName: null, isLoading: false, id: null });
  };

  const handleCreateFunnel = async () => {
    try {
      if (newFunnelData?.funnelName) {
        setNewFunnelData({ ...newFunnelData, isLoading: true });
        const data = {
          funnelName: newFunnelData?.funnelName,
        };
        const res = await addFunnelApi(data);

        if (res?.data?.success) {
          setNewFunnelData({ ...newFunnelData, isLoading: false });
          message.success(res?.data?.message);
          setNewFunnelModel(false);
          setNewFunnelData({ funnelName: null, isLoading: false });

          setTimeout(() => {
            dispatch(authActions.isLogin());
            window.location.reload();
            dispatch(funnelActions.funnelList({ limit: 10, page: 1 }));
          }, 1000);
        } else {
          setNewFunnelData({ ...newFunnelData, isLoading: false });
          message.error(res?.data?.message);
        }
      } else {
        setNewFunnelData({ ...newFunnelData, isLoading: false });
        message.error("Please Enter Funnel Name");
      }
    } catch (error) {
      setNewFunnelData({ ...newFunnelData, isLoading: false });
      message.error(error?.response?.data?.message);
    }
  };

  const handleEditFunnel = async () => {
    try {
      if (editFunnelData?.funnelName) {
        setEditFunnelData({ ...editFunnelData, isLoading: true });
        const data = {
          funnelName: editFunnelData?.funnelName,
        };
        const res = await editFunnelApi(editFunnelData?.id, data);

        if (res?.data?.success) {
          setEditFunnelData({ ...editFunnelData, isLoading: false });
          message.success(res?.data?.message);
          setEditFunnelModel(false);
          setEditFunnelData({ funnelName: null, isLoading: false });

          setTimeout(() => {
            dispatch(authActions.isLogin());
            dispatch(funnelActions.funnelList({ limit: 10, page: 1 }));
          }, 1000);
        } else {
          setEditFunnelData({ ...editFunnelData, isLoading: false });
          message.error(res?.data?.message);
        }
      } else {
        setEditFunnelData({ ...editFunnelData, isLoading: false });
        message.error("Please Enter Funnel Name");
      }
    } catch (error) {
      setEditFunnelData({ ...editFunnelData, isLoading: false });
      message.error(error?.response?.data?.message);
    }
  };

  const handleEditRow = (row) => {
    setEditFunnelData({
      funnelName: row?.funnelName,
      id: row?._id,
      isLoading: false,
    });
    setEditFunnelModel(true);
  };

  // const handleViewFunnel = (row) => {
  //   dispatch(funnelActions.viewFunnel({ funnelId: row?._id }));
  //   setTimeout(() => {
  //     navigate(PATH_DASHBOARD.viewFunnelRow(row?._id));
  //   }, 1000);
  // };

  const handleDelete = async (id) => {
    try {
      const deleteRes = await deleteFunnelApi(id);
      if (deleteRes?.data?.success) {
        message.success(deleteRes?.data?.message);
        dispatch(funnelActions.funnelList({ limit: 10, page: 1 }));
        window.location.reload();
      } else {
        message.error(deleteRes?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
      message.error(error?.response.data?.message);
    }
  };

  let updatedColumns = [...columns];

  if (
    // loggedUser?.addEditDeleteRights?.length > 0 &&
    // loggedUser?.addEditDeleteRights
    //   ?.filter((v) => v.value === "funnel")
    //   .some((val) => val?.isAddEditDelete === true)
    loggedUser?.role?.roleName === "superUser"
  ) {
    updatedColumns = [...updatedColumns];
  } else {
    delete updatedColumns[updatedColumns.length - 1];
  }

  return (
    <>
      <h2>Funnel List</h2>
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Input
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            onChange={(e) => {
              debounceOnSearch(e);
            }}
            allowClear
            onClear={(e) => debounceOnSearch()}
          />
        </div>
        {loggedUser?.role?.roleName === "superUser" && (
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            size="large"
            onClick={handleOpenNewFunnel}
          >
            New Funnel
          </Button>
        )}
        {/* {loggedUser?.addEditDeleteRights?.length > 0 &&
          loggedUser?.addEditDeleteRights */}
        {/* ?.filter((v) => v.value === "createFunnel")
            .some((val) => val?.isAddEditDelete === true) && ( */}

        {/* )} */}
      </div>
      <div className="table-responsive">
        <Card>
          <>
            <Table
              columns={updatedColumns}
              dataSource={funnels}
              loading={funnelData?.isLoading}
              rowKey="id"
              pagination={false}
              scroll={{
                x: 1000,
              }}
            />
            <div style={{ marginTop: "20px" }}>
              <CustomPagination />
            </div>
          </>
        </Card>
      </div>

      <Modal
        title="Add New Funnel"
        open={newFunnelModel}
        onOk={handleCreateFunnel}
        onCancel={handleCloseNewFunnel}
        confirmLoading={newFunnelData?.isLoading}
        okText="Submit"
      >
        <div>
          <p>Funnel Name</p>
          <Input
            placeholder="Enter Funnel Name"
            value={newFunnelData?.funnelName}
            onChange={(e) => setNewFunnelData({ funnelName: e?.target?.value })}
          />
        </div>
      </Modal>

      <Modal
        title="Edit Funnel"
        open={editFunnelModel}
        onOk={handleEditFunnel}
        onCancel={handleCloseEditFunnel}
        confirmLoading={editFunnelModel?.isLoading}
        okText="Submit"
      >
        <div>
          <p>Funnel Name</p>
          <Input
            placeholder="Enter Funnel Name"
            value={editFunnelData?.funnelName}
            onChange={(e) =>
              setEditFunnelData({
                ...editFunnelData,
                funnelName: e?.target?.value,
              })
            }
          />
        </div>
      </Modal>
    </>
  );
};

export default FunnelList;
