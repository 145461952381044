import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { customerListApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  list: [],
  viewData: null,
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
};

const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    customerList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.searchText = "";
      state.isNext = false;
    },
    customerListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
    },
    customerListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    customerListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
    },
    customerListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
    },
    customerSearch(state, action) {
      state.searchText = action.payload.searchText;
      state.page = 1;
      state.limit = 10;
    },
  },
});

export const {
  actions: customerActions,
  reducer: customerReducer,
  name: customerKey,
} = customerSlice;

const selectDomain = (state) => state.customers || initialState;

export const selectCustomer = createSelector(
  [selectDomain],
  (customerState) => customerState
);

export const getState = (state) => state.customers;

export function* customerListReq() {
  try {
    const states = yield select(getState);
    const res = yield customerListApi(states);

    if (res.data.success) {
      yield put(customerActions.customerListSuccess(res.data));
    } else {
      yield put(customerActions.customerListFailed(res.data));
    }
  } catch (error) {
    yield put(customerActions.customerListFailed(error));
  }
}

export function* customerSaga() {
  yield takeLatest(customerActions.customerList.type, customerListReq);
  yield takeLatest(customerActions.customerListPageLimit.type, customerListReq);
  yield takeLatest(customerActions.customerListLimit.type, customerListReq);
  yield takeLatest(customerActions.customerSearch.type, customerListReq);
}
