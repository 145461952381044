import { Button, Modal, Table, Tag } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { selectLoggedUser } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  carryForwardRowActions,
  selectCarryForwardRow,
} from "../../redux/slices/carryForwardRow";
import CarryForwardRowPagination from "./CarryForwardRowPagination";
import { renderPresetWisePrice } from "../../utils/shared/presetWiseValue";

const CarryForwardHistoryTable = ({ carryHistory, setCarryHistory }) => {
  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoggedUser);
  const funnelRowData = useSelector(selectCarryForwardRow);

  const funnelData = loggedUser?.activeFunnel;
  const viewFunnelData = funnelData;
  const presetsData = loggedUser?.presets;

  const columns = [
    {
      title: <span style={{ fontSize: "12px" }}>Customer Name</span>,
      dataIndex: "customerId",
      fixed: "left",
      width: 100,
      render: (_, elm) => (
        <span>{elm?.customerId ? elm?.customerId?.name : "-"}</span>
      ),
    },
    {
      title: <span style={{ fontSize: "12px" }}>Location</span>,
      dataIndex: "location",
      width: 120,
      render: (val) => (
        <span>{val?.length > 20 ? `${val.slice(0, 29)}...` : val}</span>
      ),
    },
    {
      title: <span style={{ fontSize: "12px" }}>Organisation Chart</span>,
      dataIndex: "organtionChart",
      width: 120,
      render: (_, elm) => <Button>View</Button>,
    },
    {
      title: <span style={{ fontSize: "12px" }}>Progress Stage</span>,
      dataIndex: "progressStage",
      width: 100,
      render: (val) => (
        <>
          {val ? (
            <Tag color={val?.bg} style={{ color: `#000000` }}>
              {val?.value}. {val?.name}
            </Tag>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>
          Competition Brand & Product Details
        </span>
      ),
      dataIndex: "competitionBrand",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs();
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <div>
                    {val &&
                      Object.keys(val).map((keys) => (
                        <>
                          <span style={{ margin: 0 }}>
                            {val[keys]?.length > 12
                              ? `${val[keys]?.slice(0, 12)}...`
                              : val[keys]}
                            ,
                          </span>
                        </>
                      ))}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <div>
                    {Object.keys(val).map((keys) => (
                      <>
                        <span style={{ margin: 0 }}>
                          {val[keys]?.length > 12
                            ? `${val[keys]?.slice(0, 12)}...`
                            : val[keys]}
                          ,
                        </span>
                      </>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
          {loggedUser?.activeFunnel?.funnelName || ""} Product Details against
          competition
        </span>
      ),
      dataIndex: "productAgaintCompetition",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs();
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <div>
                    {val &&
                      Object.keys(val).map((keys) => (
                        <>
                          <span style={{ margin: 0 }}>
                            {val[keys]?.length > 12
                              ? `${val[keys]?.slice(0, 12)}...`
                              : val[keys]}
                            ,
                          </span>
                        </>
                      ))}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <div>
                    {Object.keys(val).map((keys) => (
                      <>
                        <span style={{ margin: 0 }}>
                          {val[keys]?.length > 12
                            ? `${val[keys]?.slice(0, 12)}...`
                            : val[keys]}
                          ,
                        </span>
                      </>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>
          Total Potential Value (in {presetsData?.potentialValue ?? "-"})
        </span>
      ),
      dataIndex: "potentialValue",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs();
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {val?.total
                      ? renderPresetWisePrice(val.total, presetsData)
                      : "0"}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {val?.total
                      ? renderPresetWisePrice(val.total, presetsData)
                      : "0"}
                  </div>
                </div>
              </>
            ) : (
              <>-</>
            )}{" "}
          </>
        );
      },
    },
    {
      title: (
        <>
          <div style={{ position: "relative", bottom: "20px" }}>
            <span style={{ fontSize: "12px" }}>Inquiry Value If Any</span>
          </div>
        </>
      ),

      dataIndex: "inquiryValue",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs();
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {val
                      ? `${
                          val?.value &&
                          renderPresetWisePrice(val?.value, presetsData)
                        }`
                      : "0"}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {val
                      ? `${
                          val?.value &&
                          renderPresetWisePrice(val?.value, presetsData)
                        }`
                      : "0"}
                  </div>
                </div>
              </>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: (
        <>
          <div style={{ position: "relative", bottom: "20px" }}>
            <span style={{ fontSize: "12px" }}> Trials / Demo Reports</span>
          </div>
        </>
      ),
      dataIndex: "trialsReportsData",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs();
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{val?.length > 0 ? `${val?.length}` : "0"}</div>
                </div>
              </>
            ) : val?.length > 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{val?.length > 0 ? `${val?.length}` : "0"}</div>
                </div>
              </>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: (
        <>
          <div style={{ position: "relative", bottom: "10px" }}>
            <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
              {loggedUser?.activeFunnel?.funnelName || ""} existing sale value
            </span>
          </div>
        </>
      ),
      dataIndex: "existingSaleData",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs();
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {elm?.potentialValue
                      ? renderPresetWisePrice(
                          elm?.potentialValue?.existingSaleCurrentVolue,
                          presetsData
                        )
                      : "0"}
                  </div>
                </div>
              </>
            ) : elm?.potentialValue ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {elm?.potentialValue
                      ? renderPresetWisePrice(
                          elm?.potentialValue?.existingSaleCurrentVolue,
                          presetsData
                        )
                      : "0"}
                  </div>
                </div>
              </>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: (
        <>
          <div style={{ position: "relative", bottom: "10px" }}>
            <span style={{ fontSize: "12px" }}>
              Conversion sale value plan (in{" "}
              {presetsData?.potentialValue ?? "-"})
            </span>
          </div>
        </>
      ),
      dataIndex: "conversionSale",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs();
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {val ? renderPresetWisePrice(val, presetsData) : "0"}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {val ? renderPresetWisePrice(val, presetsData) : "0"}
                  </div>
                </div>
              </>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>Conversion Timeline (in days)</span>
      ),
      dataIndex: "conversionTimeline",
      width: 120,
      render: (val, elm) => (
        <>
          {val ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>{val ? dayjs(val).format("DD/MM/YYYY") : "-"}</div>
              </div>
            </>
          ) : (
            <>-</>
          )}
        </>
      ),
    },
    {
      title: <span style={{ fontSize: "12px" }}>Conversion days due</span>,
      dataIndex: "conversionDueDay",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs(val);
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline ? (
              <div>{diffInDays < 0 ? "0" : diffInDays} days left</div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>
          Order Value Conversion (in {presetsData?.potentialValue ?? "-"})
        </span>
      ),
      dataIndex: "orderValueTotal",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs();
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");

        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {val ? renderPresetWisePrice(val, presetsData) : "0"}
                  </div>
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {val ? renderPresetWisePrice(val, presetsData) : "0"}
                  </div>
                </div>
              </>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span style={{ fontSize: "12px" }}>Conversion against plan %</span>
      ),
      dataIndex: "conversionAgainstPlan",
      width: 120,
      render: (val, elm) => (
        <>
          <div>
            {elm?.orderValueTotal && elm?.conversionSale
              ? ((elm?.orderValueTotal / elm?.conversionSale) * 100).toFixed(2)
              : 0}{" "}
            %
          </div>
        </>
      ),
    },
    {
      title: (
        <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
          {loggedUser?.activeFunnel?.funnelName || ""} initial market share
        </span>
      ),
      dataIndex: "initialMarketShare",
      width: 120,
      render: (val, elm) => (
        <span>
          {elm?.potentialValue
            ? Number(
                (elm?.potentialValue?.existingSaleVolue /
                  elm?.potentialValue?.total) *
                  100
              ).toFixed(2)
            : 0}
          %
        </span>
      ),
    },
    {
      title: (
        <span style={{ fontSize: "12px", textTransform: "capitalize" }}>
          {loggedUser?.activeFunnel?.funnelName || ""} revised market share
        </span>
      ),
      dataIndex: "revisedMarketShare",
      width: 120,
      render: (val, elm) => (
        <span>
          {elm?.potentialValue && elm?.potentialValue?.total
            ? Number(
                (elm?.potentialValue?.existingSaleCurrentVolue /
                  elm?.potentialValue?.total) *
                  100
              ).toFixed(2)
            : 0}
          %
        </span>
      ),
    },
    {
      title: <span style={{ fontSize: "12px" }}>Remarks</span>,
      dataIndex: "remarks",
      width: 120,
      render: (val, elm) => {
        const currentDate = dayjs();
        const targetDate = dayjs(elm?.conversionTimeline);
        const diffInDays = targetDate.diff(currentDate, "day");
        return (
          <>
            {elm?.conversionTimeline && diffInDays <= 0 ? (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <div>
                    {val
                      ? `${val?.length > 15 ? `${val.slice(0, 12)}...` : val}`
                      : "-"}
                  </div>{" "}
                </div>
              </>
            ) : val ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <div>
                    {val
                      ? `${val?.length > 15 ? `${val.slice(0, 12)}...` : val}`
                      : "-"}
                  </div>{" "}
                </div>
              </>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
  ];

  const [funnelRows, setFunnelRows] = useState([]);

  useEffect(() => {
    if (viewFunnelData?._id && carryHistory?.row) {
      dispatch(
        carryForwardRowActions.carryForwardRowList({
          limit: 10,
          page: 1,
          funnelId: viewFunnelData?._id,
          userId: loggedUser?._id,
          isDelete: false,
          isCarryForward: true,
          mainFunnelRow: carryHistory?.row?.mainFunnelRow,
        })
      );
    }
    // eslint-disable-next-line
  }, [viewFunnelData, carryHistory?.row]);

  useEffect(() => {
    if (funnelRowData) {
      setFunnelRows(funnelRowData?.list);
    }
  }, [funnelRowData]);

  const handleCancle = () => {
    setCarryHistory({ row: null, model: false });
  };

  return (
    <>
      <Modal
        title={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Carry Forward Row</span>
              <span
                style={{ color: "red", fontSize: "12px", marginRight: "30px" }}
              >
                Note:- Carry Forward Row in Descending Order (Newest First)
              </span>
            </div>
          </>
        }
        open={carryHistory?.model}
        onCancel={handleCancle}
        footer={null}
        width={1300}
      >
        <Table
          columns={columns}
          dataSource={funnelRows}
          loading={funnelRowData?.loading}
          rowKey="id"
          pagination={false}
          bordered
          scroll={{
            x: 1500,
            y: 400,
          }}
        />
        <div style={{ marginTop: "20px" }}>
          <CarryForwardRowPagination />
        </div>
      </Modal>
    </>
  );
};

export default CarryForwardHistoryTable;
