import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";

import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";

const tableRowsCount = 2;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

const InvoiceItemsTable = ({ invoice, state, subcriptionData }) => {
  const rowsData = [state?.invoiceData];

  return (
    <>
      <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow
          state={state}
          items={invoice?.items}
          subcriptionData={subcriptionData}
        />
        <InvoiceTableBlankSpace rowsCount={tableRowsCount - rowsData?.length} />
        <InvoiceTableFooter
          subcriptionData={subcriptionData}
          state={state}
          items={invoice?.items}
        />
      </View>
    </>
  );
};

export default InvoiceItemsTable;
