export const renderPresetWisePrice = (price, presetsData) => {
  if (!price || price === "") return "";

  let updatedPrice = Number(price);

  let key = presetsData?.potentialValue?.toLowerCase();

  switch (key) {
    case "lacs":
      updatedPrice = (updatedPrice / 100000).toFixed(2) + " L";
      break;

    case "thousands":
      updatedPrice = (updatedPrice / 1000).toFixed(2) + " K";
      break;

    case "Cr":
      updatedPrice = (updatedPrice / 10000000).toFixed(2) + " CR";
      break;

    default:
      updatedPrice = Number(updatedPrice).toLocaleString();
      break;
  }

  return updatedPrice;
};
