import { createSlice, createSelector } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { funnelListApi, getFunnelApi } from "../apis/dashboardApi";

const initialState = {
  isLoading: false,
  error: null,
  success: false,
  list: [],
  limit: 10,
  page: 1,
  sort: "createdAt",
  sortType: "desc",
  searchText: "",
  isNext: false,
  funnelId: null,
  viewData: null,
};

const funnelSlice = createSlice({
  name: "funnels",
  initialState,
  reducers: {
    funnelList(state, action) {
      state.isLoading = true;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.searchText = "";
      state.isNext = false;
    },
    funnelListSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.list = action.payload.data;
      state.isNext = action.payload.isNext;
    },
    funnelListFailed(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      state.isNext = false;
      state.list = [];
    },
    funnelListPageLimit(state, action) {
      state.page = action.payload.page;
      state.limit = 10;
      state.isLoading = true;
    },
    funnelListLimit(state, action) {
      state.page = 1;
      state.limit = action.payload.limit;
      state.isLoading = true;
    },
    funnelSearch(state, action) {
      state.searchText = action.payload.searchText;
      state.page = 1;
      state.limit = 10;
    },
    viewFunnel(state, action) {
      state.funnelId = action.payload.funnelId;
      state.isLoading = true;
    },
    viewFunnelSuccess(state, action) {
      state.viewData = action.payload.data;
      state.isLoading = false;
    },
    viewFunnelFailed(state) {
      state.viewData = null;
      state.isLoading = false;
    },
  },
});

export const {
  actions: funnelActions,
  reducer: funnelReducer,
  name: funnelKey,
} = funnelSlice;

const selectDomain = (state) => state.funnels || initialState;

export const selectFunnel = createSelector(
  [selectDomain],
  (funnelState) => funnelState
);

export const getState = (state) => state.funnels;

export function* funnelListReq() {
  try {
    const states = yield select(getState);
    const res = yield funnelListApi(states);

    if (res.data.success) {
      yield put(funnelActions.funnelListSuccess(res.data));
    } else {
      yield put(funnelActions.funnelListFailed(res.data));
    }
  } catch (error) {
    yield put(funnelActions.funnelListFailed(error));
  }
}

export function* viewFunnelReq() {
  try {
    const states = yield select(getState);
    const res = yield getFunnelApi(states);

    if (res.data.success) {
      yield put(funnelActions.viewFunnelSuccess(res.data));
    } else {
      yield put(funnelActions.viewFunnelFailed(res.data));
    }
  } catch (error) {
    yield put(funnelActions.viewFunnelFailed(error));
  }
}

export function* funnelSaga() {
  yield takeLatest(funnelActions.funnelList.type, funnelListReq);
  yield takeLatest(funnelActions.funnelListPageLimit.type, funnelListReq);
  yield takeLatest(funnelActions.funnelListLimit.type, funnelListReq);
  yield takeLatest(funnelActions.funnelSearch.type, funnelListReq);
  yield takeLatest(funnelActions.viewFunnel.type, viewFunnelReq);
}
