import { Card, Col, Form, Row, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import AddEditFunnelRow from "../../sections/FunnelRowManage/AddEditFunnelRow";
import { PATH_DASHBOARD } from "../../routes/path";
import {
  editFunnelRowApi,
  getFunnelRowApi,
} from "../../redux/apis/dashboardApi";
import dayjs from "dayjs";

const EditFunnelRow = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [isEdit, setIsEdit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [isViewData, setIsViewData] = useState(null);

  const [addEditFunnelRowForm] = Form.useForm();

  useEffect(() => {
    if (location) {
      if (location.pathname.includes("edit")) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
      }
    }
  }, [location]);

  const handleCancle = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isEdit && params?.rowId) {
      (async () => {
        try {
          setIsViewData(true);
          const viewRes = await getFunnelRowApi(params?.rowId);
          if (viewRes?.data?.success) {
            setIsViewData(false);
            setViewData(viewRes?.data?.data);
          } else {
            setIsViewData(false);
            setViewData(null);
          }
        } catch (error) {
          console.log("error ---->", error);
          setIsViewData(false);
          setViewData(null);
        }
      })();
    }
  }, [params, isEdit]);

  useEffect(() => {
    if (viewData) {
      addEditFunnelRowForm.setFieldsValue({
        customerId: viewData?.customerId?._id,
        location: viewData?.location,
        progressStage: viewData?.progressStage,
        conversionTimeline: dayjs(viewData?.conversionTimeline),
        conversionAgainstPlan: viewData?.conversionAgainstPlan,
        remarks: viewData?.remarks,
      });
    }

    // eslint-disable-next-line
  }, [viewData, isEdit]);

  const onFinish = async (values) => {
    try {
      setIsSubmit(true);
      const data = {
        ...values,
        conversionTimeline: new Date(
          values?.conversionTimeline["$d"]
        ).getTime(),
        funnelId: params?.id,
      };

      const addRes = await editFunnelRowApi(params?.rowId, data);

      if (addRes.data.success) {
        message.success(addRes.data.message);
        setIsSubmit(false);
        setTimeout(() => {
          navigate(PATH_DASHBOARD.listFunnelRow(params?.id));
        }, 1000);
      } else {
        setIsSubmit(false);
        message.error(addRes.data.message);
      }
    } catch (error) {
      setIsSubmit(false);
      message.error(error?.response.data?.message);
    }
  };

  if (isViewData && isEdit) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spin />
        </div>
      </>
    );
  }

  return (
    <>
      <Card title="Add Funnel Row" style={{ marginTop: "20px" }}>
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={24} sm={24} md={20} lg={12} xl={18}>
              <div className="mt-4">
                <Form
                  name="addEditFunnelRowForm"
                  layout="vertical"
                  form={addEditFunnelRowForm}
                  onFinish={onFinish}
                >
                  <AddEditFunnelRow
                    isEdit={isEdit}
                    isSubmit={isSubmit}
                    handleCancle={handleCancle}
                  />
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default EditFunnelRow;
