import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ colors, resultData, type }) => {
  const pieChartData = {
    series: [51.22, 48.78],
    options: {
      toolbar: {
        show: false,
      },
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Competition", "Your Company"],
      tooltip: {
        y: {
          formatter: function (val) {
            return type === "conversionRatio" ? Math.round(val) : val + " %";
          },
        },
      },
      colors: ["#f7ca4f", "#37b4de"],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const [chartOptions, setChartOptions] = useState({
    series: pieChartData.series,
    options: pieChartData.options,
    chartType: "pie",
    width: 400,
  });

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      options: {
        ...pieChartData.options,
        labels: resultData?.labels || ["Competition", "Your Company"],
        colors: colors || ["#f7ca4f", "#37b4de"],
      },
      series: resultData?.series || [],
      chartType: "pie",
      width: window.innerWidth > 768 ? 400 : 320,
    });

    // eslint-disable-next-line
  }, [resultData]);

  return (
    <>
      <ReactApexChart
        options={chartOptions.options}
        series={chartOptions.series}
        type={chartOptions.chartType}
        width={chartOptions.width}
      />
    </>
  );
};

export default PieChart;
