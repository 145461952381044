import { Card, Tabs } from "antd";
import React from "react";
import RolesManagement from "./RolesManagement";
import RightsManagement from "./RightsManagement";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../redux/slices/authSlice";
// import EditData from "./EditData";
import ProductPricingManage from "./ProductPricingManage";

const Management = () => {
  const loggedUser = useSelector(selectLoggedUser);

  const { mainLicense, subLicense, initialSubLicense } = loggedUser;

  const items = [
    {
      label: "Role Management",
      key: 1,
      children: <RolesManagement loggedUser={loggedUser} />,
    },
    {
      label: "Rights Management",
      key: 2,
      children: <RightsManagement loggedUser={loggedUser} />,
    },
    // {
    //   label: "Edit Data",
    //   key: 3,
    //   children: <EditData loggedUser={loggedUser} />,
    // },
    {
      label: "Product Pricing",
      key: 3,
      children: <ProductPricingManage loggedUser={loggedUser} />,
    },
  ];

  return (
    <Card style={{ marginTop: "20px" }}>
      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        <span
          style={{
            padding: "10px",
            background: "#dbdbdb",
            fontSize: "16px",
            fontWeight: 600,
            borderRadius: "8px",
          }}
        >
          Numbers of license available : {Number(subLicense)} /{" "}
          {Number(mainLicense) + Number(initialSubLicense)}
        </span>
      </div>
      <Tabs type="card" size="large" items={items} defaultActiveKey="1" />
    </Card>
  );
};

export default Management;
